import * as turf from '@turf/turf';
import mapboxgl from 'mapbox-gl';


const sources_ids = ['sourceCommoditiesEducationSec'];
const layers_ids = ['layerCommoditiesEducationSec_Points'];
var markers = [];
var marker_img = '<svg width="11" height="14" viewBox="0 0 390 492" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M266.44 245.754H123.08V307.194H266.44V245.754Z" fill="white"/><path d="M383.378 403.517C385.078 401.797 386.307 399.81 387.29 397.721C387.577 397.107 387.741 396.451 387.966 395.796C388.58 394.035 388.949 392.233 389.072 390.348C389.092 389.918 389.318 389.549 389.318 389.119V20.48C389.318 9.175 380.163 0 368.838 0H184.518V143.36C184.518 151.573 179.603 159.007 172.046 162.202C169.465 163.308 166.742 163.84 164.038 163.84C158.836 163.84 153.696 161.853 149.825 158.085L123.078 132.28L96.3313 158.085C90.4533 163.799 81.6673 165.437 74.1103 162.201C66.5533 159.006 61.6383 151.572 61.6383 143.359V0H51.7673C23.3193 0 0.197266 23.122 0.197266 51.548V430.08C0.197266 463.954 27.7633 491.52 61.6373 491.52H368.837C377.111 491.52 384.586 486.523 387.761 478.884C390.935 471.224 389.174 462.418 383.317 456.561C364.025 437.269 364.025 422.912 383.317 403.62C383.337 403.579 383.357 403.538 383.378 403.517ZM82.1173 225.28C82.1173 213.975 91.2723 204.8 102.597 204.8H286.917C298.242 204.8 307.397 213.975 307.397 225.28V327.68C307.397 338.985 298.242 348.16 286.917 348.16H102.597C91.2723 348.16 82.1173 338.985 82.1173 327.68V225.28ZM331.4 450.56H61.6373C50.3323 450.56 41.1573 441.365 41.1573 430.08C41.1573 418.795 50.3323 409.6 61.6373 409.6H331.4C326.771 423.178 326.771 436.982 331.4 450.56Z" fill="white"/></svg>';

export const get_ids = () => {
    // Get all source ids
    var sources = [];
    sources_ids.forEach(source_id => {
        sources.push({ id: source_id });
    })
    // Get all layer ids
    var layers = [];
    layers_ids.forEach(layer_id => {
        layers.push({ id: layer_id });
    })

    return {
        sources: sources,
        layers: layers,
        markers: markers,
    }
}


export const get_data = (capacity) => {
    // Set map data
    var map_data = {
        sources: [],
        layers: [],
        markers: [],
        bbox: null
    }

    var data_items = capacity?.context?.commodities?.education_secondary?.items?.highschool || [];
    var data_cat = Array(data_items.length).fill("Lycée");
    if (capacity?.context?.commodities?.education_secondary?.items?.medschool) {
        data_items = data_items.concat(capacity?.context?.commodities?.education_secondary?.items?.medschool);
        data_cat = data_cat.concat(Array(capacity?.context?.commodities?.education_secondary?.items?.medschool.length).fill("Collège"));
    }


    // Check needed data
    if (!data_items.length > 0) {
        return map_data
    }

    // Preset layer data
    var layer_data = {
        type: 'FeatureCollection',
        features: [],
    };

    // Add data
    for (var i = 0; i < data_items.length; i++) {
        var item = data_items[i];
        var item_marker_coords = null;
        // Generate polygon
        if (item?.type === "way" && item?.coords && item.coords.length > 3 && item.coords[0].length === 2 && item.coords[0][0] === item.coords[item.coords.length - 1][0] && item.coords[0][1] === item.coords[item.coords.length - 1][1]) {
            layer_data.features.push({
                type: "Feature",
                properties: item?.properties || null,
                geometry: {
                    type: "Polygon",
                    coordinates: [item.coords],
                }
            });
            try {
                var center = turf.centroid(turf.polygon([item.coords]));
                item_marker_coords = center?.geometry?.coordinates || null;
            } catch (error) {
                item_marker_coords = null;
            }
        }
        // Generate point
        else if (item?.type === "node" && item?.coords && item.coords.length === 2) {
            layer_data.features.push({
                type: "Feature",
                properties: item?.properties || null,
                geometry: {
                    type: "Point",
                    coordinates: item.coords,
                }
            });
            item_marker_coords = item.coords;
        }
        // Generate marker
        if (item_marker_coords !== null && item_marker_coords.length === 2) {
            var title = data_cat.length >= (i-1) ? data_cat[i] : "Établissement d'enseignement secondaire";
            var title_txt = '<span style="color:#062134;font-size:15px;font-weight:700"><strong>' + title + '</strong></span>';
            var infos = [
                { label: "Nom", value: item?.properties?.name || "NC" },
                { label: "Public / Privé", value: item?.properties?.operator || "NC" },
                { label: "Distance", value: item?.properties?.distance ? item?.properties?.distance + " m" : "NC"},
            ]
            var infos_txt = infos.map(info => { return ('<br /><span style="font-size:12px;font-weight: 600;color: grey;">' + info.label + ' :</span><br /><span style="color:#062134;font-weight:500;">' + info.value + '</span>') }).join('');
            var marker_label = item?.properties?.name || "Établissement d'enseignement secondaire";
            var el = document.createElement('div');
            el.className = 'marker';
            el.innerHTML = '<div style="cursor:pointer;padding:3px 8px 2px;background-color:#062134;border-radius:6px;display:flex;justify-content:center;align-items:center;gap:4px"><div style="display:flex;justify-content:center">' + marker_img + '</div><div style="font-family:Manrope;color:#FFF;">' + marker_label + '</div></div>';
            map_data.markers.push(new mapboxgl.Marker(el)
                .setLngLat(item_marker_coords)
                .setPopup(new mapboxgl.Popup({ offset: 15 })
                    .setHTML('<p style="font-family:Manrope">' + title_txt + infos_txt + '</p>'))
            )
        }
    }

    // Check data exists
    if (!layer_data.features.length > 0) {
        return map_data
    }

    // Create sources
    map_data.sources.push(
        {
            id: sources_ids[0],
            value: {
                type: 'geojson',
                data: layer_data
            }
        }
    )

    // Create layers
    map_data.layers.push(
        {
            id: layers_ids[0],
            type: 'fill',
            source: sources_ids[0],
            paint: {
                'fill-color': '#062134',
                'fill-opacity': 0.5,
            },
            filter: ['in', '$type', 'Polygon']
        }
    );

    // Set global markers
    if (map_data.markers.length > 0) {
        markers = map_data.markers;
    }
    else {
        markers = [];
    }

    // Get bbox
    var bbox_data = {
        type: 'FeatureCollection',
        features: layer_data.features.concat([capacity?.landBase?.union?.geometry]),
    };
    var bbox = turf.bbox(bbox_data);
    if (bbox && bbox.length === 4) {
        var bbox_poly = [
            [bbox[0], bbox[1]],
            [bbox[2], bbox[1]],
            [bbox[2], bbox[3]],
            [bbox[0], bbox[3]],
            [bbox[0], bbox[1]],
        ]
        map_data.bbox = [bbox_poly];
    }


    // Return
    // console.log("map_data", map_data);
    return map_data

}

