import * as turf from '@turf/turf';
import mapboxgl from 'mapbox-gl';


const sources_ids = ['sourceCommoditiesCharging'];
const layers_ids = ['layerCommoditiesCharging_Points'];
var markers = [];
var marker_img = '<svg width="14" height="14" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1102_27)"><path d="M466.667 666.667H22.2222C9.94444 666.667 0 676.611 0 688.889V733.333C0 745.611 9.94444 755.555 22.2222 755.555H466.667C478.944 755.555 488.889 745.611 488.889 733.333V688.889C488.889 676.611 478.944 666.667 466.667 666.667ZM755.556 222.222V155.555C755.556 143.278 745.611 133.333 733.333 133.333C721.056 133.333 711.111 143.278 711.111 155.555V222.222H666.667V155.555C666.667 143.278 656.722 133.333 644.444 133.333C632.167 133.333 622.222 143.278 622.222 155.555V222.222H600C587.722 222.222 577.778 232.167 577.778 244.444V288.889C577.778 338.555 610.583 380.125 655.556 394.347V558.917C655.556 578.292 642.361 596.305 623.25 599.458C598.917 603.472 577.778 584.708 577.778 561.111V522.222C577.778 454.722 523.056 400 455.556 400H444.444V133.333C444.444 84.236 404.653 44.4443 355.556 44.4443H133.333C84.2361 44.4443 44.4444 84.236 44.4444 133.333V622.222H444.444V466.667H455.556C486.236 466.667 511.111 491.542 511.111 522.222V556.403C511.111 611.5 551.278 660.792 606.125 666.139C669.042 672.292 722.222 622.792 722.222 561.111V394.347C767.194 380.125 800 338.555 800 288.889V244.444C800 232.167 790.056 222.222 777.778 222.222H755.556ZM361.236 288.555L231.097 481.611C228.042 486.236 222.472 488.889 216.667 488.889C206.014 488.889 197.958 480.167 200.458 470.944L232.431 355.555H150C139.931 355.555 132.153 347.792 133.486 339.042L155.708 190.43C156.806 183.194 163.861 177.778 172.222 177.778H266.667C277.611 177.778 285.583 186.861 282.778 196.125L266.667 266.667H346.806C359.639 266.667 367.653 278.861 361.236 288.555Z" fill="white"/></g><defs><clipPath id="clip0_1102_27"><rect width="800" height="800" fill="white"/></clipPath></defs></svg>';

export const get_ids = () => {
    // Get all source ids
    var sources = [];
    sources_ids.forEach(source_id => {
        sources.push({ id: source_id });
    })
    // Get all layer ids
    var layers = [];
    layers_ids.forEach(layer_id => {
        layers.push({ id: layer_id });
    })

    return {
        sources: sources,
        layers: layers,
        markers: markers,
    }
}


export const get_data = (capacity) => {
    // Set map data
    var map_data = {
        sources: [],
        layers: [],
        markers: [],
        bbox: null
    }

    // Check needed data
    if (!capacity?.context?.commodities?.charging?.items || !capacity.context.commodities.charging.items.length > 0) {
        return map_data
    }

    // Preset layer data
    var layer_data = {
        type: 'FeatureCollection',
        features: [],
    };

    // Add data
    for (var i = 0; i < capacity.context.commodities.charging.items.length; i++) {
        var item = capacity.context.commodities.charging.items[i];
        var item_marker_coords = null;
        // Generate polygon
        if (item?.type === "way" && item?.coords && item.coords.length > 3 && item.coords[0].length === 2 && item.coords[0][0] === item.coords[item.coords.length - 1][0] && item.coords[0][1] === item.coords[item.coords.length - 1][1]) {
            layer_data.features.push({
                type: "Feature",
                properties: item?.properties || null,
                geometry: {
                    type: "Polygon",
                    coordinates: [item.coords],
                }
            });
            try {
                var center = turf.centroid(turf.polygon([item.coords]));
                item_marker_coords = center?.geometry?.coordinates || null;
            } catch (error) {
                item_marker_coords = null;
            }
        }
        // Generate point
        else if (item?.type === "node" && item?.coords && item.coords.length === 2) {
            layer_data.features.push({
                type: "Feature",
                properties: item?.properties || null,
                geometry: {
                    type: "Point",
                    coordinates: item.coords,
                }
            });
            item_marker_coords = item.coords;
        }
        // Generate marker
        if (item_marker_coords !== null && item_marker_coords.length === 2) {
            var title = "Bornes de chargement";
            var title_txt = '<span style="color:#062134;font-size:15px;font-weight:700"><strong>' + title + '</strong></span>';
            var infos = [
                { label: "Nombre de bornes", value: item?.properties?.slots || "NC" },
                { label: "Service payant", value: item?.properties?.fee || "NC"},
            ]
            var infos_txt = infos.map(info => { return ('<br /><span style="font-size:12px;font-weight: 600;color: grey;">' + info.label + ' :</span><br /><span style="color:#062134;font-weight:500;">' + info.value + '</span>') }).join('');
            var marker_label = "Bornes";
            var el = document.createElement('div');
            el.className = 'marker';
            el.innerHTML = '<div style="cursor:pointer;padding:3px 8px 2px;background-color:#062134;border-radius:6px;display:flex;justify-content:center;align-items:center;gap:4px"><div style="display:flex;justify-content:center">' + marker_img + '</div><div style="font-family:Manrope;color:#FFF;">' + marker_label + '</div></div>';
            map_data.markers.push(new mapboxgl.Marker(el)
                .setLngLat(item_marker_coords)
                .setPopup(new mapboxgl.Popup({ offset: 15 })
                    .setHTML('<p style="font-family:Manrope">' + title_txt + infos_txt + '</p>'))
            )
        }
    }

    // Check data exists
    if (!layer_data.features.length > 0) {
        return map_data
    }

    // Create sources
    map_data.sources.push(
        {
            id: sources_ids[0],
            value: {
                type: 'geojson',
                data: layer_data
            }
        }
    )

    // Create layers
    map_data.layers.push(
        {
            id: layers_ids[0],
            type: 'fill',
            source: sources_ids[0],
            paint: {
                'fill-color': '#062134',
                'fill-opacity': 0.5,
            },
            filter: ['in', '$type', 'Polygon']
        }
    );

    // Set global markers
    if (map_data.markers.length > 0) {
        markers = map_data.markers;
    }
    else {
        markers = [];
    }

    // Get bbox
    var bbox_data = {
        type: 'FeatureCollection',
        features: layer_data.features.concat([capacity?.landBase?.union?.geometry]),
    };
    var bbox = turf.bbox(bbox_data);
    if (bbox && bbox.length === 4) {
        var bbox_poly = [
            [bbox[0], bbox[1]],
            [bbox[2], bbox[1]],
            [bbox[2], bbox[3]],
            [bbox[0], bbox[3]],
            [bbox[0], bbox[1]],
        ]
        map_data.bbox = [bbox_poly];
    }



    // Return
    // console.log("map_data", map_data);
    return map_data

}

