import * as turf from '@turf/turf';
import mapboxgl from 'mapbox-gl';


const sources_ids = ['sourceCommoditiesSupermarket'];
const layers_ids = ['layerCommoditiesSupermarket_Points'];
var markers = [];
var marker_img = '<svg width="13" height="14" viewBox="0 0 700 740" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M649 10.0384C649 10.0384 574.845 7.84103 545.25 36.705C516.797 64.449 505.042 85.649 505.042 143.372H9L83.375 400.143C83.5617 400.532 83.9015 400.904 84.1042 401.288C96.6055 436.344 129.284 461.592 168.271 463.059C168.431 463.129 169 463.372 169 463.372H515.667V473.997C515.667 473.997 515.708 490.059 505.042 511.393C494.375 532.726 489.062 543.372 446.396 543.372H99.7292C46.3958 543.372 46.3958 623.372 99.7292 623.372H163.688H169H430.354H435.667H441.083C505.083 623.372 553 586.018 569 548.684C585 511.351 585.042 479.413 585.042 479.413V463.372V143.372C585.042 115.446 597.235 90.0384 622.333 90.0384H651.708C673.799 90.0384 691.708 72.129 691.708 50.0384C691.708 27.9477 673.799 10.0384 651.708 10.0384H649ZM435.667 623.372C403.667 623.372 382.333 644.705 382.333 676.705C382.333 708.705 403.667 730.038 435.667 730.038C467.667 730.038 489 708.705 489 676.705C489 644.705 467.667 623.372 435.667 623.372ZM169 623.372C137 623.372 115.667 644.705 115.667 676.705C115.667 708.705 137 730.038 169 730.038C201 730.038 222.333 708.705 222.333 676.705C222.333 644.705 201 623.372 169 623.372Z" fill="white"/></svg>';

export const get_ids = () => {
    // Get all source ids
    var sources = [];
    sources_ids.forEach(source_id => {
        sources.push({ id: source_id });
    })
    // Get all layer ids
    var layers = [];
    layers_ids.forEach(layer_id => {
        layers.push({ id: layer_id });
    })

    return {
        sources: sources,
        layers: layers,
        markers: markers,
    }
}


export const get_data = (capacity) => {
    // Set map data
    var map_data = {
        sources: [],
        layers: [],
        markers: [],
        bbox: null
    }

    // Check needed data
    if (!capacity?.context?.commodities?.shop?.items?.supermarket || !capacity.context.commodities.shop.items.supermarket.length > 0) {
        return map_data
    }

    // Preset layer data
    var layer_data = {
        type: 'FeatureCollection',
        features: [],
    };

    // Add data
    for (var i = 0; i < capacity.context.commodities.shop.items.supermarket.length; i++) {
        var item = capacity.context.commodities.shop.items.supermarket[i];
        var item_marker_coords = null;
        // Generate polygon
        if (item?.type === "way" && item?.coords && item.coords.length > 3 && item.coords[0].length === 2 && item.coords[0][0] === item.coords[item.coords.length - 1][0] && item.coords[0][1] === item.coords[item.coords.length - 1][1]) {
            layer_data.features.push({
                type: "Feature",
                properties: item?.properties || null,
                geometry: {
                    type: "Polygon",
                    coordinates: [item.coords],
                }
            });
            try {
                var center = turf.centroid(turf.polygon([item.coords]));
                item_marker_coords = center?.geometry?.coordinates || null;
            } catch (error) {
                item_marker_coords = null;
            }
        }
        // Generate point
        else if (item?.type === "node" && item?.coords && item.coords.length === 2) {
            layer_data.features.push({
                type: "Feature",
                properties: item?.properties || null,
                geometry: {
                    type: "Point",
                    coordinates: item.coords,
                }
            });
            item_marker_coords = item.coords;
        }
        // Generate marker
        if (item_marker_coords !== null && item_marker_coords.length === 2) {
            var title = "Centre commercial";
            var title_txt = '<span style="color:#062134;font-size:15px;font-weight:700"><strong>' + title + '</strong></span>';
            var infos = [
                { label: "Nom", value: item?.properties?.name || "NC" },
                { label: "Distance", value: item?.properties?.distance ? item?.properties?.distance + " m" : "NC"},
            ]
            var infos_txt = infos.map(info => { return ('<br /><span style="font-size:12px;font-weight: 600;color: grey;">' + info.label + ' :</span><br /><span style="color:#062134;font-weight:500;">' + info.value + '</span>') }).join('');
            var marker_label = item?.properties?.name || "Centre commercial";
            var el = document.createElement('div');
            el.className = 'marker';
            el.innerHTML = '<div style="cursor:pointer;padding:3px 8px 2px;background-color:#062134;border-radius:6px;display:flex;justify-content:center;align-items:center;gap:4px"><div style="display:flex;justify-content:center">' + marker_img + '</div><div style="font-family:Manrope;color:#FFF;">' + marker_label + '</div></div>';
            map_data.markers.push(new mapboxgl.Marker(el)
                .setLngLat(item_marker_coords)
                .setPopup(new mapboxgl.Popup({ offset: 15 })
                    .setHTML('<p style="font-family:Manrope">' + title_txt + infos_txt + '</p>'))
            )
        }
    }

    // Check data exists
    if (!layer_data.features.length > 0) {
        return map_data
    }

    // Create sources
    map_data.sources.push(
        {
            id: sources_ids[0],
            value: {
                type: 'geojson',
                data: layer_data
            }
        }
    )

    // Create layers
    map_data.layers.push(
        {
            id: layers_ids[0],
            type: 'fill',
            source: sources_ids[0],
            paint: {
                'fill-color': '#062134',
                'fill-opacity': 0.5,
            },
            filter: ['in', '$type', 'Polygon']
        }
    );

    // Set global markers
    if (map_data.markers.length > 0) {
        markers = map_data.markers;
    }
    else {
        markers = [];
    }

    // Get bbox
    var bbox_data = {
        type: 'FeatureCollection',
        features: layer_data.features.concat([capacity?.landBase?.union?.geometry]),
    };
    var bbox = turf.bbox(bbox_data);
    if (bbox && bbox.length === 4) {
        var bbox_poly = [
            [bbox[0], bbox[1]],
            [bbox[2], bbox[1]],
            [bbox[2], bbox[3]],
            [bbox[0], bbox[3]],
            [bbox[0], bbox[1]],
        ]
        map_data.bbox = [bbox_poly];
    }


    // Return
    // console.log("map_data", map_data);
    return map_data

}

