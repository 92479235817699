import * as turf from '@turf/turf';
import mapboxgl from 'mapbox-gl';


const sources_ids = ['sourceCommoditiesParc'];
const layers_ids = ['layerCommoditiesParc_Polys'];
var markers = [];
var marker_img = '<svg width="14" height="14" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_616_13)"><path d="M773.912 747.827H732.589L654.734 669.566L732.589 591.305H773.912C788.314 591.305 800 579.628 800 565.217C800 550.806 788.314 539.13 773.912 539.13C597.502 539.13 495.964 539.13 460.869 539.13C446.458 539.13 434.781 550.806 434.781 565.217C434.781 579.628 446.458 591.305 460.869 591.305H503.28L581.133 669.566L503.28 747.827H365.217V572.388C337.966 569.308 311.552 561.471 286.956 549.066C262.361 561.471 235.947 569.308 208.695 572.388V747.827H26.0875C11.6766 747.827 0 759.503 0 773.913C0 788.324 11.6766 800 26.0875 800C676.139 800 117.673 800 773.914 800C788.316 800 800.002 788.324 800.002 773.913C800 759.503 788.314 747.827 773.912 747.827ZM576.883 591.305H658.986L617.936 632.572L576.883 591.305ZM576.883 747.827L617.934 706.56L658.984 747.827H576.883Z" fill="white"/><path d="M469.395 174.753C465.267 77.6922 385.02 0 286.956 0C189.075 0 108.967 77.4125 104.567 174.223C40.3672 204.616 0 271.305 0 339.13C0 486.098 166.202 573.511 286.956 488.366C407.792 573.569 573.912 486.017 573.912 339.13C573.912 268.513 532.634 204.713 469.395 174.753Z" fill="white"/></g><defs><clipPath id="clip0_616_13"><rect width="800" height="800" fill="white"/></clipPath></defs></svg>';

export const get_ids = () => {
    // Get all source ids
    var sources = [];
    sources_ids.forEach(source_id => {
        sources.push({ id: source_id });
    })
    // Get all layer ids
    var layers = [];
    layers_ids.forEach(layer_id => {
        layers.push({ id: layer_id });
    })

    return {
        sources: sources,
        layers: layers,
        markers: markers,
    }
}


export const get_data = (capacity) => {
    // Set map data
    var map_data = {
        sources: [],
        layers: [],
        markers: [],
        bbox: null
    }

    // Check needed data
    if (!capacity?.context?.commodities?.park_garden?.items || !capacity.context.commodities.park_garden.items.length > 0) {
        return map_data
    }

    // Preset layer data
    var layer_data = {
        type: 'FeatureCollection',
        features: [],
    };

    // Add data
    for (var i = 0; i < capacity.context.commodities.park_garden.items.length; i++) {
        var item = capacity.context.commodities.park_garden.items[i];
        var item_marker_coords = null;
        // Generate polygon
        if (item?.type === "way" && item?.coords && item.coords.length > 3 && item.coords[0].length === 2 && item.coords[0][0] === item.coords[item.coords.length - 1][0] && item.coords[0][1] === item.coords[item.coords.length - 1][1]) {
            layer_data.features.push({
                type: "Feature",
                properties: item?.properties || null,
                geometry: {
                    type: "Polygon",
                    coordinates: [item.coords],
                }
            });
            try {
                var center = turf.centroid(turf.polygon([item.coords]));
                item_marker_coords = center?.geometry?.coordinates || null;
            } catch (error) {
                item_marker_coords = null;
            }
        }
        // Generate point
        else if (item?.type === "node" && item?.coords && item.coords.length === 2) {
            layer_data.features.push({
                type: "Feature",
                properties: item?.properties || null,
                geometry: {
                    type: "Point",
                    coordinates: item.coords,
                }
            });
            item_marker_coords = item.coords;
        }
        // Generate marker
        if (item_marker_coords !== null && item_marker_coords.length === 2) {
            var title = "Parc / Jardin";
            var title_txt = '<span style="color:#062134;font-size:15px;font-weight:700"><strong>' + title + '</strong></span>';
            var infos = [
                { label: "Nom", value: item?.properties?.name || "NC" },
                { label: "Distance", value: item?.properties?.distance ? item?.properties?.distance + " m" : "NC"},
                { label: "Superficie", value: item?.properties?.area ? item?.properties?.area + " m²" : "NC"},
            ]
            var infos_txt = infos.map(info => { return ('<br /><span style="font-size:12px;font-weight: 600;color: grey;">' + info.label + ' :</span><br /><span style="color:#062134;font-weight:500;">' + info.value + '</span>') }).join('');
            var marker_label = item?.properties?.name || "Parc / Jardin";
            var el = document.createElement('div');
            el.className = 'marker';
            el.innerHTML = '<div style="cursor:pointer;padding:3px 8px 2px;background-color:#062134;border-radius:6px;display:flex;justify-content:center;align-items:center;gap:4px"><div style="display:flex;justify-content:center">' + marker_img + '</div><div style="font-family:Manrope;color:#FFF;">' + marker_label + '</div></div>';
            map_data.markers.push(new mapboxgl.Marker(el)
                .setLngLat(item_marker_coords)
                .setPopup(new mapboxgl.Popup({ offset: 15 })
                    .setHTML('<p style="font-family:Manrope">' + title_txt + infos_txt + '</p>'))
            )
        }
    }

    // Check data exists
    if (!layer_data.features.length > 0) {
        return map_data
    }

    // Create sources
    map_data.sources.push(
        {
            id: sources_ids[0],
            value: {
                type: 'geojson',
                data: layer_data
            }
        }
    )

    // Create layers
    map_data.layers.push(
        {
            id: layers_ids[0],
            type: 'fill',
            source: sources_ids[0],
            paint: {
                'fill-color': '#062134',
                'fill-opacity': 0.5,
            },
            filter: ['in', '$type', 'Polygon']
        }
    );

    // Set global markers
    if (map_data.markers.length > 0) {
        markers = map_data.markers;
    }
    else {
        markers = [];
    }

    // Get bbox
    var bbox_data = {
        type: 'FeatureCollection',
        features: layer_data.features.concat([capacity?.landBase?.union?.geometry]),
    };
    var bbox = turf.bbox(bbox_data);
    if (bbox && bbox.length === 4) {
        var bbox_poly = [
            [bbox[0], bbox[1]],
            [bbox[2], bbox[1]],
            [bbox[2], bbox[3]],
            [bbox[0], bbox[3]],
            [bbox[0], bbox[1]],
        ]
        map_data.bbox = [bbox_poly];
    }


    // Return
    // console.log("map_data", map_data);
    return map_data

}

