import React, { useEffect, useState } from 'react';

// Import CSS & assets
import './Commodities.css';
import ico_map_layers from '../../../../assets/ico/ico_map_layers.svg';
import ico_arrow from '../../../../assets/ico/ico_arrow_up.svg';
import ico_transit_station from '../../../../assets/ico/ico_transit_station.svg';
import ico_train from '../../../../assets/ico/ico_train.svg';
import ico_doctor from '../../../../assets/ico/ico_doctor.svg';
import ico_school from '../../../../assets/ico/ico_school.svg';
import ico_grocery from '../../../../assets/ico/ico_grocery.svg';
import ico_parc_garden from '../../../../assets/ico/ico_park.svg';
import ico_car_park from '../../../../assets/ico/ico_car_park.svg';
import ico_charging from '../../../../assets/ico/ico_charging.svg';
import ico_bike_dock from '../../../../assets/ico/ico_bike_station.svg';
import ico_school_bag from '../../../../assets/ico/ico_school_bag.svg';
import ico_highschool from '../../../../assets/ico/ico_highschool.svg';
import ico_mall from '../../../../assets/ico/ico_mall.svg';
import ico_baby from '../../../../assets/ico/ico_baby.svg';
import ico_bus from '../../../../assets/ico/ico_bus.svg';
import ico_medical from '../../../../assets/ico/ico_medical.svg';
import ico_pharmacy from '../../../../assets/ico/ico_pharmacy.svg';
import ico_restaurant from '../../../../assets/ico/ico_restaurant.svg';
import ico_culture from '../../../../assets/ico/ico_culture.svg';

const max_detail = 30;

const Commodities = ({ capacity, mapLayers, layer_Toggle }) => {

    const [detailTransport, setDetailTransport] = useState(false);
    const handleDetailTransport = () => {
        var delay = 0;
        if (detailTransport === true && document.getElementById("commodities_transport").getBoundingClientRect().top < 130) {
            // Scroll
            var topList = document.getElementById("commodities_transport").offsetTop;
            var topSection = document.getElementById("section_3").offsetTop;
            document.getElementById("section_data").scrollTop = topSection + topList + 14;
            delay = 200;
        }
        setTimeout(() => {
            setDetailTransport(!detailTransport);
        }, delay);
    }
    const [detailHospital, setDetailHospital] = useState(false);
    const handleDetailHospital = () => {
        var delay = 0;
        if (detailHospital === true && document.getElementById("commodities_hospital").getBoundingClientRect().top < 130) {
            // Scroll
            var topList = document.getElementById("commodities_hospital").offsetTop;
            var topSection = document.getElementById("section_3").offsetTop;
            document.getElementById("section_data").scrollTop = topSection + topList + 14;
            delay = 200;
        }
        setTimeout(() => {
            setDetailHospital(!detailHospital);
        }, delay);
    }
    const [detailDoctor, setDetailDoctor] = useState(false);
    const handleDetailDoctor = () => {
        var delay = 0;
        if (detailDoctor === true && document.getElementById("commodities_doctor").getBoundingClientRect().top < 130) {
            // Scroll
            var topList = document.getElementById("commodities_doctor").offsetTop;
            var topSection = document.getElementById("section_3").offsetTop;
            document.getElementById("section_data").scrollTop = topSection + topList + 14;
            delay = 200;
        }
        setTimeout(() => {
            setDetailDoctor(!detailDoctor);
        }, delay);
    }
    const [detailPharmacy, setDetailPharmacy] = useState(false);
    const handleDetailPharmacy = () => {
        var delay = 0;
        if (detailPharmacy === true && document.getElementById("commodities_pharmacy").getBoundingClientRect().top < 130) {
            // Scroll
            var topList = document.getElementById("commodities_pharmacy").offsetTop;
            var topSection = document.getElementById("section_3").offsetTop;
            document.getElementById("section_data").scrollTop = topSection + topList + 14;
            delay = 200;
        }
        setTimeout(() => {
            setDetailPharmacy(!detailPharmacy);
        }, delay);
    }
    const [detailBaby, setDetailBaby] = useState(false);
    const handleDetailBaby = () => {
        var delay = 0;
        if (detailBaby === true && document.getElementById("commodities_baby").getBoundingClientRect().top < 130) {
            // Scroll
            var topList = document.getElementById("commodities_baby").offsetTop;
            var topSection = document.getElementById("section_3").offsetTop;
            document.getElementById("section_data").scrollTop = topSection + topList + 14;
            delay = 200;
        }
        setTimeout(() => {
            setDetailBaby(!detailBaby);
        }, delay);
    }
    const [detailSchool, setDetailSchool] = useState(false);
    const handleDetailSchool = () => {
        var delay = 0;
        if (detailSchool === true && document.getElementById("commodities_school").getBoundingClientRect().top < 130) {
            // Scroll
            var topList = document.getElementById("commodities_school").offsetTop;
            var topSection = document.getElementById("section_3").offsetTop;
            document.getElementById("section_data").scrollTop = topSection + topList + 14;
            delay = 200;
        }
        setTimeout(() => {
            setDetailSchool(!detailSchool);
        }, delay);
    }
    const [detailHighSchool, setDetailHighSchool] = useState(false);
    const handleDetailHighSchool = () => {
        var delay = 0;
        if (detailHighSchool === true && document.getElementById("commodities_highschool").getBoundingClientRect().top < 130) {
            // Scroll
            var topList = document.getElementById("commodities_highschool").offsetTop;
            var topSection = document.getElementById("section_3").offsetTop;
            document.getElementById("section_data").scrollTop = topSection + topList + 14;
            delay = 200;
        }
        setTimeout(() => {
            setDetailHighSchool(!detailHighSchool);
        }, delay);
    }
    const [detailUniversity, setDetailUniversity] = useState(false);
    const handleDetailUniversity = () => {
        var delay = 0;
        if (detailUniversity === true && document.getElementById("commodities_university").getBoundingClientRect().top < 130) {
            // Scroll
            var topList = document.getElementById("commodities_university").offsetTop;
            var topSection = document.getElementById("section_3").offsetTop;
            document.getElementById("section_data").scrollTop = topSection + topList + 14;
            delay = 200;
        }
        setTimeout(() => {
            setDetailUniversity(!detailUniversity);
        }, delay);
    }
    const [detailMall, setDetailMall] = useState(false);
    const handleDetailMall = () => {
        var delay = 0;
        if (detailMall === true && document.getElementById("commodities_mall").getBoundingClientRect().top < 130) {
            // Scroll
            var topList = document.getElementById("commodities_mall").offsetTop;
            var topSection = document.getElementById("section_3").offsetTop;
            document.getElementById("section_data").scrollTop = topSection + topList + 14;
            delay = 200;
        }
        setTimeout(() => {
            setDetailMall(!detailMall);
        }, delay);
    }
    const [detailShop, setDetailShop] = useState(false);
    const handleDetailShop = () => {
        var delay = 0;
        if (detailShop === true && document.getElementById("commodities_shop").getBoundingClientRect().top < 130) {
            // Scroll
            var topList = document.getElementById("commodities_shop").offsetTop;
            var topSection = document.getElementById("section_3").offsetTop;
            document.getElementById("section_data").scrollTop = topSection + topList + 14;
            delay = 200;
        }
        setTimeout(() => {
            setDetailShop(!detailShop);
        }, delay);
    }
    const [detailParcGarden, setDetailParcGarden] = useState(false);
    const handleDetailParcGarden = () => {
        var delay = 0;
        if (detailParcGarden === true && document.getElementById("commodities_parc_garden").getBoundingClientRect().top < 130) {
            // Scroll
            var topList = document.getElementById("commodities_parc_garden").offsetTop;
            var topSection = document.getElementById("section_3").offsetTop;
            document.getElementById("section_data").scrollTop = topSection + topList + 14;
            delay = 200;
        }
        setTimeout(() => {
            setDetailParcGarden(!detailParcGarden);
        }, delay);
    }
    const [detailBike, setDetailBike] = useState(false);
    const handleDetailBike = () => {
        var delay = 0;
        if (detailBike === true && document.getElementById("commodities_bike").getBoundingClientRect().top < 130) {
            // Scroll
            var topList = document.getElementById("commodities_bike").offsetTop;
            var topSection = document.getElementById("section_3").offsetTop;
            document.getElementById("section_data").scrollTop = topSection + topList + 14;
            delay = 200;
        }
        setTimeout(() => {
            setDetailBike(!detailBike);
        }, delay);
    }
    const [detailTrain, setDetailTrain] = useState(false);
    const handleDetailTrain = () => {
        var delay = 0;
        if (detailTrain === true && document.getElementById("commodities_train").getBoundingClientRect().top < 130) {
            // Scroll
            var topList = document.getElementById("commodities_train").offsetTop;
            var topSection = document.getElementById("section_3").offsetTop;
            document.getElementById("section_data").scrollTop = topSection + topList + 14;
            delay = 200;
        }
        setTimeout(() => {
            setDetailTrain(!detailTrain);
        }, delay);
    }
    const [detailRestaurant, setDetailRestaurant] = useState(false);
    const handleDetailRestaurant = () => {
        var delay = 0;
        if (detailRestaurant === true && document.getElementById("commodities_restaurant").getBoundingClientRect().top < 130) {
            // Scroll
            var topList = document.getElementById("commodities_restaurant").offsetTop;
            var topSection = document.getElementById("section_3").offsetTop;
            document.getElementById("section_data").scrollTop = topSection + topList + 14;
            delay = 200;
        }
        setTimeout(() => {
            setDetailRestaurant(!detailRestaurant);
        }, delay);
    }
    const [detailCulture, setDetailCulture] = useState(false);
    const handleDetailCulture = () => {
        var delay = 0;
        if (detailCulture === true && document.getElementById("commodities_culture").getBoundingClientRect().top < 130) {
            // Scroll
            var topList = document.getElementById("commodities_culture").offsetTop;
            var topSection = document.getElementById("section_3").offsetTop;
            document.getElementById("section_data").scrollTop = topSection + topList + 14;
            delay = 200;
        }
        setTimeout(() => {
            setDetailCulture(!detailCulture);
        }, delay);
    }



    return (
        <>
            <div className="app__sectiondata_widget_spacer_10"></div>
            {/* <div id="section_3_amenities" className="app__sectiondata_widget_title">Commodités à proximité du terrain</div> */}
            {/* PARKING */}
            {((capacity?.context?.commodities?.parking?.items && capacity.context.commodities.parking.items.length > 0) || (capacity?.context?.commodities?.charging?.items && capacity.context.commodities.charging.items.length > 0)) &&
                <>
                    <div className="app__sectiondata_widget_spacer_20"></div>
                    <div id="section_3_amenities" className="app__sectiondata_widget_title">Commodités à proximité du terrain : Stationnements</div>
                    {/* CAR PARK */}
                    {(capacity?.context?.commodities?.parking?.items && capacity.context.commodities.parking.items.length > 0) &&
                        <>
                            <div id="commodities_car_park" className="app__sectiondata__widget">
                                <div className="app__sectiondata_widget_title_container">
                                    <div className="app__sectiondata_widget_title">Stationnements (dans un rayon de 500m autour du terrain)</div>
                                    <div className="app__sectiondata_widget_layer">
                                        <img src={ico_map_layers} alt="ico_map_layers" />
                                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_context_commodities_car_park" checked={mapLayers.context_commodities_car_park.isVisible} onChange={() => { layer_Toggle("context_commodities_car_park") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_context_commodities_car_park">Toggle</label></div>
                                    </div>
                                </div>
                                <div className="app__context_iso_container_small">
                                    <img src={ico_car_park} alt="iso_car_park" />
                                    <div>
                                        <div className="app__context_iso_value_second_strong"><span className="app__context_iso_value_main_small">{(capacity?.context?.commodities?.parking?.total_slots || 0)}</span> places de stationnement{capacity?.context?.commodities?.parking?.total_slots_free > 0 ? " dont " + capacity?.context?.commodities?.parking?.total_slots_free + " places gratuites" : ""}</div>
                                        <div className="app__context_iso_value_second_small">pakings publics, aériens ou souterrains</div>
                                    </div>
                                </div>
                            </div>
                            <div className="app__sectiondata_widget_spacer_10"></div>
                        </>
                    }
                    {/* CHARGING */}
                    {(capacity?.context?.commodities?.charging?.items && capacity.context.commodities.charging.items.length > 0) &&
                        <>
                            <div id="commodities_charging" className="app__sectiondata__widget">
                                <div className="app__sectiondata_widget_title_container">
                                    <div className="app__sectiondata_widget_title">Bornes de chargement (dans un rayon de 500m autour du terrain)</div>
                                    <div className="app__sectiondata_widget_layer">
                                        <img src={ico_map_layers} alt="ico_map_layers" />
                                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_context_commodities_car_charging" checked={mapLayers.context_commodities_car_charging.isVisible} onChange={() => { layer_Toggle("context_commodities_car_charging") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_context_commodities_car_charging">Toggle</label></div>
                                    </div>
                                </div>
                                <div className="app__context_iso_container_small">
                                    <img src={ico_charging} alt="iso_charging" />
                                    <div>
                                        <div className="app__context_iso_value_second_strong"><span className="app__context_iso_value_main_small">{(capacity?.context?.commodities?.charging?.total_slots || 0)}</span> bornes de chargement</div>
                                        <div className="app__context_iso_value_second_small">bornes de chargement pour véhicule électrique</div>
                                    </div>
                                </div>
                            </div>
                            <div className="app__sectiondata_widget_spacer_10"></div>
                        </>
                    }
                </>
            }
            {/* TRANSPORTS */}
            {((capacity?.context?.commodities?.transports_bus_tram_subway?.stops?.bus && capacity.context.commodities.transports_bus_tram_subway.stops.bus.length > 0) || (capacity?.context?.commodities?.transports_bus_tram_subway?.stops?.tram && capacity.context.commodities.transports_bus_tram_subway.stops.tram.length > 0) || (capacity?.context?.commodities?.transports_bus_tram_subway?.stops?.subway && capacity.context.commodities.transports_bus_tram_subway.stops.subway.length > 0) || (capacity?.context?.commodities?.transports_train?.items && capacity.context.commodities.transports_train.items.length > 0) || (capacity?.context?.commodities?.transports_bike?.items && capacity.context.commodities.transports_bike.items.length > 0)) &&
                <>
                    <div className="app__sectiondata_widget_spacer_20"></div>
                    <div id="section_3_amenities" className="app__sectiondata_widget_title">Commodités à proximité du terrain : Transports</div>
                    {/* BUS TRAMWAY SUBWAY */}
                    {((capacity?.context?.commodities?.transports_bus_tram_subway?.stops?.bus && capacity.context.commodities.transports_bus_tram_subway.stops.bus.length > 0) || (capacity?.context?.commodities?.transports_bus_tram_subway?.stops?.tram && capacity.context.commodities.transports_bus_tram_subway.stops.tram.length > 0) || (capacity?.context?.commodities?.transports_bus_tram_subway?.stops?.subway && capacity.context.commodities.transports_bus_tram_subway.stops.subway.length > 0)) &&
                        <>
                            <div id="commodities_transport" className="app__sectiondata__widget" style={{ paddingBottom: "8px" }}>
                                <div className="app__sectiondata_widget_title_container">
                                    <div className="app__sectiondata_widget_title">Transports en commun (dans un rayon de 500m autour du terrain)</div>
                                    <div className="app__sectiondata_widget_layer">
                                        <img src={ico_map_layers} alt="ico_map_layers" />
                                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_context_commodities_transport" checked={mapLayers.context_commodities_transport.isVisible} onChange={() => { layer_Toggle("context_commodities_transport") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_context_commodities_transport">Toggle</label></div>
                                    </div>
                                </div>
                                <div className="app__context_iso_container_small">
                                    <img src={ico_bus} alt="iso_bus" />
                                    <div>
                                        <div className="app__context_iso_value_second_strong"><span className="app__context_iso_value_main_small">{capacity?.context?.commodities?.transports_bus_tram_subway?.sum_values?.all?.lines || 0}</span> lignes de transports en commun</div>
                                        <div className="app__context_iso_value_second_small">bus, tramway ou métro</div>
                                    </div>
                                </div>
                                <div className={`app__context_commodities_detail_container ${detailTransport ? "" : "app__context_commodities_detail_container_hidden"}`}>
                                    {/* BUS */}
                                    {(capacity?.context?.commodities?.transports_bus_tram_subway?.stops?.bus && capacity.context.commodities.transports_bus_tram_subway.stops.bus.length > 0) &&
                                        <>
                                            <div className="app__context_commodities_label">BUS : {capacity.context.commodities.transports_bus_tram_subway.stops.bus.length > 1 ? capacity.context.commodities.transports_bus_tram_subway.stops.bus.length + " arrêts désservants" : "1 arrêt désservant"} {capacity?.context?.commodities?.transports_bus_tram_subway?.sum_values?.bus?.lines > 1 ? capacity.context.commodities.transports_bus_tram_subway.sum_values.bus.lines + " lignes de bus" : "1 ligne de bus"}</div>
                                            <div className="app__context_commodities_titles_container">
                                                <div className="app__context_commodities_title app__context_commodities_title_3">Ligne</div>
                                                <div className="app__context_commodities_title app__context_commodities_title_3">Arrêt</div>
                                                <div className="app__context_commodities_title app__context_commodities_title_3">Distance du terrain</div>
                                            </div>
                                            {capacity.context.commodities.transports_bus_tram_subway.stops.bus.map((stop, index) => {
                                                return (
                                                    <>
                                                        {(stop?.lines && stop.lines.length > 0) &&
                                                            stop.lines.map((itm, itm_i) => {
                                                                if (itm_i < max_detail) {
                                                                    return (
                                                                        <div key={"bus_item" + itm_i} className="app__context_commodities_values_container">
                                                                            <div className="app__context_commodities_value app__context_commodities_title_3"><div className="app__context_commodities_value_color" style={{ backgroundColor: itm?.color || "", color: itm?.color_txt || "" }}>{itm?.name}</div></div>
                                                                            <div className="app__context_commodities_value app__context_commodities_title_3">{stop?.name}</div>
                                                                            <div className="app__context_commodities_value app__context_commodities_title_3">{stop?.distance} m</div>
                                                                        </div>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </>
                                                )
                                            })}
                                        </>
                                    }
                                    {/* TRAM */}
                                    {(capacity?.context?.commodities?.transports_bus_tram_subway?.stops?.tram && capacity.context.commodities.transports_bus_tram_subway.stops.tram.length > 0) &&
                                        <>
                                            <div className="app__context_commodities_label">TRAMWAY : {capacity.context.commodities.transports_bus_tram_subway.stops.tram.length > 1 ? capacity.context.commodities.transports_bus_tram_subway.stops.tram.length + " arrêts désservants" : "1 arrêt désservant"} {capacity?.context?.commodities?.transports_bus_tram_subway?.sum_values?.tram?.lines > 1 ? capacity.context.commodities.transports_bus_tram_subway.sum_values.tram.lines + " lignes de tram" : "1 ligne de tram"}</div>
                                            <div className="app__context_commodities_titles_container">
                                                <div className="app__context_commodities_title app__context_commodities_title_3">Ligne</div>
                                                <div className="app__context_commodities_title app__context_commodities_title_3">Arrêt</div>
                                                <div className="app__context_commodities_title app__context_commodities_title_3">Distance du terrain</div>
                                            </div>
                                            {capacity.context.commodities.transports_bus_tram_subway.stops.tram.map(stop => {
                                                return (
                                                    <>
                                                        {(stop?.lines && stop.lines.length > 0) &&
                                                            stop.lines.map((itm, itm_i) => {
                                                                if (itm_i < max_detail) {
                                                                    return (
                                                                        <div className="app__context_commodities_values_container">
                                                                            <div className="app__context_commodities_value app__context_commodities_title_3"><div className="app__context_commodities_value_color" style={{ backgroundColor: itm?.color || "", color: itm?.color_txt || "" }}>{itm?.name}</div></div>
                                                                            <div className="app__context_commodities_value app__context_commodities_title_3">{stop?.name}</div>
                                                                            <div className="app__context_commodities_value app__context_commodities_title_3">{stop?.distance} m</div>
                                                                        </div>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </>
                                                )
                                            })}
                                        </>
                                    }
                                    {/* SUBWAY */}
                                    {(capacity?.context?.commodities?.transports_bus_tram_subway?.stops?.subway && capacity.context.commodities.transports_bus_tram_subway.stops.subway.length > 0) &&
                                        <>
                                            <div className="app__context_commodities_label">MÉTRO : {capacity.context.commodities.transports_bus_tram_subway.stops.subway.length > 1 ? capacity.context.commodities.transports_bus_tram_subway.stops.subway.length + " arrêts désservants" : "1 arrêt désservant"} {capacity?.context?.commodities?.transports_bus_tram_subway?.sum_values?.subway?.lines > 1 ? capacity.context.commodities.transports_bus_tram_subway.sum_values.subway.lines + " lignes de métro" : "1 ligne de métro"}</div>
                                            <div className="app__context_commodities_titles_container">
                                                <div className="app__context_commodities_title app__context_commodities_title_3">Ligne</div>
                                                <div className="app__context_commodities_title app__context_commodities_title_3">Arrêt</div>
                                                <div className="app__context_commodities_title app__context_commodities_title_3">Distance du terrain</div>
                                            </div>
                                            {capacity.context.commodities.transports_bus_tram_subway.stops.subway.map(stop => {
                                                return (
                                                    <>
                                                        {(stop?.lines && stop.lines.length > 0) &&
                                                            stop.lines.map((itm, itm_i) => {
                                                                if (itm_i < max_detail) {
                                                                    return (
                                                                        <div className="app__context_commodities_values_container">
                                                                            <div className="app__context_commodities_value app__context_commodities_title_3"><div className="app__context_commodities_value_color" style={{ backgroundColor: itm?.color || "", color: itm?.color_txt || "" }}>{itm?.name}</div></div>
                                                                            <div className="app__context_commodities_value app__context_commodities_title_3">{stop?.name}</div>
                                                                            <div className="app__context_commodities_value app__context_commodities_title_3">{stop?.distance} m</div>
                                                                        </div>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </>
                                                )
                                            })}
                                        </>
                                    }
                                </div>
                                <div className="app__context_housing_detail_button_container" style={{ marginTop: "6px" }} onClick={() => { handleDetailTransport() }}>
                                    {detailTransport ?
                                        <div className="app__context_housing_detail_button_up"><img src={ico_arrow} alt="arrow_up" /></div>
                                        :
                                        <div className="app__context_housing_detail_button_down"><img src={ico_arrow} alt="arrow_up" /></div>
                                    }
                                </div>
                            </div>
                            <div className="app__sectiondata_widget_spacer_10"></div>
                        </>
                    }
                    {/* TRAIN */}
                    {(capacity?.context?.commodities?.transports_train?.items && capacity.context.commodities.transports_train.items.length > 0) &&
                        <>
                            <div id="commodities_train" className="app__sectiondata__widget" style={{ paddingBottom: "8px" }}>
                                <div className="app__sectiondata_widget_title_container">
                                    <div className="app__sectiondata_widget_title">Gares ou haltes ferroviaires (dans un rayon de 5km autour du terrain)</div>
                                    <div className="app__sectiondata_widget_layer">
                                        <img src={ico_map_layers} alt="ico_map_layers" />
                                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_context_commodities_train" checked={mapLayers.context_commodities_train.isVisible} onChange={() => { layer_Toggle("context_commodities_train") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_context_commodities_train">Toggle</label></div>
                                    </div>
                                </div>
                                <div className="app__context_iso_container_small">
                                    <img src={ico_train} alt="iso_train" />
                                    <div>
                                        <div className="app__context_iso_value_second_strong"><span className="app__context_iso_value_main_small">{(capacity?.context?.commodities?.transports_train?.items ? capacity.context.commodities.transports_train.items.length : 0)}</span> gares ou haltes ferroviaires</div>
                                        <div className="app__context_iso_value_second_small">gares ou haltes ferroviaires</div>
                                    </div>
                                </div>
                                <div className={`app__context_commodities_detail_container ${detailTrain ? "" : "app__context_commodities_detail_container_hidden"}`}>
                                    {/* DETAILS */}
                                    <div className="app__context_commodities_titles_container" style={{ marginTop: '14px' }}>
                                        <div className="app__context_commodities_title app__context_commodities_title_3">Nom</div>
                                        <div className="app__context_commodities_title app__context_commodities_title_3">Opérateur</div>
                                        <div className="app__context_commodities_title app__context_commodities_title_3">Distance du terrain</div>
                                    </div>
                                    {capacity.context.commodities.transports_train.items.map((itm, itm_i) => {
                                        if (itm_i < max_detail) {
                                            return (
                                                <div className="app__context_commodities_values_container">
                                                    <div className="app__context_commodities_value app__context_commodities_title_3">{itm?.properties?.name}</div>
                                                    <div className="app__context_commodities_value app__context_commodities_title_3">{itm?.properties?.operator}</div>
                                                    <div className="app__context_commodities_value app__context_commodities_title_3">{itm?.properties?.distance} m</div>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                                <div className="app__context_housing_detail_button_container" style={{ marginTop: "6px" }} onClick={() => { handleDetailTrain() }}>
                                    {detailTrain ?
                                        <div className="app__context_housing_detail_button_up"><img src={ico_arrow} alt="arrow_up" /></div>
                                        :
                                        <div className="app__context_housing_detail_button_down"><img src={ico_arrow} alt="arrow_up" /></div>
                                    }
                                </div>
                            </div>
                            <div className="app__sectiondata_widget_spacer_10"></div>
                        </>
                    }
                    {/* BIKE */}
                    {(capacity?.context?.commodities?.transports_bike?.items && capacity.context.commodities.transports_bike.items.length > 0) &&
                        <>
                            <div id="commodities_bike" className="app__sectiondata__widget" style={{ paddingBottom: "8px" }}>
                                <div className="app__sectiondata_widget_title_container">
                                    <div className="app__sectiondata_widget_title">Stations de vélo (dans un rayon de 500m autour du terrain)</div>
                                    <div className="app__sectiondata_widget_layer">
                                        <img src={ico_map_layers} alt="ico_map_layers" />
                                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_context_commodities_bike" checked={mapLayers.context_commodities_bike.isVisible} onChange={() => { layer_Toggle("context_commodities_bike") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_context_commodities_bike">Toggle</label></div>
                                    </div>
                                </div>
                                <div className="app__context_iso_container_small">
                                    <img src={ico_bike_dock} alt="iso_parcgarden" />
                                    <div>
                                        <div className="app__context_iso_value_second_strong"><span className="app__context_iso_value_main_small">{(capacity?.context?.commodities?.transports_bike?.items ? capacity.context.commodities.transports_bike.items.length : 0)}</span> stations de vélo</div>
                                        <div className="app__context_iso_value_second_small">vélos en libre service</div>
                                    </div>
                                </div>
                                <div className={`app__context_commodities_detail_container ${detailBike ? "" : "app__context_commodities_detail_container_hidden"}`}>
                                    {/* DETAILS */}
                                    <div className="app__context_commodities_titles_container" style={{ marginTop: '14px' }}>
                                        <div className="app__context_commodities_title app__context_commodities_title_3">Nom</div>
                                        <div className="app__context_commodities_title app__context_commodities_title_3">Service</div>
                                        <div className="app__context_commodities_title app__context_commodities_title_3">Distance du terrain</div>
                                    </div>
                                    {capacity.context.commodities.transports_bike.items.map((itm, itm_i) => {
                                        if (itm_i < max_detail) {
                                            return (
                                                <div className="app__context_commodities_values_container">
                                                    <div className="app__context_commodities_value app__context_commodities_title_3">{itm?.properties?.name}</div>
                                                    <div className="app__context_commodities_value app__context_commodities_title_3">{itm?.properties?.network}{itm?.properties?.operator ? " (" + itm?.properties?.operator + ")" : ""}</div>
                                                    <div className="app__context_commodities_value app__context_commodities_title_3">{itm?.properties?.distance} m</div>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                                <div className="app__context_housing_detail_button_container" style={{ marginTop: "6px" }} onClick={() => { handleDetailBike() }}>
                                    {detailBike ?
                                        <div className="app__context_housing_detail_button_up"><img src={ico_arrow} alt="arrow_up" /></div>
                                        :
                                        <div className="app__context_housing_detail_button_down"><img src={ico_arrow} alt="arrow_up" /></div>
                                    }
                                </div>
                            </div>
                            <div className="app__sectiondata_widget_spacer_10"></div>
                        </>
                    }
                </>
            }
            {/* HEALTH */}
            {((capacity?.context?.commodities?.health_hospital?.items?.hospital && capacity.context.commodities.health_hospital.items.hospital.length > 0) || (capacity?.context?.commodities?.health_hospital?.items?.clinic && capacity.context.commodities.health_hospital.items.clinic.length > 0) || (capacity?.context?.commodities?.health_hospital?.items?.laboratory && capacity.context.commodities.health_hospital.items.laboratory.length > 0) || (capacity?.context?.commodities?.health_doctor?.items?.doctor && capacity.context.commodities.health_doctor.items.doctor.length > 0) || (capacity?.context?.commodities?.health_doctor?.items?.nurse && capacity.context.commodities.health_doctor.items.nurse.length > 0) || (capacity?.context?.commodities?.health_pharmacy?.items?.pharmacy && capacity.context.commodities.health_pharmacy.items.pharmacy.length > 0)) &&
                <>
                    <div className="app__sectiondata_widget_spacer_20"></div>
                    <div id="section_3_amenities" className="app__sectiondata_widget_title">Commodités à proximité du terrain : Santé</div>
                    {/* HOSPITAL */}
                    {((capacity?.context?.commodities?.health_hospital?.items?.hospital && capacity.context.commodities.health_hospital.items.hospital.length > 0) || (capacity?.context?.commodities?.health_hospital?.items?.clinic && capacity.context.commodities.health_hospital.items.clinic.length > 0) || (capacity?.context?.commodities?.health_hospital?.items?.laboratory && capacity.context.commodities.health_hospital.items.laboratory.length > 0)) &&
                        <>
                            <div id="commodities_hospital" className="app__sectiondata__widget" style={{ paddingBottom: "8px" }}>
                                <div className="app__sectiondata_widget_title_container">
                                    <div className="app__sectiondata_widget_title">Étabilssements de soins et d'analyses (dans un rayon de 1000m autour du terrain)</div>
                                    <div className="app__sectiondata_widget_layer">
                                        <img src={ico_map_layers} alt="ico_map_layers" />
                                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_context_commodities_hospital" checked={mapLayers.context_commodities_hospital.isVisible} onChange={() => { layer_Toggle("context_commodities_hospital") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_context_commodities_hospital">Toggle</label></div>
                                    </div>
                                </div>
                                <div className="app__context_iso_container_small">
                                    <img src={ico_doctor} alt="iso_doctor" />
                                    <div>
                                        <div className="app__context_iso_value_second_strong"><span className="app__context_iso_value_main_small">{(capacity?.context?.commodities?.health_hospital?.items?.hospital ? capacity.context.commodities.health_hospital.items.hospital.length : 0) + (capacity?.context?.commodities?.health_hospital?.items?.clinic ? capacity.context.commodities.health_hospital.items.clinic.length : 0) + (capacity?.context?.commodities?.health_hospital?.items?.laboratory ? capacity.context.commodities.health_hospital.items.laboratory.length : 0)}</span> étabilssements de soins</div>
                                        <div className="app__context_iso_value_second_small">hôpitaux, cliniques ou laboratoires</div>
                                    </div>
                                </div>
                                <div className={`app__context_commodities_detail_container ${detailHospital ? "" : "app__context_commodities_detail_container_hidden"}`}>
                                    {/* DETAILS */}
                                    {Object.keys(capacity.context.commodities.health_hospital.items).map(category => {
                                        if (capacity.context.commodities.health_hospital.items[category].length > 0) {
                                            var txt_single = "1 Étabilssement";
                                            var txt_multiple = " Étabilssements";
                                            if (category === "hospital") { txt_single = "1 Hôpital"; txt_multiple = " Hôpitaux"; }
                                            if (category === "clinic") { txt_single = "1 Clinique"; txt_multiple = " Cliniques"; }
                                            if (category === "doctor") { txt_single = "1 Cabinet médical"; txt_multiple = " Cabinets médicaux"; }
                                            if (category === "nurse") { txt_single = "1 Cabinet infirmier"; txt_multiple = " Cabinets infirmiers"; }
                                            if (category === "laboratory") { txt_single = "1 Laboratoire"; txt_multiple = " Laboratoires"; }
                                            if (category === "pharmacy") { txt_single = "1 Pharmacie"; txt_multiple = " Pharmacies"; }
                                            return (
                                                <>
                                                    <div className="app__context_commodities_label">{capacity.context.commodities.health_hospital.items[category].length > 1 ? capacity.context.commodities.health_hospital.items[category].length + txt_multiple : txt_single}</div>
                                                    <div className="app__context_commodities_titles_container">
                                                        <div className="app__context_commodities_title app__context_commodities_title_3">Nom</div>
                                                        <div className="app__context_commodities_title app__context_commodities_title_3">{(category === "nurse" || category === "pharmacy" || category === "laboratory") ? "" : "Spécialité"}</div>
                                                        <div className="app__context_commodities_title app__context_commodities_title_3">Distance du terrain</div>
                                                    </div>
                                                    {capacity.context.commodities.health_hospital.items[category].map((itm, itm_i) => {
                                                        if (itm_i < max_detail) {
                                                            return (
                                                                <div className="app__context_commodities_values_container">
                                                                    <div className="app__context_commodities_value app__context_commodities_title_3">{itm?.properties?.name}</div>
                                                                    <div className="app__context_commodities_value app__context_commodities_title_3" style={{ textTransform: 'capitalize' }}>{(category === "nurse" || category === "pharmacy" || category === "laboratory") ? "" : itm?.properties?.speciality}</div>
                                                                    <div className="app__context_commodities_value app__context_commodities_title_3">{itm?.distance} m</div>
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                </>
                                            )
                                        }
                                    })}

                                </div>
                                <div className="app__context_housing_detail_button_container" style={{ marginTop: "6px" }} onClick={() => { handleDetailHospital() }}>
                                    {detailHospital ?
                                        <div className="app__context_housing_detail_button_up"><img src={ico_arrow} alt="arrow_up" /></div>
                                        :
                                        <div className="app__context_housing_detail_button_down"><img src={ico_arrow} alt="arrow_up" /></div>
                                    }
                                </div>
                            </div>
                            <div className="app__sectiondata_widget_spacer_10"></div>
                        </>
                    }
                    {/* DOCTOR */}
                    {((capacity?.context?.commodities?.health_doctor?.items?.doctor && capacity.context.commodities.health_doctor.items.doctor.length > 0) || (capacity?.context?.commodities?.health_doctor?.items?.nurse && capacity.context.commodities.health_doctor.items.nurse.length > 0)) &&
                        <>
                            <div id="commodities_doctor" className="app__sectiondata__widget" style={{ paddingBottom: "8px" }}>
                                <div className="app__sectiondata_widget_title_container">
                                    <div className="app__sectiondata_widget_title">Cabinets médicaux (dans un rayon de 1000m autour du terrain)</div>
                                    <div className="app__sectiondata_widget_layer">
                                        <img src={ico_map_layers} alt="ico_map_layers" />
                                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_context_commodities_doctor" checked={mapLayers.context_commodities_doctor.isVisible} onChange={() => { layer_Toggle("context_commodities_doctor") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_context_commodities_doctor">Toggle</label></div>
                                    </div>
                                </div>
                                <div className="app__context_iso_container_small">
                                    <img src={ico_medical} alt="iso_medical" />
                                    <div>
                                        <div className="app__context_iso_value_second_strong"><span className="app__context_iso_value_main_small">{(capacity?.context?.commodities?.health_doctor?.items?.doctor ? capacity.context.commodities.health_doctor.items.doctor.length : 0) + (capacity?.context?.commodities?.health_doctor?.items?.nurse ? capacity.context.commodities.health_doctor.items.nurse.length : 0)}</span> cabinets médicaux</div>
                                        <div className="app__context_iso_value_second_small">cabinets médicaux, cabinets infirmiers ou maisons de santé</div>
                                    </div>
                                </div>
                                <div className={`app__context_commodities_detail_container ${detailDoctor ? "" : "app__context_commodities_detail_container_hidden"}`}>
                                    {/* DETAILS */}
                                    {Object.keys(capacity.context.commodities.health_doctor.items).map(category => {
                                        if (capacity.context.commodities.health_doctor.items[category].length > 0) {
                                            var txt_single = "1 Étabilssement";
                                            var txt_multiple = " Étabilssements";
                                            if (category === "hospital") { txt_single = "1 Hôpital"; txt_multiple = " Hôpitaux"; }
                                            if (category === "clinic") { txt_single = "1 Clinique"; txt_multiple = " Cliniques"; }
                                            if (category === "doctor") { txt_single = "1 Cabinet médical"; txt_multiple = " Cabinets médicaux"; }
                                            if (category === "nurse") { txt_single = "1 Cabinet infirmier"; txt_multiple = " Cabinets infirmiers"; }
                                            if (category === "laboratory") { txt_single = "1 Laboratoire"; txt_multiple = " Laboratoires"; }
                                            if (category === "pharmacy") { txt_single = "1 Pharmacie"; txt_multiple = " Pharmacies"; }
                                            return (
                                                <>
                                                    <div className="app__context_commodities_label">{capacity.context.commodities.health_doctor.items[category].length > 1 ? capacity.context.commodities.health_doctor.items[category].length + txt_multiple : txt_single}</div>
                                                    <div className="app__context_commodities_titles_container">
                                                        <div className="app__context_commodities_title app__context_commodities_title_3">Nom</div>
                                                        <div className="app__context_commodities_title app__context_commodities_title_3">{(category === "nurse" || category === "pharmacy" || category === "laboratory") ? "" : "Spécialité"}</div>
                                                        <div className="app__context_commodities_title app__context_commodities_title_3">Distance du terrain</div>
                                                    </div>
                                                    {capacity.context.commodities.health_doctor.items[category].map((itm, itm_i) => {
                                                        if (itm_i < max_detail) {
                                                            return (
                                                                <div className="app__context_commodities_values_container">
                                                                    <div className="app__context_commodities_value app__context_commodities_title_3">{itm?.properties?.name}</div>
                                                                    <div className="app__context_commodities_value app__context_commodities_title_3" style={{ textTransform: 'capitalize' }}>{(category === "nurse" || category === "pharmacy" || category === "laboratory") ? "" : itm?.properties?.speciality}</div>
                                                                    <div className="app__context_commodities_value app__context_commodities_title_3">{itm?.distance} m</div>
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                </>
                                            )
                                        }
                                    })}

                                </div>
                                <div className="app__context_housing_detail_button_container" style={{ marginTop: "6px" }} onClick={() => { handleDetailDoctor() }}>
                                    {detailDoctor ?
                                        <div className="app__context_housing_detail_button_up"><img src={ico_arrow} alt="arrow_up" /></div>
                                        :
                                        <div className="app__context_housing_detail_button_down"><img src={ico_arrow} alt="arrow_up" /></div>
                                    }
                                </div>
                            </div>
                            <div className="app__sectiondata_widget_spacer_10"></div>
                        </>
                    }
                    {/* PHARMACY */}
                    {((capacity?.context?.commodities?.health_pharmacy?.items && capacity.context.commodities.health_pharmacy.items.length > 0)) &&
                        <>
                            <div id="commodities_pharmacy" className="app__sectiondata__widget" style={{ paddingBottom: "8px" }}>
                                <div className="app__sectiondata_widget_title_container">
                                    <div className="app__sectiondata_widget_title">Pharmacies (dans un rayon de 1000m autour du terrain)</div>
                                    <div className="app__sectiondata_widget_layer">
                                        <img src={ico_map_layers} alt="ico_map_layers" />
                                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_context_commodities_pharmacy" checked={mapLayers.context_commodities_pharmacy.isVisible} onChange={() => { layer_Toggle("context_commodities_pharmacy") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_context_commodities_pharmacy">Toggle</label></div>
                                    </div>
                                </div>
                                <div className="app__context_iso_container_small">
                                    <img src={ico_pharmacy} alt="iso_pharmacy" />
                                    <div>
                                        <div className="app__context_iso_value_second_strong"><span className="app__context_iso_value_main_small">{capacity.context.commodities.health_pharmacy.items.length || 0}</span> pharmacies</div>
                                        <div className="app__context_iso_value_second_small">pharmacies</div>
                                    </div>
                                </div>
                                <div className={`app__context_commodities_detail_container ${detailPharmacy ? "" : "app__context_commodities_detail_container_hidden"}`}>
                                    {/* DETAILS */}
                                    <div className="app__context_commodities_titles_container" style={{ marginTop: '14px' }}>
                                        <div className="app__context_commodities_title app__context_commodities_title_2">Nom</div>
                                        <div className="app__context_commodities_title app__context_commodities_title_2">Distance du terrain</div>
                                    </div>
                                    {capacity.context.commodities.health_pharmacy.items.map((itm, itm_i) => {
                                        if (itm_i < max_detail) {
                                            return (
                                                <div className="app__context_commodities_values_container">
                                                    <div className="app__context_commodities_value app__context_commodities_title_2">{itm?.properties?.name}</div>
                                                    <div className="app__context_commodities_value app__context_commodities_title_2">{itm?.distance} m</div>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                                <div className="app__context_housing_detail_button_container" style={{ marginTop: "6px" }} onClick={() => { handleDetailPharmacy() }}>
                                    {detailPharmacy ?
                                        <div className="app__context_housing_detail_button_up"><img src={ico_arrow} alt="arrow_up" /></div>
                                        :
                                        <div className="app__context_housing_detail_button_down"><img src={ico_arrow} alt="arrow_up" /></div>
                                    }
                                </div>
                            </div>
                            <div className="app__sectiondata_widget_spacer_10"></div>
                        </>
                    }
                </>
            }
            {/* EDUCATION */}
            {((capacity?.context?.commodities?.education_baby?.items && capacity.context.commodities.education_baby.items.length > 0) || (capacity?.context?.commodities?.education_primary?.items?.maternel && capacity.context.commodities.education_primary.items.maternel.length > 0) || (capacity?.context?.commodities?.education_primary?.items?.elementary && capacity.context.commodities.education_primary.items.elementary.length > 0) || (capacity?.context?.commodities?.education_primary?.items?.primary && capacity.context.commodities.education_primary.items.primary.length > 0) || (capacity?.context?.commodities?.education_secondary?.items?.medschool && capacity.context.commodities.education_secondary.items.medschool.length > 0) || (capacity?.context?.commodities?.education_secondary?.items?.highschool && capacity.context.commodities.education_secondary.items.highschool.length > 0) || (capacity?.context?.commodities?.education_university?.items && capacity.context.commodities.education_university.items.length > 0)) &&
                <>
                    <div className="app__sectiondata_widget_spacer_20"></div>
                    <div id="section_3_amenities" className="app__sectiondata_widget_title">Commodités à proximité du terrain : Éducation</div>
                    {/* BABY */}
                    {(capacity?.context?.commodities?.education_baby?.items && capacity.context.commodities.education_baby.items.length > 0) &&
                        <>
                            <div id="commodities_baby" className="app__sectiondata__widget" style={{ paddingBottom: "8px" }}>
                                <div className="app__sectiondata_widget_title_container">
                                    <div className="app__sectiondata_widget_title">Crèches (dans un rayon de 1000m autour du terrain)</div>
                                    <div className="app__sectiondata_widget_layer">
                                        <img src={ico_map_layers} alt="ico_map_layers" />
                                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_context_commodities_baby" checked={mapLayers.context_commodities_baby.isVisible} onChange={() => { layer_Toggle("context_commodities_baby") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_context_commodities_baby">Toggle</label></div>
                                    </div>
                                </div>
                                <div className="app__context_iso_container_small">
                                    <img src={ico_baby} alt="iso_baby" />
                                    <div>
                                        <div className="app__context_iso_value_second_strong"><span className="app__context_iso_value_main_small">{capacity.context.commodities.education_baby.items.length || 0}</span> crèches</div>
                                        <div className="app__context_iso_value_second_small">crèches, micro-crèches ou maisons d'assitants maternels</div>
                                    </div>
                                </div>
                                <div className={`app__context_commodities_detail_container ${detailBaby ? "" : "app__context_commodities_detail_container_hidden"}`}>
                                    {/* DETAILS */}
                                    <div className="app__context_commodities_titles_container" style={{ marginTop: '14px' }}>
                                        <div className="app__context_commodities_title app__context_commodities_title_2">Nom</div>
                                        <div className="app__context_commodities_title app__context_commodities_title_2">Distance du terrain</div>
                                    </div>
                                    {capacity.context.commodities.education_baby.items.map((itm, itm_i) => {
                                        if (itm_i < max_detail) {
                                            return (
                                                <div className="app__context_commodities_values_container">
                                                    <div className="app__context_commodities_value app__context_commodities_title_2">{itm?.properties?.name}</div>
                                                    <div className="app__context_commodities_value app__context_commodities_title_2">{itm?.properties?.distance} m</div>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                                <div className="app__context_housing_detail_button_container" style={{ marginTop: "6px" }} onClick={() => { handleDetailBaby() }}>
                                    {detailBaby ?
                                        <div className="app__context_housing_detail_button_up"><img src={ico_arrow} alt="arrow_up" /></div>
                                        :
                                        <div className="app__context_housing_detail_button_down"><img src={ico_arrow} alt="arrow_up" /></div>
                                    }
                                </div>
                            </div>
                            <div className="app__sectiondata_widget_spacer_10"></div>
                        </>
                    }
                    {/* PRIMARY */}
                    {((capacity?.context?.commodities?.education_primary?.items?.maternel && capacity.context.commodities.education_primary.items.maternel.length > 0) || (capacity?.context?.commodities?.education_primary?.items?.elementary && capacity.context.commodities.education_primary.items.elementary.length > 0) || (capacity?.context?.commodities?.education_primary?.items?.primary && capacity.context.commodities.education_primary.items.primary.length > 0)) &&
                        <>
                            <div id="commodities_school" className="app__sectiondata__widget" style={{ paddingBottom: "8px" }}>
                                <div className="app__sectiondata_widget_title_container">
                                    <div className="app__sectiondata_widget_title">Enseignement primaire (dans un rayon de 1000m autour du terrain)</div>
                                    <div className="app__sectiondata_widget_layer">
                                        <img src={ico_map_layers} alt="ico_map_layers" />
                                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_context_commodities_primary_school" checked={mapLayers.context_commodities_primary_school.isVisible} onChange={() => { layer_Toggle("context_commodities_primary_school") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_context_commodities_primary_school">Toggle</label></div>
                                    </div>
                                </div>
                                <div className="app__context_iso_container_small">
                                    <img src={ico_school_bag} alt="iso_school_bag" />
                                    <div>
                                        <div className="app__context_iso_value_second_strong"><span className="app__context_iso_value_main_small">{(capacity?.context?.commodities?.education_primary?.items?.maternel ? capacity.context.commodities.education_primary.items.maternel.length : 0) + (capacity?.context?.commodities?.education_primary?.items?.elementary ? capacity.context.commodities.education_primary.items.elementary.length : 0) + (capacity?.context?.commodities?.education_primary?.items?.primary ? capacity.context.commodities.education_primary.items.primary.length : 0)}</span> écoles</div>
                                        <div className="app__context_iso_value_second_small">écoles maternelles, élémentaires ou primaires</div>
                                    </div>
                                </div>
                                <div className={`app__context_commodities_detail_container ${detailSchool ? "" : "app__context_commodities_detail_container_hidden"}`}>
                                    {Object.keys(capacity.context.commodities.education_primary.items).map(category => {
                                        if (capacity.context.commodities.education_primary.items[category].length > 0) {
                                            var txt_single = "1 Étabilssement";
                                            var txt_multiple = " Étabilssements";
                                            if (category === "kindergarten") { txt_single = "1 Crèche"; txt_multiple = " Crèches"; }
                                            if (category === "maternel") { txt_single = "1 École maternelle"; txt_multiple = " Écoles maternelles"; }
                                            if (category === "elementary") { txt_single = "1 École élémentaire"; txt_multiple = " Écoles élémentaires"; }
                                            if (category === "primary") { txt_single = "1 École primaire"; txt_multiple = " Écoles primaires"; }
                                            if (category === "medschool") { txt_single = "1 Collège"; txt_multiple = " Collèges"; }
                                            if (category === "highschool") { txt_single = "1 Lycée"; txt_multiple = " Lycées"; }
                                            if (category === "university") { txt_single = "1 Établissement d'enseignement suppérieur"; txt_multiple = " Établissements d'enseignement suppérieur"; }
                                            return (
                                                <>
                                                    <div className="app__context_commodities_label">{capacity.context.commodities.education_primary.items[category].length > 1 ? capacity.context.commodities.education_primary.items[category].length + txt_multiple : txt_single}</div>
                                                    <div className="app__context_commodities_titles_container">
                                                        <div className="app__context_commodities_title app__context_commodities_title_3">Nom</div>
                                                        <div className="app__context_commodities_title app__context_commodities_title_3">Public / Privé</div>
                                                        <div className="app__context_commodities_title app__context_commodities_title_3">Distance du terrain</div>
                                                    </div>
                                                    {capacity.context.commodities.education_primary.items[category].map((itm, itm_i) => {
                                                        if (itm_i < max_detail) {
                                                            return (
                                                                <div className="app__context_commodities_values_container">
                                                                    <div className="app__context_commodities_value app__context_commodities_title_3">{itm?.properties?.name}</div>
                                                                    <div className="app__context_commodities_value app__context_commodities_title_3">{itm?.properties?.operator || ""}</div>
                                                                    <div className="app__context_commodities_value app__context_commodities_title_3">{itm?.properties?.distance} m</div>
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                </>
                                            )
                                        }
                                    })}
                                </div>
                                <div className="app__context_housing_detail_button_container" style={{ marginTop: "6px" }} onClick={() => { handleDetailSchool() }}>
                                    {detailSchool ?
                                        <div className="app__context_housing_detail_button_up"><img src={ico_arrow} alt="arrow_up" /></div>
                                        :
                                        <div className="app__context_housing_detail_button_down"><img src={ico_arrow} alt="arrow_up" /></div>
                                    }
                                </div>
                            </div>
                            <div className="app__sectiondata_widget_spacer_10"></div>
                        </>
                    }
                    {/* SECONDARY */}
                    {((capacity?.context?.commodities?.education_secondary?.items?.medschool && capacity.context.commodities.education_secondary.items.medschool.length > 0) || (capacity?.context?.commodities?.education_secondary?.items?.highschool && capacity.context.commodities.education_secondary.items.highschool.length > 0)) &&
                        <>
                            <div id="commodities_highschool" className="app__sectiondata__widget" style={{ paddingBottom: "8px" }}>
                                <div className="app__sectiondata_widget_title_container">
                                    <div className="app__sectiondata_widget_title">Enseignement secondaire (dans un rayon de 1000m autour du terrain)</div>
                                    <div className="app__sectiondata_widget_layer">
                                        <img src={ico_map_layers} alt="ico_map_layers" />
                                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_context_commodities_secondary_school" checked={mapLayers.context_commodities_secondary_school.isVisible} onChange={() => { layer_Toggle("context_commodities_secondary_school") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_context_commodities_secondary_school">Toggle</label></div>
                                    </div>
                                </div>
                                <div className="app__context_iso_container_small">
                                    <img src={ico_highschool} alt="iso_highschool" />
                                    <div>
                                        <div className="app__context_iso_value_second_strong"><span className="app__context_iso_value_main_small">{(capacity?.context?.commodities?.education_secondary?.items?.medschool ? capacity.context.commodities.education_secondary.items.medschool.length : 0) + (capacity?.context?.commodities?.education_secondary?.items?.highschool ? capacity.context.commodities.education_secondary.items.highschool.length : 0)}</span> établissements secondaires</div>
                                        <div className="app__context_iso_value_second_small">collèges ou lycées</div>
                                    </div>
                                </div>
                                <div className={`app__context_commodities_detail_container ${detailHighSchool ? "" : "app__context_commodities_detail_container_hidden"}`}>
                                    {Object.keys(capacity.context.commodities.education_secondary.items).map(category => {
                                        if (capacity.context.commodities.education_secondary.items[category].length > 0) {
                                            var txt_single = "1 Étabilssement";
                                            var txt_multiple = " Étabilssements";
                                            if (category === "kindergarten") { txt_single = "1 Crèche"; txt_multiple = " Crèches"; }
                                            if (category === "maternel") { txt_single = "1 École maternelle"; txt_multiple = " Écoles maternelles"; }
                                            if (category === "elementary") { txt_single = "1 École élémentaire"; txt_multiple = " Écoles élémentaires"; }
                                            if (category === "primary") { txt_single = "1 École primaire"; txt_multiple = " Écoles primaires"; }
                                            if (category === "medschool") { txt_single = "1 Collège"; txt_multiple = " Collèges"; }
                                            if (category === "highschool") { txt_single = "1 Lycée"; txt_multiple = " Lycées"; }
                                            if (category === "university") { txt_single = "1 Établissement d'enseignement suppérieur"; txt_multiple = " Établissements d'enseignement suppérieur"; }
                                            return (
                                                <>
                                                    <div className="app__context_commodities_label">{capacity.context.commodities.education_secondary.items[category].length > 1 ? capacity.context.commodities.education_secondary.items[category].length + txt_multiple : txt_single}</div>
                                                    <div className="app__context_commodities_titles_container">
                                                        <div className="app__context_commodities_title app__context_commodities_title_3">Nom</div>
                                                        <div className="app__context_commodities_title app__context_commodities_title_3">Public / Privé</div>
                                                        <div className="app__context_commodities_title app__context_commodities_title_3">Distance du terrain</div>
                                                    </div>
                                                    {capacity.context.commodities.education_secondary.items[category].map((itm, itm_i) => {
                                                        if (itm_i < max_detail) {
                                                            return (
                                                                <div className="app__context_commodities_values_container">
                                                                    <div className="app__context_commodities_value app__context_commodities_title_3">{itm?.properties?.name}</div>
                                                                    <div className="app__context_commodities_value app__context_commodities_title_3">{itm?.properties?.operator || ""}</div>
                                                                    <div className="app__context_commodities_value app__context_commodities_title_3">{itm?.properties?.distance} m</div>
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                </>
                                            )
                                        }
                                    })}
                                </div>
                                <div className="app__context_housing_detail_button_container" style={{ marginTop: "6px" }} onClick={() => { handleDetailHighSchool() }}>
                                    {detailHighSchool ?
                                        <div className="app__context_housing_detail_button_up"><img src={ico_arrow} alt="arrow_up" /></div>
                                        :
                                        <div className="app__context_housing_detail_button_down"><img src={ico_arrow} alt="arrow_up" /></div>
                                    }
                                </div>
                            </div>
                            <div className="app__sectiondata_widget_spacer_10"></div>
                        </>
                    }
                    {/* UNIVERSITY */}
                    {(capacity?.context?.commodities?.education_university?.items && capacity.context.commodities.education_university.items.length > 0) &&
                        <>
                            <div id="commodities_university" className="app__sectiondata__widget" style={{ paddingBottom: "8px" }}>
                                <div className="app__sectiondata_widget_title_container">
                                    <div className="app__sectiondata_widget_title">Enseignement suppérieur (dans un rayon de 1000m autour du terrain)</div>
                                    <div className="app__sectiondata_widget_layer">
                                        <img src={ico_map_layers} alt="ico_map_layers" />
                                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_context_commodities_university" checked={mapLayers.context_commodities_university.isVisible} onChange={() => { layer_Toggle("context_commodities_university") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_context_commodities_university">Toggle</label></div>
                                    </div>
                                </div>
                                <div className="app__context_iso_container_small">
                                    <img src={ico_school} alt="iso_school" />
                                    <div>
                                        <div className="app__context_iso_value_second_strong"><span className="app__context_iso_value_main_small">{capacity.context.commodities.education_university.items.length || 0}</span> établissements suppérieurs</div>
                                        <div className="app__context_iso_value_second_small">universités, établissements d'enseignement suppérieur</div>
                                    </div>
                                </div>
                                <div className={`app__context_commodities_detail_container ${detailUniversity ? "" : "app__context_commodities_detail_container_hidden"}`}>
                                    {/* DETAILS */}
                                    <div className="app__context_commodities_titles_container" style={{ marginTop: '14px' }}>
                                        <div className="app__context_commodities_title app__context_commodities_title_2">Nom</div>
                                        <div className="app__context_commodities_title app__context_commodities_title_2">Distance du terrain</div>
                                    </div>
                                    {capacity.context.commodities.education_university.items.map((itm, itm_i) => {
                                        if (itm_i < max_detail) {
                                            return (
                                                <div className="app__context_commodities_values_container">
                                                    <div className="app__context_commodities_value app__context_commodities_title_2">{itm?.properties?.name}</div>
                                                    <div className="app__context_commodities_value app__context_commodities_title_2">{itm?.properties?.distance} m</div>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                                <div className="app__context_housing_detail_button_container" style={{ marginTop: "6px" }} onClick={() => { handleDetailUniversity() }}>
                                    {detailUniversity ?
                                        <div className="app__context_housing_detail_button_up"><img src={ico_arrow} alt="arrow_up" /></div>
                                        :
                                        <div className="app__context_housing_detail_button_down"><img src={ico_arrow} alt="arrow_up" /></div>
                                    }
                                </div>
                            </div>
                            <div className="app__sectiondata_widget_spacer_10"></div>
                        </>
                    }
                </>
            }
            {/* SHOP */}
            {((capacity?.context?.commodities?.shop?.items?.mall && capacity.context.commodities.shop.items.mall.length > 0) || (capacity?.context?.commodities?.shop?.items?.supermarket && capacity.context.commodities.shop.items.supermarket.length > 0)) &&
                <>
                    <div className="app__sectiondata_widget_spacer_20"></div>
                    <div id="section_3_amenities" className="app__sectiondata_widget_title">Commodités à proximité du terrain : Commerces</div>
                    {/* MALL */}
                    {(capacity?.context?.commodities?.shop?.items?.mall && capacity.context.commodities.shop.items.mall.length > 0) &&
                        <>
                            <div id="commodities_mall" className="app__sectiondata__widget" style={{ paddingBottom: "8px" }}>
                                <div className="app__sectiondata_widget_title_container">
                                    <div className="app__sectiondata_widget_title">Centres commerciaux (dans un rayon de 1000m autour du terrain)</div>
                                    <div className="app__sectiondata_widget_layer">
                                        <img src={ico_map_layers} alt="ico_map_layers" />
                                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_context_commodities_mall" checked={mapLayers.context_commodities_mall.isVisible} onChange={() => { layer_Toggle("context_commodities_mall") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_context_commodities_mall">Toggle</label></div>
                                    </div>
                                </div>
                                <div className="app__context_iso_container_small">
                                    <img src={ico_mall} alt="iso_mall" />
                                    <div>
                                        <div className="app__context_iso_value_second_strong"><span className="app__context_iso_value_main_small">{(capacity?.context?.commodities?.shop?.items?.mall ? capacity.context.commodities.shop.items.mall.length : 0)}</span> centres commerciaux</div>
                                        <div className="app__context_iso_value_second_small">centres commerciaux ou zones commerciales</div>
                                    </div>
                                </div>
                                <div className={`app__context_commodities_detail_container ${detailMall ? "" : "app__context_commodities_detail_container_hidden"}`}>
                                    {/* DETAILS */}
                                    <div className="app__context_commodities_titles_container" style={{ marginTop: '14px' }}>
                                        <div className="app__context_commodities_title app__context_commodities_title_2">Nom</div>
                                        <div className="app__context_commodities_title app__context_commodities_title_2">Distance du terrain</div>
                                    </div>
                                    {capacity.context.commodities.shop.items.mall.map((itm, itm_i) => {
                                        if (itm_i < max_detail) {
                                            return (
                                                <div className="app__context_commodities_values_container">
                                                    <div className="app__context_commodities_value app__context_commodities_title_2">{itm?.properties?.name}</div>
                                                    <div className="app__context_commodities_value app__context_commodities_title_2">{itm?.properties?.distance} m</div>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                                <div className="app__context_housing_detail_button_container" style={{ marginTop: "6px" }} onClick={() => { handleDetailMall() }}>
                                    {detailMall ?
                                        <div className="app__context_housing_detail_button_up"><img src={ico_arrow} alt="arrow_up" /></div>
                                        :
                                        <div className="app__context_housing_detail_button_down"><img src={ico_arrow} alt="arrow_up" /></div>
                                    }
                                </div>
                            </div>
                            <div className="app__sectiondata_widget_spacer_10"></div>
                        </>
                    }
                    {/* SUPERMARKET */}
                    {(capacity?.context?.commodities?.shop?.items?.supermarket && capacity.context.commodities.shop.items.supermarket.length > 0) &&
                        <>
                            <div id="commodities_shop" className="app__sectiondata__widget" style={{ paddingBottom: "8px" }}>
                                <div className="app__sectiondata_widget_title_container">
                                    <div className="app__sectiondata_widget_title">Supermarchés (dans un rayon de 1000m autour du terrain)</div>
                                    <div className="app__sectiondata_widget_layer">
                                        <img src={ico_map_layers} alt="ico_map_layers" />
                                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_context_commodities_supermarket" checked={mapLayers.context_commodities_supermarket.isVisible} onChange={() => { layer_Toggle("context_commodities_supermarket") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_context_commodities_supermarket">Toggle</label></div>
                                    </div>
                                </div>
                                <div className="app__context_iso_container_small">
                                    <img src={ico_grocery} alt="iso_grocery" />
                                    <div>
                                        <div className="app__context_iso_value_second_strong"><span className="app__context_iso_value_main_small">{(capacity?.context?.commodities?.shop?.items?.supermarket ? capacity.context.commodities.shop.items.supermarket.length : 0)}</span> supermarchés</div>
                                        <div className="app__context_iso_value_second_small">supermarchés ou commerces de proximité</div>
                                    </div>
                                </div>
                                <div className={`app__context_commodities_detail_container ${detailShop ? "" : "app__context_commodities_detail_container_hidden"}`}>
                                    {/* DETAILS */}
                                    <div className="app__context_commodities_titles_container" style={{ marginTop: '14px' }}>
                                        <div className="app__context_commodities_title app__context_commodities_title_2">Nom</div>
                                        <div className="app__context_commodities_title app__context_commodities_title_2">Distance du terrain</div>
                                    </div>
                                    {capacity.context.commodities.shop.items.supermarket.map((itm, itm_i) => {
                                        if (itm_i < max_detail) {
                                            return (
                                                <div className="app__context_commodities_values_container">
                                                    <div className="app__context_commodities_value app__context_commodities_title_2">{itm?.properties?.name}</div>
                                                    <div className="app__context_commodities_value app__context_commodities_title_2">{itm?.properties?.distance} m</div>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                                <div className="app__context_housing_detail_button_container" style={{ marginTop: "6px" }} onClick={() => { handleDetailShop() }}>
                                    {detailShop ?
                                        <div className="app__context_housing_detail_button_up"><img src={ico_arrow} alt="arrow_up" /></div>
                                        :
                                        <div className="app__context_housing_detail_button_down"><img src={ico_arrow} alt="arrow_up" /></div>
                                    }
                                </div>
                            </div>
                            <div className="app__sectiondata_widget_spacer_10"></div>
                        </>
                    }
                </>
            }
            {/* LEISURES */}
            {((capacity?.context?.commodities?.park_garden?.items && capacity.context.commodities.park_garden.items.length > 0) || (capacity?.context?.commodities?.leisure_food?.items?.restaurant && capacity.context.commodities.leisure_food.items.restaurant.length > 0) || (capacity?.context?.commodities?.leisure_food?.items?.bar_cafe && capacity.context.commodities.leisure_food.items.bar_cafe.length > 0) || (capacity?.context?.commodities?.leisure_food?.items?.marketplace && capacity.context.commodities.leisure_food.items.marketplace.length > 0) || (capacity?.context?.commodities?.leisure_culture?.items?.arts_centre && capacity.context.commodities.leisure_culture.items.arts_centre.length > 0) || (capacity?.context?.commodities?.leisure_culture?.items?.cinema && capacity.context.commodities.leisure_culture.items.cinema.length > 0) || (capacity?.context?.commodities?.leisure_culture?.items?.conference_exhibition_centre && capacity.context.commodities.leisure_culture.items.conference_exhibition_centre.length > 0) || (capacity?.context?.commodities?.leisure_culture?.items?.library && capacity.context.commodities.leisure_culture.items.library.length > 0) || (capacity?.context?.commodities?.leisure_culture?.items?.music_venue && capacity.context.commodities.leisure_culture.items.music_venue.length > 0) || (capacity?.context?.commodities?.leisure_culture?.items?.theatre && capacity.context.commodities.leisure_culture.items.theatre.length > 0)) &&
                <>
                    <div className="app__sectiondata_widget_spacer_20"></div>
                    <div id="section_3_amenities" className="app__sectiondata_widget_title">Commodités à proximité du terrain : Loisirs</div>
                    {/* PARC GARDENS */}
                    {(capacity?.context?.commodities?.park_garden?.items && capacity.context.commodities.park_garden.items.length > 0) &&
                        <>
                            <div id="commodities_parc_garden" className="app__sectiondata__widget" style={{ paddingBottom: "8px" }}>
                                <div className="app__sectiondata_widget_title_container">
                                    <div className="app__sectiondata_widget_title">Parcs et jardins publics (dans un rayon de 500m autour du terrain)</div>
                                    <div className="app__sectiondata_widget_layer">
                                        <img src={ico_map_layers} alt="ico_map_layers" />
                                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_context_commodities_parc" checked={mapLayers.context_commodities_parc.isVisible} onChange={() => { layer_Toggle("context_commodities_parc") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_context_commodities_parc">Toggle</label></div>
                                    </div>
                                </div>
                                <div className="app__context_iso_container_small">
                                    <img src={ico_parc_garden} alt="iso_parcgarden" />
                                    <div>
                                        <div className="app__context_iso_value_second_strong"><span className="app__context_iso_value_main_small">{(capacity?.context?.commodities?.park_garden?.items ? capacity.context.commodities.park_garden.items.length : 0)}</span> parcs</div>
                                        <div className="app__context_iso_value_second_small">parcs, bois, jardins ou espaces publics</div>
                                    </div>
                                </div>
                                <div className={`app__context_commodities_detail_container ${detailParcGarden ? "" : "app__context_commodities_detail_container_hidden"}`}>
                                    {/* DETAILS */}
                                    <div className="app__context_commodities_titles_container" style={{ marginTop: '14px' }}>
                                        <div className="app__context_commodities_title app__context_commodities_title_3">Nom</div>
                                        <div className="app__context_commodities_title app__context_commodities_title_3">Superficie</div>
                                        <div className="app__context_commodities_title app__context_commodities_title_3">Distance du terrain</div>
                                    </div>
                                    {capacity.context.commodities.park_garden.items.map((itm, itm_i) => {
                                        if (itm_i < max_detail) {
                                            return (
                                                <div className="app__context_commodities_values_container">
                                                    <div className="app__context_commodities_value app__context_commodities_title_3">{itm?.properties?.name}</div>
                                                    <div className="app__context_commodities_value app__context_commodities_title_3">{itm?.properties?.area} m²</div>
                                                    <div className="app__context_commodities_value app__context_commodities_title_3">{itm?.properties?.distance} m</div>
                                                </div>
                                            )
                                        }
                                    })}

                                </div>
                                <div className="app__context_housing_detail_button_container" style={{ marginTop: "6px" }} onClick={() => { handleDetailParcGarden() }}>
                                    {detailParcGarden ?
                                        <div className="app__context_housing_detail_button_up"><img src={ico_arrow} alt="arrow_up" /></div>
                                        :
                                        <div className="app__context_housing_detail_button_down"><img src={ico_arrow} alt="arrow_up" /></div>
                                    }
                                </div>
                            </div>
                            <div className="app__sectiondata_widget_spacer_10"></div>
                        </>
                    }
                    {/* FOOD */}
                    {((capacity?.context?.commodities?.leisure_food?.items?.restaurant && capacity.context.commodities.leisure_food.items.restaurant.length > 0) || (capacity?.context?.commodities?.leisure_food?.items?.bar_cafe && capacity.context.commodities.leisure_food.items.bar_cafe.length > 0) || (capacity?.context?.commodities?.leisure_food?.items?.marketplace && capacity.context.commodities.leisure_food.items.marketplace.length > 0)) &&
                        <>
                            <div id="commodities_restaurant" className="app__sectiondata__widget" style={{ paddingBottom: "8px" }}>
                                <div className="app__sectiondata_widget_title_container">
                                    <div className="app__sectiondata_widget_title">Nourriture et boissons (dans un rayon de 500m autour du terrain)</div>
                                    <div className="app__sectiondata_widget_layer">
                                        <img src={ico_map_layers} alt="ico_map_layers" />
                                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_context_commodities_restaurant" checked={mapLayers.context_commodities_restaurant.isVisible} onChange={() => { layer_Toggle("context_commodities_restaurant") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_context_commodities_restaurant">Toggle</label></div>
                                    </div>
                                </div>
                                <div className="app__context_iso_container_small">
                                    <img src={ico_restaurant} alt="iso_restaurant" />
                                    <div>
                                        <div className="app__context_iso_value_second_strong"><span className="app__context_iso_value_main_small">{(capacity?.context?.commodities?.leisure_food?.items?.restaurant ? capacity.context.commodities.leisure_food.items.restaurant.length : 0) + (capacity?.context?.commodities?.leisure_food?.items?.bar_cafe ? capacity.context.commodities.leisure_food.items.bar_cafe.length : 0) + (capacity?.context?.commodities?.leisure_food?.items?.marketplace ? capacity.context.commodities.leisure_food.items.marketplace.length : 0)}</span> restaurants, bars ou cafés</div>
                                        <div className="app__context_iso_value_second_small">restaurants, bars, cafés ou marchés</div>
                                    </div>
                                </div>
                                <div className={`app__context_commodities_detail_container ${detailRestaurant ? "" : "app__context_commodities_detail_container_hidden"}`}>
                                    {Object.keys(capacity.context.commodities.leisure_food.items).map(category => {
                                        if (capacity.context.commodities.leisure_food.items[category].length > 0) {
                                            var txt_single = "1 Étabilssement";
                                            var txt_multiple = " Étabilssements";
                                            if (category === "restaurant") { txt_single = "1 Restaurant"; txt_multiple = " Restaurants"; }
                                            if (category === "bar_cafe") { txt_single = "1 Bar ou Café"; txt_multiple = " Bars ou Cafés"; }
                                            if (category === "marketplace") { txt_single = "1 Marché"; txt_multiple = " Marchés"; }
                                            return (
                                                <>
                                                    <div className="app__context_commodities_label">{capacity.context.commodities.leisure_food.items[category].length > 1 ? capacity.context.commodities.leisure_food.items[category].length + txt_multiple : txt_single}</div>
                                                    <div className="app__context_commodities_titles_container">
                                                        <div className="app__context_commodities_title app__context_commodities_title_2">Nom</div>
                                                        <div className="app__context_commodities_title app__context_commodities_title_2">Distance du terrain</div>
                                                    </div>
                                                    {capacity.context.commodities.leisure_food.items[category].map((itm, itm_i) => {
                                                        if (itm_i < max_detail) {
                                                            return (
                                                                <div className="app__context_commodities_values_container">
                                                                    <div className="app__context_commodities_value app__context_commodities_title_2">{itm?.properties?.name}</div>
                                                                    <div className="app__context_commodities_value app__context_commodities_title_2">{itm?.properties?.distance} m</div>
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                </>
                                            )
                                        }
                                    })}
                                </div>
                                <div className="app__context_housing_detail_button_container" style={{ marginTop: "6px" }} onClick={() => { handleDetailRestaurant() }}>
                                    {detailRestaurant ?
                                        <div className="app__context_housing_detail_button_up"><img src={ico_arrow} alt="arrow_up" /></div>
                                        :
                                        <div className="app__context_housing_detail_button_down"><img src={ico_arrow} alt="arrow_up" /></div>
                                    }
                                </div>
                            </div>
                            <div className="app__sectiondata_widget_spacer_10"></div>
                        </>
                    }
                    {/* CULTURE */}
                    {((capacity?.context?.commodities?.leisure_culture?.items?.arts_centre && capacity.context.commodities.leisure_culture.items.arts_centre.length > 0) || (capacity?.context?.commodities?.leisure_culture?.items?.cinema && capacity.context.commodities.leisure_culture.items.cinema.length > 0) || (capacity?.context?.commodities?.leisure_culture?.items?.conference_exhibition_centre && capacity.context.commodities.leisure_culture.items.conference_exhibition_centre.length > 0) || (capacity?.context?.commodities?.leisure_culture?.items?.library && capacity.context.commodities.leisure_culture.items.library.length > 0) || (capacity?.context?.commodities?.leisure_culture?.items?.music_venue && capacity.context.commodities.leisure_culture.items.music_venue.length > 0) || (capacity?.context?.commodities?.leisure_culture?.items?.theatre && capacity.context.commodities.leisure_culture.items.theatre.length > 0)) &&
                        <>
                            <div id="commodities_culture" className="app__sectiondata__widget" style={{ paddingBottom: "8px" }}>
                                <div className="app__sectiondata_widget_title_container">
                                    <div className="app__sectiondata_widget_title">Culture (dans un rayon de 1000m autour du terrain)</div>
                                    <div className="app__sectiondata_widget_layer">
                                        <img src={ico_map_layers} alt="ico_map_layers" />
                                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_context_commodities_culture" checked={mapLayers.context_commodities_culture.isVisible} onChange={() => { layer_Toggle("context_commodities_culture") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_context_commodities_culture">Toggle</label></div>
                                    </div>
                                </div>
                                <div className="app__context_iso_container_small">
                                    <img src={ico_culture} alt="iso_culture" />
                                    <div>
                                        <div className="app__context_iso_value_second_strong"><span className="app__context_iso_value_main_small">{(capacity?.context?.commodities?.leisure_culture?.items?.arts_centre ? capacity.context.commodities.leisure_culture.items.arts_centre.length : 0) + (capacity?.context?.commodities?.leisure_culture?.items?.cinema ? capacity.context.commodities.leisure_culture.items.cinema.length : 0) + (capacity?.context?.commodities?.leisure_culture?.items?.conference_exhibition_centre ? capacity.context.commodities.leisure_culture.items.conference_exhibition_centre.length : 0) + (capacity?.context?.commodities?.leisure_culture?.items?.library ? capacity.context.commodities.leisure_culture.items.library.length : 0) + (capacity?.context?.commodities?.leisure_culture?.items?.music_venue ? capacity.context.commodities.leisure_culture.items.music_venue.length : 0) + (capacity?.context?.commodities?.leisure_culture?.items?.theatre ? capacity.context.commodities.leisure_culture.items.theatre.length : 0)}</span> établissements culturels</div>
                                        <div className="app__context_iso_value_second_small">centres culturels, cinémas, bibliothèques, théatres, salles de concert ou galleries d'art</div>
                                    </div>
                                </div>
                                <div className={`app__context_commodities_detail_container ${detailCulture ? "" : "app__context_commodities_detail_container_hidden"}`}>
                                    {Object.keys(capacity.context.commodities.leisure_culture.items).map(category => {
                                        if (capacity.context.commodities.leisure_culture.items[category].length > 0) {
                                            var txt_single = "1 Étabilssement";
                                            var txt_multiple = " Étabilssements";
                                            if (category === "arts_centre") { txt_single = "1 Gallerie d'art ou librairie"; txt_multiple = " Galleries d'art ou librairies"; }
                                            if (category === "cinema") { txt_single = "1 Cinéma"; txt_multiple = " Cinémas"; }
                                            if (category === "conference_exhibition_centre") { txt_single = "1 Centre d'exposition"; txt_multiple = " Centres d'exposition"; }
                                            if (category === "library") { txt_single = "1 Bibliothèque"; txt_multiple = " Bibliothèques"; }
                                            if (category === "music_venue") { txt_single = "1 Salle de concert"; txt_multiple = " Salles de concert"; }
                                            if (category === "theatre") { txt_single = "1 Théatre"; txt_multiple = " Théatres"; }
                                            return (
                                                <>
                                                    <div className="app__context_commodities_label">{capacity.context.commodities.leisure_culture.items[category].length > 1 ? capacity.context.commodities.leisure_culture.items[category].length + txt_multiple : txt_single}</div>
                                                    <div className="app__context_commodities_titles_container">
                                                        <div className="app__context_commodities_title app__context_commodities_title_2">Nom</div>
                                                        <div className="app__context_commodities_title app__context_commodities_title_2">Distance du terrain</div>
                                                    </div>
                                                    {capacity.context.commodities.leisure_culture.items[category].map((itm, itm_i) => {
                                                        if (itm_i < max_detail) {
                                                            return (
                                                                <div className="app__context_commodities_values_container">
                                                                    <div className="app__context_commodities_value app__context_commodities_title_2">{itm?.properties?.name}</div>
                                                                    <div className="app__context_commodities_value app__context_commodities_title_2">{itm?.properties?.distance} m</div>
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                </>
                                            )
                                        }
                                    })}
                                </div>
                                <div className="app__context_housing_detail_button_container" style={{ marginTop: "6px" }} onClick={() => { handleDetailCulture() }}>
                                    {detailCulture ?
                                        <div className="app__context_housing_detail_button_up"><img src={ico_arrow} alt="arrow_up" /></div>
                                        :
                                        <div className="app__context_housing_detail_button_down"><img src={ico_arrow} alt="arrow_up" /></div>
                                    }
                                </div>
                            </div>
                            <div className="app__sectiondata_widget_spacer_10"></div>
                        </>
                    }
                </>
            }


            <div className="app__sectiondata_widget_info_container">
                <div className="app__sectiondata_widget_info_text">
                    Source : Open Street Map
                </div>
                <div className="app__sectiondata_widget_info_icon">i</div>
            </div>
        </>
    );
};

export default Commodities;