import * as map_helpers from '../components/app/map/Map_helpers';
import ColorThief from 'colorthief';

//__________ UI

export const get_pretty_num = (num, nb_decimals) => {

    if (num === null || num === undefined || isNaN(num)) {
        return "";
    }

    // Get string from num
    var num_string = num.toString();
    if (nb_decimals) {
        num_string = (num.toFixed(nb_decimals)).toString();
    }

    // Get . posiiton
    var separation = num_string.indexOf('.');

    // Get parts
    var num_string_first = num_string;
    var num_string_second = "";
    if (separation >= 0) {
        num_string_first = num_string.substring(0, separation);
        num_string_second = num_string.substring(separation + 1, num_string.length);
    }

    // Add space to first part
    var num_string_first_reverse = num_string_first.split("").reverse().join("");
    var num_string_first_reverse_spaced = num_string_first_reverse.match(/.{1,3}/g).join(" ");
    var num_string_first_spaced = num_string_first_reverse_spaced.split("").reverse().join("");

    // Create final
    var num_string_final = num_string_first_spaced;
    if (num_string_second !== "") {
        num_string_final = num_string_final + "," + num_string_second;
    }

    return num_string_final;
}

export const get_pretty_date_dd_mm_yyyy_from_yyy_mm_dd = (date_yyy_mm_dd) => {
    var elements = date_yyy_mm_dd.split("-");
    elements.reverse();
    return elements.join("/")
}


//__________ COMBINATIONS

export const m_combinations = (permutation) => {
    var length = permutation.length,
        result = [permutation.slice()],
        c = new Array(length).fill(0),
        i = 1, k, p;

    while (i < length) {
        if (c[i] < i) {
            k = i % 2 && c[i];
            p = permutation[i];
            permutation[i] = permutation[k];
            permutation[k] = p;
            ++c[i];
            i = 1;
            result.push(permutation.slice());
        } else {
            c[i] = 0;
            ++i;
        }
    }
    return result;
}

export const l_combinations = (array) => {
    const res = [];
    let max = array.length - 1;
    const helper = (arr, i) => {
        for (let j = 0, l = array[i].length; j < l; j++) {
            let copy = arr.slice(0);
            copy.push(array[i][j]);
            if (i == max)
                res.push(copy);
            else
                helper(copy, i + 1);
        };
    };
    helper([], 0);
    return res;
};

export const k_combinations = (set, k) => {
    var i, j, combs, head, tailcombs;

    // There is no way to take e.g. sets of 5 elements from
    // a set of 4.
    if (k > set.length || k <= 0) {
        return [];
    }

    // K-sized set has only one K-sized subset.
    if (k === set.length) {
        return [set];
    }

    // There is N 1-sized subsets in a N-sized set.
    if (k === 1) {
        combs = [];
        for (i = 0; i < set.length; i++) {
            combs.push([set[i]]);
        }
        return combs;
    }

    // Assert {1 < k < set.length}

    // Algorithm description:
    // To get k-combinations of a set, we want to join each element
    // with all (k-1)-combinations of the other elements. The set of
    // these k-sized sets would be the desired result. However, as we
    // represent sets with lists, we need to take duplicates into
    // account. To avoid producing duplicates and also unnecessary
    // computing, we use the following approach: each element i
    // divides the list into three: the preceding elements, the
    // current element i, and the subsequent elements. For the first
    // element, the list of preceding elements is empty. For element i,
    // we compute the (k-1)-computations of the subsequent elements,
    // join each with the element i, and store the joined to the set of
    // computed k-combinations. We do not need to take the preceding
    // elements into account, because they have already been the i:th
    // element so they are already computed and stored. When the length
    // of the subsequent list drops below (k-1), we cannot find any
    // (k-1)-combs, hence the upper limit for the iteration:
    combs = [];
    for (i = 0; i < set.length - k + 1; i++) {
        // head is a list that includes only our current element.
        head = set.slice(i, i + 1);
        // We take smaller combinations from the subsequent elements
        tailcombs = k_combinations(set.slice(i + 1), k - 1);
        // For each (k-1)-combination we join it with the current
        // and store it to the set of k-combinations.
        for (j = 0; j < tailcombs.length; j++) {
            combs.push(head.concat(tailcombs[j]));
        }
    }
    return combs;
}


// SVG COORDINATES

export const get_svgCoords_from_boundsGroupsList = (bounds_groups, bbox) => {
    // console.log("SVG FROM BOUNDS GROUPS", bounds_groups, bbox);

    // Create local coords list
    var localCoords = [];
    bounds_groups.forEach(bounds_group => {
        if (bounds_group?.start_coord_local) {
            localCoords.push(bounds_group.start_coord_local);
        }
    })
    // console.log("localCoords", localCoords);

    var svgCoords = get_svgCoords_from_localCoords(localCoords, bbox);
    // console.log("svgCoords", svgCoords);

    return svgCoords
    // return [[0, 0], [100, 0], [100, 100], [0, 200]]

}

export const get_svgCoords_from_globalCoords = (globalCoords, bbox) => {
    // console.log("SVG FROM GLOBAL COORDS", globalCoords, bbox);

    var svgCoords = [];

    try {
        for (var i = 0; i < globalCoords.length; i++) {

            var globalCoords_i = globalCoords[i];
            // First is the outerline
            if (i > 0) {
                globalCoords_i = globalCoords[i].reverse();
            }

            // Get local coords
            var localCoords = [];
            globalCoords_i.forEach(globalCoord => {
                localCoords.push(map_helpers.mercator_to_local(globalCoord, bbox.origin));
            })
            // console.log(localCoords);

            // Get svg coords
            svgCoords = svgCoords.concat(get_svgCoords_from_localCoords(localCoords, bbox));
            // console.log(svgCoords);
        }
        return svgCoords

    } catch (error) {
        console.log("ERROR CREATING SVG", error);
        return svgCoords
    }

    // return [[0, 0], [100, 0], [100, 100], [0, 200]]

}

export const get_svgCoords_from_localCoords = (localCoords, bbox) => {
    // console.log("SVG FROM LOCAL COORDS", localCoords, bbox);


    // Get svg coords
    var svgWidth = 800;
    var svgCoords = [];

    localCoords.forEach(localCoord => {
        if (bbox.lengthH >= bbox.lengthV) {
            var scaleFactor = svgWidth / bbox.lengthH;
            var verticalOffset = (svgWidth - (bbox.lengthV * scaleFactor)) / 2;
            svgCoords.push([localCoord[0] * scaleFactor, svgWidth - (localCoord[1] * scaleFactor) - verticalOffset]);
        }
        else {
            var scaleFactor = svgWidth / bbox.lengthV;
            var horizontalOffset = (svgWidth - (bbox.lengthH * scaleFactor)) / 2;
            svgCoords.push([(localCoord[0] * scaleFactor) + horizontalOffset, svgWidth - (localCoord[1] * scaleFactor)]);
        }
    });

    return svgCoords
}

export const get_svgPath_from_svgCoords = (svgCoords, isHole) => {

    var svgPath = "";

    // Remove last item because it's the same as first one but in svg it auto close
    svgCoords.pop();

    // Reverse array if hole
    if (isHole === true) {
        svgCoords.reverse();
    }

    svgCoords.forEach((svgCoord, index) => {
        var type = " L";
        if (index === 0) { type = "M" }

        svgPath = svgPath + type + " " + svgCoord[0].toFixed(2) + " " + svgCoord[1].toFixed(2);

        if (index === svgCoords.length - 1) { svgPath = svgPath + " Z" }
    });

    return svgPath
}


// COMPRESS AND OPTIMIZE CAPA
export const compressCapa = (capa) => {

    // console.log("-SAVING CAPA- : Optimizing");

    // Create new capa optimized
    var capaOptim = JSON.parse(JSON.stringify(capa));
    // console.log(capaOptim);

    // Opitimizing specific parts

    // LANDBASE > UNION > BOUNDS GROUPS > CONTEXT ANALYSIS > LANDS & ROADS
    if (capaOptim?.landBase?.union?.bounds_groups) {
        for (var i = 0; i < capaOptim.landBase.union.bounds_groups.length; i++) {
            if (capaOptim.landBase.union.bounds_groups[i]?.context_analysis?.lands) {
                for (var j = 0; j < capaOptim.landBase.union.bounds_groups[i].context_analysis.lands.length; j++) {
                    // Reduce data
                    if (capaOptim?.landBase?.union?.bounds_groups[i]?.context_analysis?.lands[j]?.data?.properties?.section) {
                        capaOptim.landBase.union.bounds_groups[i].context_analysis.lands[j].data = { id: capaOptim.landBase.union.bounds_groups[i].context_analysis.lands[j].data.id, name: capaOptim.landBase.union.bounds_groups[i].context_analysis.lands[j].data.properties.section + "-" + capaOptim.landBase.union.bounds_groups[i].context_analysis.lands[j].data.properties.numero }
                    }
                    // Erase point and line and check_line
                    delete capaOptim.landBase.union.bounds_groups[i].context_analysis.lands[j].point;
                    delete capaOptim.landBase.union.bounds_groups[i].context_analysis.lands[j].line;
                    delete capaOptim.landBase.union.bounds_groups[i].context_analysis.lands[j].check_line;
                }
            }
            if (capaOptim.landBase.union.bounds_groups[i]?.context_analysis?.roads) {
                for (var j = 0; j < capaOptim.landBase.union.bounds_groups[i].context_analysis.roads.length; j++) {
                    // Reduce data
                    if (capaOptim.landBase.union.bounds_groups[i].context_analysis.roads[j]?.data?.properties?.name) {
                        capaOptim.landBase.union.bounds_groups[i].context_analysis.roads[j].data = { id: capaOptim.landBase.union.bounds_groups[i].context_analysis.roads[j].data.id, name: capaOptim.landBase.union.bounds_groups[i].context_analysis.roads[j]?.data?.properties?.name || "Route non nommée" }
                    }
                    // Erase point and line and check_line
                    delete capaOptim.landBase.union.bounds_groups[i].context_analysis.roads[j].point;
                    delete capaOptim.landBase.union.bounds_groups[i].context_analysis.roads[j].line;
                    delete capaOptim.landBase.union.bounds_groups[i].context_analysis.roads[j].check_line;
                }
            }
        }
    }

    // LANDBASE > TOPO > MATRIX
    // if (capaOptim?.landBase?.topo?.matrix && capaOptim?.landBase?.topo?.matrix.length > 0 && capaOptim.landBase.topo.matrix[0].coord_global) {
    //     for (var i = 0; i < capaOptim.landBase.topo.matrix.length; i++) {
    //         capaOptim.landBase.topo.matrix[i] = {
    //             cg: capaOptim.landBase.topo.matrix[i].coord_global,
    //             cl: capaOptim.landBase.topo.matrix[i].coord_local,
    //             z: capaOptim.landBase.topo.matrix[i].elevation,
    //         }
    //     }
    // }

    // LANDBASE > BUILDINGS
    if (capaOptim?.landBase?.buildings?.buildings && capaOptim?.landBase?.buildings?.buildings_land) {
        for (var i = 0; i < capaOptim.landBase.buildings.buildings.length; i++) {
            if (!capaOptim.landBase.buildings.buildings_land.includes(i)) {
                capaOptim.landBase.buildings.buildings[i].properties = {
                    id: capaOptim.landBase.buildings.buildings[i].properties.id,
                    area: capaOptim.landBase.buildings.buildings[i].properties.area,
                    height_max: capaOptim.landBase.buildings.buildings[i].properties.height_max,
                }
            }
        }
    }

    // CONTEXT > DVF > DVF_TRASACTIONS
    // if (capaOptim?.context?.dvf?.dvf_transactions && capaOptim?.context?.dvf?.dvf_transactions_land) {
    //     var dvf_transactions_optim = {};
    //     for (var i = 0; i < capaOptim.context.dvf.dvf_transactions_land.length; i++) {
    //         dvf_transactions_optim[capaOptim.context.dvf.dvf_transactions_land[i]] = capaOptim.context.dvf.dvf_transactions[capaOptim.context.dvf.dvf_transactions_land[i]];
    //     }
    //     capaOptim.context.dvf.dvf_transactions = dvf_transactions_optim;
    // }


    // RULES > GPU_DATA > DOCUMENT
    if (capaOptim?.rules?.gpu_data?.document) {
        var document_optim = {
            id: capaOptim.rules.gpu_data.document.id,
            title: capaOptim.rules.gpu_data.document.title,
            originalName: capaOptim.rules.gpu_data.document.originalName,
            archiveUrl: capaOptim.rules.gpu_data.document.archiveUrl,
        };
        capaOptim.rules.gpu_data.document = document_optim;
    }

    // RULES > GPU_DATA > FILES
    if (capaOptim?.rules?.gpu_data?.files) {
        for (var file_group of Object.keys(capaOptim.rules.gpu_data.files)) {
            for (var i = 0; i < capaOptim.rules.gpu_data.files[file_group].length; i++) {
                delete capaOptim.rules.gpu_data.files[file_group][i].label;
                delete capaOptim.rules.gpu_data.files[file_group][i].name;
                delete capaOptim.rules.gpu_data.files[file_group][i].path;
            }
        }
    }

    // RULES > RULESET > ITEM > INTERPRETED
    var rules = { ...capaOptim.rules };
    rules.ruleset.forEach(ruleset => {
        ruleset.items.forEach(item => {
            if (item.type === "rule") {
                item.interpreted = ["removed"]
            }
        })
    })
    capaOptim.rules = rules;

    // BUILDABLE > VOLUME
    capaOptim.buildable.volume.interpreted = ["removed"];

    // BUILDABLE > COMBINATIONS
    capaOptim.buildable.combinations = ["removed"];

    // Optimisation results
    var size_origin = JSON.stringify(capa).length;
    var size_optim = JSON.stringify(capaOptim).length;
    // console.log("FULL ORIGINAL size :", size_origin, " (" + (size_origin / 1024 / 1024).toFixed(1) + " Mb)");
    // console.log("FULL OPTIMIZED size :", size_optim, " (" + (size_optim / 1024 / 1024).toFixed(1) + " Mb)");
    // console.log("Optimization :", size_origin - size_optim, " (-" + (100 * (size_origin - size_optim) / size_origin).toFixed(2) + "%)");
    console.log("-SAVING CAPA- : Optimized");
    console.table({
        "original": size_origin + " (" + (size_origin / 1024 / 1024).toFixed(1) + " Mb)",
        "optimized": size_optim + " (" + (size_optim / 1024 / 1024).toFixed(1) + " Mb)",
        "result": (size_origin - size_optim) + " (" + (100 * (size_origin - size_optim) / size_origin).toFixed(2) + "%)",
    })


    // KEEP ONLY UPDATED PARTS
    if (capaOptim?.toSave?.keys && capaOptim?.toSave?.keys.length > 0 && !capaOptim?.toSave?.keys.includes("all")) {
        var capaOptimPartial = {
            _id: capaOptim._id,
            __v: capaOptim.__v,
            toSave: capaOptim.toSave,
            modifications: capaOptim.modifications,
            simplified: capaOptim?.simplified || {},
        }
        var keysSetted = [...new Set(capaOptim.toSave.keys)];
        for (var i = 0; i < keysSetted.length; i++) {
            capaOptimPartial[keysSetted[i]] = capaOptim[keysSetted[i]]
        }
        // console.log("-SAVING CAPA- : Partialized", capaOptim?.toSave?.keys, capaOptimPartial);

        return capaOptimPartial
    }


    return capaOptim
}


// _________ COLORS

// export const getColorMain2 = async (imgData) => {
//     const fac = new FastAverageColor();
//     const res = await fac.getColorAsync(imgData);
//     return res?.hex || "#062134"
// }
export const getColorMain = async (imgData) => {
    return new Promise((resolve, reject) => {
        var image = new Image();
        image.onload = () => resolve(extractColor(image))
        image.onerror = reject;
        image.src = imgData;
    })
}
function extractColor(image) {
    const colorThief = new ColorThief();
    try {
        var rgb = colorThief.getColor(image);
        return rgbToHex(rgb[0], rgb[1], rgb[2])
    } catch (error) {
        console.log(error);
        return "#062134";
    }
}
const rgbToHex = (r, g, b) => '#' + [r, g, b].map(x => {
    const hex = x.toString(16)
    return hex.length === 1 ? '0' + hex : hex
}).join('')
export function getColorContrast(color) {
    return (luma(color) >= 165) ? '#000' : '#FFF';
}
function luma(color) // color can be a hx string or an array of RGB values 0-255
{
    var rgb = (typeof color === 'string') ? hexToRGBArray(color) : color;
    return (0.2126 * rgb[0]) + (0.7152 * rgb[1]) + (0.0722 * rgb[2]); // SMPTE C, Rec. 709 weightings
}
function hexToRGBArray(color) {
    if (color.length === 3)
        color = color.charAt(0) + color.charAt(0) + color.charAt(1) + color.charAt(1) + color.charAt(2) + color.charAt(2);
    else if (color.length === 7) {
        color = color.substring(1);
    }
    else if (color.length !== 6)
        throw ('Invalid hex color: ' + color);
    var rgb = [];
    for (var i = 0; i <= 2; i++)
        rgb[i] = parseInt(color.substr(i * 2, 2), 16);
    return rgb;
}


// UPDATE MODEL

export const update_capa_model = (capacity) => {
    var updatedKeys = [];

    // __________ UPDATES
    // TEST
    if (capacity?.buildable?.volume?.parameters && !capacity?.buildable?.volume?.parameters?.test_04) {
        capacity.buildable.volume.parameters.test_04 = "OK";
        updatedKeys.push("buildable");
    }
    // Coef Area to SHAB
    if (capacity?.buildable?.volume?.parameters && !capacity?.buildable?.volume?.parameters?.coef_area_to_shabsu) {
        capacity.buildable.volume.parameters.coef_area_to_shabsu = 0.7;
        updatedKeys.push("buildable");
    }
    // Remove landBase > buildings_osm
    // if (capacity?.landBase?.buildings_osm && capacity?.landBase?.buildings_osm) {
    //     capacity.buildable.volume.parameters.coef_area_to_shabsu = 0.7;
    //     updatedKeys.push("buildable");
    // }
    
    // __________ SAVE
    // Update save parameters
    if (updatedKeys.length > 0) {
        capacity.toSave.keys = updatedKeys;
        capacity.toSave.autoSave = true;
        console.log("-FETCHING CAPA- : Updated Model");
    }

    return capacity
}

