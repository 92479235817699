import React, { useState } from 'react';

// Import components
import Modification from './Modification';

// Import CSS & assets
import user_pp from '../../../../assets/user_pp_default.png';

const Modifications = ({ capacity, user }) => {

    const [isDetailed, setIsDetailed] = useState(false);

    const getUserData = (userId) => {
        if (userId === "admin") {
            return {
                avatar: user_pp ,
                name: "un Administrateur",
            }
        }
        for (var i = 0; i < user.users.length; i++) {
            if (user.users[i]._id === userId) {
                var userName = user.users[i].firstName + " " + user.users[i].lastName;
                if (userId === user.result._id) {
                    userName = "vous";
                }
                return {
                    avatar: user.users[i].avatar,
                    name: userName,
                }
            }
        }
        return {
            avatar: user_pp ,
            name: "Utilisateur supprimé ou désactivé",
        }
    }

    const handleMouseLeave = () => {
        setTimeout(() => {
            setIsDetailed(false);
        }, 500);
    }

    return (
        <div className="app__sectiondata__widget2" onMouseLeave={handleMouseLeave}>
            <div id="section_0_modif" className="app__sectiondata_widget_title">Suivi des modifications</div>
            {capacity?.modifications.map((item, index) => {
                var userData = getUserData(item.userId);
                var position = "middle";
                if (index === 0) { position = "first" }
                else if (index === capacity.modifications.length - 1) { position = "last" }
                return (
                    <Modification key={index} modification={item} userData={userData} position={position} total={capacity.modifications.length} isDetailed={isDetailed} setIsDetailed={setIsDetailed} />
                )
            })}
        </div >
    );
};

export default Modifications;