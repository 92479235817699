import * as turf from '@turf/turf';
import mapboxgl from 'mapbox-gl';


const sources_ids = ['sourceCommoditiesTransport'];
const layers_ids = ['layerCommoditiesTransport_Points', 'layerCommoditiesTransport_Lines'];
var markers = [];
var marker_img_bus = '<svg width="14" height="14" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M222 0C117 0 78 34.6875 78 128V656C78 677.75 84.375 693.438 94 705V752C94 778.438 115.562 800 142 800H206C232.438 800 254 778.438 254 752V736H546V752C546 778.438 567.562 800 594 800H658C684.438 800 706 778.438 706 752V705C715.625 693.438 722 677.75 722 656V144C722 74.3125 713.375 0 610 0H222ZM270 64H546C554.875 64 562 71.1875 562 80V112C562 120.812 554.875 128 546 128H270C261.188 128 254 120.812 254 112V80C254 71.1875 261.188 64 270 64ZM206 176H594C626 176 642 192 642 224V416C642 448 610.75 463 594 463L206 464C174 464 158 448 158 416V224C158 192 174 176 206 176ZM62 192C44.375 192 30 206.375 30 224V352C30 369.625 44.375 384 62 384V192ZM738 192V384C755.688 384 770 369.625 770 352V224C770 206.375 755.688 192 738 192ZM214 544C244.938 544 270 569.062 270 600C270 630.938 244.938 656 214 656C183.062 656 158 630.938 158 600C158 569.062 183.062 544 214 544ZM586 544C616.938 544 642 569.062 642 600C642 630.938 616.938 656 586 656C555.062 656 530 630.938 530 600C530 569.062 555.062 544 586 544Z" fill="white"/></svg>';
var marker_img_tram = '<svg width="10" height="14" viewBox="0 0 600 800" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M300 0C181.562 0 124 20.9375 124 64C124 72.8125 131.188 80 140 80C148.812 80 156 72.8125 156 64C156 56.125 171.938 43.25 221.5 36.5L235 115.5C105.625 130 28 186.812 28 240V612.5C28 661.812 62.6875 703.438 110 716L43 772C36.3125 777.625 35.375 787.312 41 794C44.125 797.75 48.5 800 53 800C56.5625 800 60.5 798.5 63.5 796L154.5 720H445.5L536.5 796C539.5 798.5 543.438 800 547 800C551.5 800 555.875 797.75 559 794C564.625 787.312 563.688 777.625 557 772L490 716C537.312 703.438 572 661.812 572 612.5V240C572 186.812 494.375 130 365 115.5L378.5 36.5C428.062 43.25 444 56.125 444 64C444 72.8125 451.125 80 460 80C468.875 80 476 72.8125 476 64C476 20.9375 418.438 0 300 0ZM300 32C317.375 32 332.312 32.625 346 33.5L332.5 113C321.875 112.438 311.188 112 300 112C288.812 112 278.125 112.438 267.5 113L254 33.5C267.688 32.625 282.625 32 300 32ZM204 208H396C404.875 208 412 215.188 412 224V256H188V224C188 215.188 195.188 208 204 208ZM140 288H460C477.688 288 492 301.312 492 317.5V434.5C492 450.688 477.688 464 460 464H140C122.312 464 108 450.688 108 434.5V317.5C108 301.312 122.312 288 140 288ZM156 544C182.5 544 204 565.5 204 592C204 618.5 182.5 640 156 640C129.5 640 108 618.5 108 592C108 565.5 129.5 544 156 544ZM444 544C470.5 544 492 565.5 492 592C492 618.5 470.5 640 444 640C417.5 640 396 618.5 396 592C396 565.5 417.5 544 444 544Z" fill="white"/></svg>';
var marker_img_subway = '<svg width="10" height="14" viewBox="0 0 600 800" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M571.131 498.725V146.702C571.131 65.7188 506.58 0 300.067 0C93.5406 0 29 65.7188 29 146.702V498.723C29 568.322 80.7234 632.394 161.603 667.255L64.6984 800H140.547L223.253 686.666C247.403 691.763 273.075 694.678 300.066 694.678C327.055 694.678 352.712 691.75 376.877 686.666L459.572 800H535.431L438.527 667.244C519.408 632.394 571.131 568.322 571.131 498.725ZM200.322 76.3937C200.322 73.0969 203.009 70.4094 206.305 70.4094H393.827C397.123 70.4094 399.798 73.0969 399.798 76.3937V113.703C399.798 117.012 397.123 119.7 393.827 119.7H206.305C203.008 119.7 200.322 117.012 200.322 113.703V76.3937ZM111.136 484.628C111.136 460.655 130.583 441.22 154.544 441.22C178.53 441.22 197.975 460.655 197.975 484.628C197.975 508.612 178.528 528.047 154.544 528.047C130.583 528.047 111.136 508.612 111.136 484.628ZM445.575 528.047C421.603 528.047 402.156 508.612 402.156 484.628C402.156 460.655 421.603 441.22 445.575 441.22C469.548 441.22 488.995 460.655 488.995 484.628C488.995 508.612 469.548 528.047 445.575 528.047ZM508.95 324.083C508.95 339.523 496.438 352.036 481.008 352.036H119.136C103.695 352.036 91.1953 339.523 91.1953 324.083V187.536C91.1953 172.108 103.695 159.583 119.136 159.583H481.008C496.436 159.583 508.95 172.108 508.95 187.536V324.083Z" fill="white"/></svg>';

export const get_ids = () => {
    // Get all source ids
    var sources = [];
    sources_ids.forEach(source_id => {
        sources.push({ id: source_id });
    })
    // Get all layer ids
    var layers = [];
    layers_ids.forEach(layer_id => {
        layers.push({ id: layer_id });
    })

    return {
        sources: sources,
        layers: layers,
        markers: markers,
    }
}


export const get_data = (capacity) => {
    // Set map data
    var map_data = {
        sources: [],
        layers: [],
        markers: [],
        bbox: null
    }

    // Check needed data
    var check_data = false;
    if (capacity?.context?.commodities?.transports_bus_tram_subway?.stops?.bus && capacity.context.commodities.transports_bus_tram_subway.stops.bus.length > 0) {
        check_data = true;
    }
    if (capacity?.context?.commodities?.transports_bus_tram_subway?.stops?.tram && capacity.context.commodities.transports_bus_tram_subway.stops.tram.length > 0) {
        check_data = true;
    }
    if (capacity?.context?.commodities?.transports_bus_tram_subway?.stops?.subway && capacity.context.commodities.transports_bus_tram_subway.stops.subway.length > 0) {
        check_data = true;
    }
    if (check_data === false) {
        return map_data
    }

    // Preset layer data
    var layer_data = {
        type: 'FeatureCollection',
        features: [],
    };

    // Add data
    var all_items = capacity.context.commodities.transports_bus_tram_subway.stops.bus.concat(capacity.context.commodities.transports_bus_tram_subway.stops.tram.concat(capacity.context.commodities.transports_bus_tram_subway.stops.subway));
    for (var i = 0; i < all_items.length; i++) {
        var item = all_items[i];
        var item_marker_coords = null;
        // Generate point
        if (item?.type === "node" && item?.coords && item.coords.length === 2) {
            // layer_data.features.push({
            //     type: "Feature",
            //     properties: item?.properties || null,
            //     geometry: {
            //         type: "Point",
            //         coordinates: item.coords,
            //     }
            // });
            item_marker_coords = item.coords;
        }
        // Generate lines & points
        if (item?.lines) {
            item.lines.forEach(line => {
                if (line?.coords) {
                    layer_data.features.push({
                        type: "Feature",
                        properties: { color: line?.color || "#062134", category: item?.category || null },
                        geometry: {
                            type: "MultiLineString",
                            coordinates: line.coords,
                        }
                    });
                }
                if (line?.stops_coords) {
                    layer_data.features.push({
                        type: "Feature",
                        properties: { color: line?.color || "#062134" },
                        geometry: {
                            type: "MultiPoint",
                            coordinates: line.stops_coords,
                        }
                    });
                }
            })
        }
        // Generate marker
        if (item_marker_coords !== null && item_marker_coords.length === 2) {
            var title = "Arrêt";
            var marker_img = "";
            if (item?.category === "bus") { title = "Arrêt de bus"; marker_img = marker_img_bus }
            else if (item?.category === "tram") { title = "Arrêt de tramway"; marker_img = marker_img_tram }
            else if (item?.category === "subway") { title = "Arrêt de métro"; marker_img = marker_img_subway }
            var title_txt = '<span style="color:#062134;font-size:15px;font-weight:700"><strong>' + title + '</strong></span>';
            var infos = [
                { label: "Nom", value: item?.name || "NC" },
                { label: "Distance", value: item?.distance ? item?.distance + " m" : "NC" },
                { label: "Lignes désservies", value: item?.lines ? (item.lines.map(line => { return ('<span style="background-color:' + (line?.color || "#062134") + ';color:' + (item?.color_txt || "#FFF") + ';border-radius: 4px;padding: 0 6px">' + line.name + '</span>') }).join("<br />")) : "NC" },
            ]
            var infos_txt = infos.map(info => { return ('<br /><span style="font-size:12px;font-weight: 600;color: grey;">' + info.label + ' :</span><br /><span style="color:#062134;font-weight:500;">' + info.value + '</span>') }).join('');
            var marker_label = item?.name || "";
            var el = document.createElement('div');
            el.className = 'marker';
            el.innerHTML = '<div style="cursor:pointer;padding:3px 8px 2px;background-color:#062134;border-radius:6px;display:flex;justify-content:center;align-items:center;gap:4px"><div style="display:flex;justify-content:center">' + marker_img + '</div><div style="font-family:Manrope;color:#FFF;">' + marker_label + '</div></div>';
            map_data.markers.push(new mapboxgl.Marker(el)
                .setLngLat(item_marker_coords)
                .setPopup(new mapboxgl.Popup({ offset: 15 })
                    .setHTML('<p style="font-family:Manrope">' + title_txt + infos_txt + '</p>'))
            )
        }
    }


    // Check data exists
    if (!layer_data.features.length > 0) {
        return map_data
    }

    // Create sources
    map_data.sources.push(
        {
            id: sources_ids[0],
            value: {
                type: 'geojson',
                data: layer_data
            }
        }
    )

    // Create layers
    map_data.layers.push(
        {
            id: layers_ids[0],
            type: 'circle',
            source: sources_ids[0],
            paint: {
                'circle-radius': 4,
                'circle-color': ['get', 'color']
            },
            filter: ['in', '$type', 'Point']
        }
    );
    map_data.layers.push(
        {
            id: layers_ids[1],
            type: 'line',
            source: sources_ids[0],
            layout: {
                'line-cap': 'round',
                'line-join': 'round'
            },
            paint: {
                // 'line-width': 2,
                'line-width': [
                    'match',
                    ['get', 'category'],
                    'subway', 4,
                    'tram', 4,
                    2],
                'line-color': ['get', 'color'],
                // 'line-dasharray': [2, 2],
                'line-dasharray': [
                    "match", ["get", "category"],
                    "subway", ["literal", [2, 2]],
                    ["literal", [1, 0]]
                ],
            },
            filter: ['in', '$type', 'LineString']
        }
    );

    // Set global markers
    if (map_data.markers.length > 0) {
        markers = map_data.markers;
    }
    else {
        markers = [];
    }

    // Get bbox
    var bbox_data = {
        type: 'FeatureCollection',
        features: layer_data.features.concat([capacity?.landBase?.union?.geometry]),
    };
    var bbox = turf.bbox(bbox_data);
    if (bbox && bbox.length === 4) {
        var bbox_max = turf.bbox(turf.circle(capacity?.landBase?.union?.center, 8, { steps: 8 }));
        var bbox_final = [
            Math.max(bbox[0], bbox_max[0]),
            Math.max(bbox[1], bbox_max[1]),
            Math.min(bbox[2], bbox_max[2]),
            Math.min(bbox[3], bbox_max[3]),
        ];
        var bbox_poly = [
            [bbox_final[0], bbox_final[1]],
            [bbox_final[2], bbox_final[1]],
            [bbox_final[2], bbox_final[3]],
            [bbox_final[0], bbox_final[3]],
            [bbox_final[0], bbox_final[1]],
        ]
        map_data.bbox = [bbox_poly];
    }


    // Return
    console.log("map_data", map_data);
    return map_data

}

