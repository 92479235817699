// Import dependencies
import * as turf from '@turf/turf';

// __________ MUNICIPALITY

// MUNICIPALITY : Get from coordinates
export const get_municipality = async (longitude, latitude, capacity, setCapacity) => {
    // Test if new city
    var turf_test = false;
    if (capacity.city !== null && capacity.city !== "fetching" && capacity?.city?.bounds) {
        var turf_point = turf.point([longitude, latitude]);
        var turf_polygon = turf.multiPolygon(capacity.city.bounds);
        turf_test = !turf.booleanPointInPolygon(turf_point, turf_polygon);
    }
    if (capacity.city === null || turf_test === true) {
        await fetch("https://api-adresse.data.gouv.fr/reverse/?lon=" + longitude + "&lat=" + latitude)
            .then(res => res.json())
            .then(
                (result) => {
                    // Check if there is at least 1 feature
                    if (result["features"].length < 1) {
                        console.log("Error fetching municiplity : Not any features");
                        setCapacity({ ...capacity, city: null, toSave: { autoSave: false, action: "", value: "" } });
                        return;
                    }
                    // Set city from first result (most relevant)
                    var municipality = {
                        inseecode: result["features"][0]["properties"]["citycode"],
                        postcode: result["features"][0]["properties"]["postcode"],
                        title: result["features"][0]["properties"]["city"],
                        url_bounds: "https://cadastre.data.gouv.fr/bundler/cadastre-etalab/communes/" + result["features"][0]["properties"]["citycode"] + "/geojson/communes",
                        url_lands: "https://cadastre.data.gouv.fr/bundler/cadastre-etalab/communes/" + result["features"][0]["properties"]["citycode"] + "/geojson/parcelles",
                    }
                    // Get bounds
                    fetch(municipality["url_bounds"])
                        .then(res2 => res2.json())
                        .then(
                            (result2) => {
                                municipality["bounds"] = result2["features"][0]["geometry"]["coordinates"];
                                setCapacity({ ...capacity, city: municipality, toSave: { autoSave: false, action: "", value: "" } });
                                return;
                            },
                            (error) => {
                                console.log("Error fetching bounds", error);
                                setCapacity({ ...capacity, city: municipality, toSave: { autoSave: false, action: "", value: "" } });
                                return;
                            }
                        )

                },
                (error) => {
                    console.log("Error fetching municiplity", error);
                    setCapacity({ ...capacity, city: null, toSave: { autoSave: false, action: "", value: "" } });
                    return;
                }
            )
    }
    else {
        console.log("city already fetched for this zone");
        return;
    }
}



// __________ LANDS DB
var landsDB_model = {};

// LANDS DB : Create DB
export const create_landsDB = (lands_list) => {
    landsDB_model = {};
    // Loop th lands
    for (var i = 0; i < lands_list.length; i++) {
        // Get section
        var section = lands_list[i]["properties"]["section"];
        // Create section key if not exists
        if (!landsDB_model[section]) {
            landsDB_model[section] = {
                "0": [],
                "1": [],
                "2": [],
                "3": [],
                "4": [],
                "5": [],
                "6": [],
                "7": [],
                "8": [],
                "9": [],
            }
        }
        // Get first char of numero
        var numero = lands_list[i]["properties"]["numero"].charAt(0);
        // Add land into DB
        landsDB_model[section][numero].push(lands_list[i]);
    }
    console.log("landsDB_model", landsDB_model)
}

// LANDS DB : Get land geometry
export const get_landsDB = (land_properties) => {
    console.log("landsDB_GetGeometry land_id", land_properties.id);
    console.log("landsDB_GetGeometry model", landsDB_model);
    // Get section and numero
    var section = land_properties.section;
    var numero = land_properties.numero.charAt(0);
    console.log("landsDB_GetGeometry section", section);
    console.log("landsDB_GetGeometry numero", numero);
    console.log("landsDB_GetGeometry model at section/numero", landsDB_model[section][numero]);
    // Loop th lands inside specific section/numero
    for (var i = 0; i < landsDB_model[section][numero].length; i++) {
        // Check if id is matching
        if (landsDB_model[section][numero][i]["id"] === land_properties.id) {
            // Return geometry
            return landsDB_model[section][numero][i]["geometry"];
        }
    }
    return "none";
}



// __________ LAND INTERACTIONS

// LAND : Unselect
export const land_unselect = (land_properties, capacity, setCapacity) => {
    // Get already selected lands
    var landsSelected_before = [];
    if (capacity?.landBase?.lands) {
        landsSelected_before = capacity.landBase.lands;
    }

    // Check if current land is already selected
    for (var i = 0; i < landsSelected_before.length; i++) {
        if (landsSelected_before[i]["properties"]["id"] === land_properties["id"]) {
            console.log("This land is already selected");
            // Remove from landsSelected_before
            landsSelected_before.splice(i, 1);
            break;
        }
    }
}

// LAND : Select
export const land_select = (land_id, land_properties, land_geometry, capacity, setCapacity, visibleLandsAndRoads, visibleBuildings, setIsMapLoading, map) => {

    try {
        // Get already selected lands
        var landsSelected_before = [];
        if (capacity?.landBase?.lands) {
            landsSelected_before = capacity.landBase.lands;
        }

        // Check if current land is already selected
        var isSelected = false;
        for (var i = 0; i < landsSelected_before.length; i++) {
            if (landsSelected_before[i]["properties"]["id"] === land_properties["id"]) {
                console.log("This land is already selected");
                // Set test value
                isSelected = true;
                // Remove from landsSelected_before
                landsSelected_before.splice(i, 1);
                break;
            }
        }

        // If not already selected => Add to landsSelected_before
        if (!isSelected) {
            console.log("This land is not selected yet");

            // ----- VERSION WITH LANDS DB -----
            var land_area = turf.area(turf.polygon(land_geometry.coordinates));
            landsSelected_before.push({ generated_id: land_id, properties: land_properties, geometry: land_geometry, area: land_area });
            // Continue
            land_union(landsSelected_before, capacity, setCapacity, visibleLandsAndRoads, visibleBuildings, map);

            // ----- VERSION WITH DIRECT FETCH -----
            // // Set loading state
            // setIsMapLoading(true);
            // // Fetch API Carto to get real coordinates
            // let url = "https://apicarto.ign.fr/api/cadastre/parcelle?code_insee=" + land_properties["id"].substring(0, 5) + "&section=" + land_properties["id"].substring(8, 10) + "&numero=" + land_properties["id"].substring(10) + "&_limit=1";
            // fetch(url, { "method": "get" })
            //     .then((httpResponse) => {
            //         if (httpResponse.ok) {
            //             return httpResponse.json();
            //         } else {
            //             return Promise.reject("Fetch API Carto did not succeed");
            //         }
            //     })
            //     .then(json => {
            //         console.log("Success API Carto", json);
            //         var land_area = turf.area(turf.polygon(land_geometry.coordinates));
            //         landsSelected_before.push({ generated_id: land_id, properties: land_properties, geometry: json["features"][0]["geometry"], area: land_area });
            //         // Continue
            //         land_union(landsSelected_before, capacity, setCapacity, visibleLandsAndRoads, map);
            //         // Set loading state
            //         setIsMapLoading(false);
            //     });

            // ----- VERSION WITH CHANGING GEOM FROM MAP -----
            // // Calculate area
            // var land_area = turf.area(turf.polygon(land_geometry.coordinates));
            // // Set land before matching id list
            // var land_id_match = [land_properties["id"]];
            // for (var i = 0; i < landsSelected_before.length; i++) {
            //     land_id_match.push(landsSelected_before[i]["properties"]["id"]);
            // }
            // console.log("land_id_match", land_id_match);
            // // Search lands inside query result
            // var land_list = [];
            // for (var i = 0; i < visibleLandsAndRoads.length; i++) {
            //     if (visibleLandsAndRoads[i]["source"] = "sourceLandsPolys" && land_id_match.includes(visibleLandsAndRoads[i]["properties"]["id"])) {
            //         land_list.push({ generated_id: land_id, properties: visibleLandsAndRoads[i]["properties"], geometry: visibleLandsAndRoads[i]["geometry"], area: land_area });
            //         land_id_match.splice(land_id_match.indexOf(visibleLandsAndRoads[i]["properties"]["id"]), 1);
            //     }
            // }
            // // Add land to landSelection
            // // landsSelected_before.push({ generated_id: land_id, properties: land_properties, geometry: land_geometry, area: land_area });
            // landsSelected_before = land_list;
        }
        else {
            land_union(landsSelected_before, capacity, setCapacity, visibleLandsAndRoads, visibleBuildings, map);
        }
    } catch (error) {
        console.log(error);
        throw new Error("Error occurred with the database");
    }


}

// LAND : Create union
function land_union(landsSelected_before, capacity, setCapacity, visibleLandsAndRoads, visibleBuildings, map) {
    var sections = [];

    // Sort landsSelected_before on item.properties.id
    landsSelected_before.sort(function (a, b) {
        return parseInt(a["properties"]["numero"]) - parseInt(b["properties"]["numero"]);
    });

    // Create union
    var union = null;
    var polyLand = null;
    if (landsSelected_before.length > 0) {
        if (landsSelected_before[0]["geometry"]["type"] === "MultiPolygon") {
            if (landsSelected_before[0]["geometry"]["coordinates"].length === 1) {
                polyLand = turf.polygon(landsSelected_before[0]["geometry"]["coordinates"][0]);
            }
            else {
                polyLand = turf.multiPolygon(landsSelected_before[0]["geometry"]["coordinates"]);
            }
        }
        else {
            polyLand = turf.polygon(landsSelected_before[0]["geometry"]["coordinates"]);
        }
        if (landsSelected_before.length > 1) {
            for (var i = 1; i < landsSelected_before.length; i++) {
                var poly2;
                if (landsSelected_before[i]["geometry"]["type"] === "MultiPolygon") {
                    poly2 = turf.multiPolygon(landsSelected_before[i]["geometry"]["coordinates"]);
                }
                else {
                    poly2 = turf.polygon(landsSelected_before[i]["geometry"]["coordinates"]);
                }
                polyLand = turf.union(polyLand, poly2);
            }
        }
        // Get outerline
        var outerLine = turf.polygonToLine(polyLand);
        // Get center
        var center = turf.centroid(polyLand);
        // Get area
        var area = turf.area(polyLand);
        // Get bbox
        var bbox_coord = turf.getCoords(turf.bboxPolygon(turf.bbox(polyLand)));
        var bbox_length1 = turf.distance(turf.point(bbox_coord[0][0]), turf.point(bbox_coord[0][1])) * 1000;
        var bbox_length2 = turf.distance(turf.point(bbox_coord[0][1]), turf.point(bbox_coord[0][2])) * 1000;
        // Get point0
        let lat_min = bbox_coord[0][0][0];
        let lng_min = bbox_coord[0][0][1];
        let lat_max = bbox_coord[0][0][0];
        let lng_max = bbox_coord[0][0][1];
        for (var i = 1; i < bbox_coord[0].length; i++) {
            if (bbox_coord[0][i][0] < lat_min) {
                lat_min = bbox_coord[0][i][0];
            }
            if (bbox_coord[0][i][0] > lat_max) {
                lat_max = bbox_coord[0][i][0];
            }
            if (bbox_coord[0][i][1] < lng_min) {
                lng_min = bbox_coord[0][i][1];
            }
            if (bbox_coord[0][i][1] > lng_max) {
                lng_max = bbox_coord[0][i][1];
            }
        }
        // Create matrix
        let origin = [lat_min, lng_min];
        let min = mercator_to_local([lat_min, lng_min], origin);
        let max = mercator_to_local([lat_max, lng_max], origin);
        let x_min = min[0];
        let x_max = max[0];
        let y_min = min[1];
        let y_max = max[1];
        let matrix = {
            "lat_min": lat_min,
            "lat_max": lat_max,
            "lat_delta": lat_max - lat_min,
            "x_min": x_min,
            "x_max": x_max,
            "x_delta": x_max - x_min,
            "lng_min": lng_min,
            "lng_max": lng_max,
            "lng_delta": lng_max - lng_min,
            "y_min": y_min,
            "y_max": y_max,
            "y_delta": y_max - y_min,
        };
        // Final bbox
        var bbox = {
            'coordinates': bbox_coord,
            'origin': origin,
            'lengthH': bbox_length1,
            'lengthV': bbox_length2,
            'matrix': matrix,
        }

        // Create bounds
        let coords = turf.getCoords(polyLand);
        let bounds = [];
        for (var i = 1; i < coords[0].length; i++) {
            let id = "bnd" + (Math.round(Math.random() * 100000000)).toString();
            let bound_start_coord = coords[0][i - 1];
            let bound_start_local = mercator_to_local(bound_start_coord, bbox["origin"]);
            let bound_end_coord = coords[0][i];
            let bound_end_local = mercator_to_local(bound_end_coord, bbox["origin"]);
            let bound_gradient = (bound_end_local[1] - bound_start_local[1]) / ((bound_end_local[0] - bound_start_local[0]));
            let bound_intersect = bound_start_local[1] - (bound_gradient * bound_start_local[0]);
            let bearing = turf.bearing(turf.point(bound_start_coord), turf.point(bound_end_coord));
            bounds.push({
                '_id': id,
                'label': "Limite " + i.toString(10),
                'value': id,
                'point1_coord': coords[0][i - 1],
                'point2_coord': coords[0][i],
                'type_id': "",
                'type_title': "",
                'type_description': "",
                'slope': (coords[0][i][1] - coords[0][i - 1][1]) / (coords[0][i][0] - coords[0][i - 1][0]),
                'length': turf.length(turf.lineString([coords[0][i - 1], coords[0][i]])) * 1000,
                "start_coord_global": bound_start_coord,
                "start_coord_local": bound_start_local,
                "end_coord_global": bound_end_coord,
                "end_coord_local": bound_end_local,
                "gradient": bound_gradient,
                "intersect": bound_intersect,
                "bearing": bearing
            });
        }
        // Get angles of bounds
        for (var i = 0; i < bounds.length; i++) {
            // Get last and next index
            var last = i - 1;
            if (last === -1) { last = bounds.length - 1 }
            var next = i + 1;
            if (next === bounds.length) { next = 0 }
            // Get last line bearing & current line bearing
            var lastBearing = bounds[last].bearing;
            if (lastBearing < 0) { lastBearing = lastBearing + 180 }
            else { lastBearing = lastBearing - 180 }
            var currentBearing = bounds[i].bearing;
            if (currentBearing < 0) { currentBearing = currentBearing + 180 }
            else { currentBearing = currentBearing - 180 }
            // Get last angle & next angle
            var lastAngle = bounds[i].bearing - lastBearing;
            if (lastAngle < 0) { lastAngle = lastAngle + 360 }
            var nextAngle = bounds[next].bearing - currentBearing;
            if (nextAngle < 0) { nextAngle = nextAngle + 360 }
            // Add data
            bounds[i].start_angle = lastAngle;
            bounds[i].end_angle = nextAngle;
        }
        // Create bounds groups
        let bounds_groups = [];
        let delta = 1;
        let delta_bearing = 10;
        let length_min = 1;
        let slope = 0;
        let bearing = 0;
        let group = [];
        let label = "";
        let count = 0;
        let length = 0;
        let start_coord = [];
        let start_local = [];
        let end_coord = [];
        let end_local = [];
        let middle_coord_global = [];
        let middle_coord_local = [];
        let intersect = 0;
        let gradient = 0;
        for (var i = 0; i < bounds.length; i++) {
            // If first => initialise
            if (i === 0) {
                slope = bounds[i]["slope"];
                bearing = bounds[i]["bearing"];
                group = [i];
                count++;
                label = "Groupe de limites #" + count.toString(10);
                length = bounds[i]["length"];
                start_coord = bounds[i]["point1_coord"];
                start_local = mercator_to_local(start_coord, bbox["origin"]);
            }
            else {
                // Check if slope === bounds[slope] +- delta
                // if (bounds[i]["slope"] - delta <= slope && bounds[i]["slope"] + delta >= slope) {
                if (bounds[i]["bearing"] - delta_bearing <= bearing && bounds[i]["bearing"] + delta_bearing >= bearing) {
                    // Add bound index to group
                    group.push(i);
                    // Get new length
                    length = length + bounds[i]["length"];
                    // Get new slope (avergae)
                    /*let slope_sum = 0;
                    for (var j = 0; j < group.length; j++) {
                        slope_sum = slope_sum + bounds[group[j]]["slope"];
                    }
                    slope = slope_sum / group.length;*/
                }
                else {
                    // Check if next bound slop is = to slope and if length is smaller than length_min
                    let next = i + 1;
                    if (next >= bounds.length) {
                        next = 0;
                    }
                    // if (bounds[i]["length"] <= length_min && bounds[next]["slope"] - delta <= slope && bounds[next]["slope"] + delta >= slope) {
                    if (bounds[i]["length"] <= length_min && bounds[next]["bearing"] - delta_bearing <= bearing && bounds[next]["bearing"] + delta_bearing >= bearing) {
                        // Add bound index to group
                        group.push(i);
                        // Get new length
                        length = length + bounds[i]["length"];
                    }
                    else {
                        // Push group to bounds_groups
                        end_coord = bounds[i - 1]["point2_coord"];
                        end_local = mercator_to_local(end_coord, bbox["origin"]);
                        gradient = (end_local[1] - start_local[1]) / ((end_local[0] - start_local[0]));
                        intersect = start_local[1] - (gradient * start_local[0]);
                        middle_coord_global = turf.getCoord(turf.midpoint(turf.point(start_coord), turf.point(end_coord)));
                        middle_coord_local = mercator_to_local(middle_coord_global, bbox["origin"]);
                        bounds_groups.push({
                            "_id": "grpbnd" + (Math.round(Math.random() * 100000000)).toString(),
                            "label": label,
                            "slope": slope,
                            "bearing": bearing,
                            "gradient": gradient,
                            "intersect": intersect,
                            "bounds": group,
                            "length": length,
                            "start_coord_global": start_coord,
                            "start_coord_local": start_local,
                            "end_coord_global": end_coord,
                            "end_coord_local": end_local,
                            "middle_coord_global": middle_coord_global,
                            "middle_coord_local": middle_coord_local,
                            "context_analysis": {
                                roads: [],
                                lands: [],
                                result: {
                                    type: "nc",
                                    subtype: "unknown",
                                    elements: []
                                }
                            }
                        });
                        // Reinitialise
                        slope = bounds[i]["slope"];
                        bearing = bounds[i]["bearing"];
                        group = [i];
                        count++;
                        label = "Groupe de limites #" + count.toString(10);
                        length = bounds[i]["length"];
                        start_coord = bounds[i]["point1_coord"];
                        start_local = mercator_to_local(start_coord, bbox["origin"]);
                    }
                }
                // Check if last
                if (i === bounds.length - 1) {
                    // Check if same slope than first
                    // if (bounds_groups.length > 1 && bounds[i]["slope"] - delta <= bounds[0]["slope"] && bounds[i]["slope"] + delta >= bounds[0]["slope"]) {
                    if (bounds_groups.length > 1 && bounds[i]["bearing"] - delta_bearing <= bounds[0]["bearing"] && bounds[i]["bearing"] + delta_bearing >= bounds[0]["bearing"]) {
                        console.log("ATTENTION : merge de la première et dernière limite => a revoir");
                        let merged_group = group.concat(bounds_groups[0]["bounds"]);
                        let merged_length = length + bounds_groups[0]["length"];
                        bounds_groups[0]["bounds"] = merged_group;
                        bounds_groups[0]["length"] = merged_length;
                        bounds_groups[0]["start_coord_global"] = bounds[group[0]]["point1_coord"];
                        bounds_groups[0]["start_coord_local"] = mercator_to_local(bounds_groups[0]["start_coord_global"], bbox["origin"]);;
                        bounds_groups[0]["gradient"] = (bounds_groups[0]["end_coord_local"][1] - bounds_groups[0]["start_coord_local"][1]) / (bounds_groups[0]["end_coord_local"][0] - bounds_groups[0]["start_coord_local"][0]);
                        bounds_groups[0]["intersect"] = bounds_groups[0]["start_coord_local"][1] - (bounds_groups[0]["gradient"] * bounds_groups[0]["start_coord_local"][0]);
                    }
                    else {
                        end_coord = bounds[i]["point2_coord"];
                        end_local = mercator_to_local(end_coord, bbox["origin"]);
                        gradient = (end_local[1] - start_local[1]) / ((end_local[0] - start_local[0]));
                        intersect = start_local[1] - (gradient * start_local[0]);
                        middle_coord_global = turf.getCoord(turf.midpoint(turf.point(start_coord), turf.point(end_coord)));
                        middle_coord_local = mercator_to_local(middle_coord_global, bbox["origin"]);
                        bounds_groups.push({
                            "_id": "grpbnd" + (Math.round(Math.random() * 100000000)).toString(),
                            "label": label,
                            "slope": slope,
                            "bearing": bearing,
                            "gradient": gradient,
                            "intersect": intersect,
                            "bounds": group,
                            "length": length,
                            "start_coord_global": start_coord,
                            "start_coord_local": start_local,
                            "end_coord_global": end_coord,
                            "end_coord_local": end_local,
                            "middle_coord_global": middle_coord_global,
                            "middle_coord_local": middle_coord_local,
                            "context_analysis": {
                                roads: [],
                                lands: [],
                                result: {
                                    type: "nc",
                                    subtype: "unknown",
                                    elements: []
                                }
                            }
                        });
                    }
                }
            }
        }

        // Create union model
        union = {
            geometry: polyLand,
            outerLine: outerLine,
            center: center,
            area: area,
            bbox: bbox,
            bounds: bounds,
            bounds_groups: bounds_groups,
        }

        // __________ Analyse context elements
        // Create buffer of polyLand to check if land is near union
        var buffer = turf.buffer(polyLand, 0.002);
        // Keep studied ids to check doubled ones
        var lands_studied_ids = [];
        var roads_studied_ids = [];
        // STEP 01 : Create check lines
        // Loop th all elements of map query
        console.log("LANDS & ROADS list", visibleLandsAndRoads);
        for (var i = 0; i < visibleLandsAndRoads.length; i++) {
            // Check if type land
            if (visibleLandsAndRoads[i]["layer"]["id"] === "layerLandsPolys") {
                // Create sections list
                if (!sections.includes(visibleLandsAndRoads[i]["properties"]["section"])) {
                    sections.push(visibleLandsAndRoads[i]["properties"]["section"]);
                }
                // Check if land is part of selected ones
                var isSelected = false;
                for (var j = 0; j < landsSelected_before.length; j++) {
                    if (landsSelected_before[j]["properties"]["id"] === visibleLandsAndRoads[i]["properties"]["id"]) {
                        // Set test value
                        isSelected = true;
                        break;
                    }
                }
                if (!isSelected) {
                    // Check if not doubled
                    if (!lands_studied_ids.includes(visibleLandsAndRoads[i].id)) {
                        // Add to studied list to check for doubles later
                        lands_studied_ids.push(visibleLandsAndRoads[i].id);
                        // Create the turf polygon element of land
                        var land_poly = null;
                        if (visibleLandsAndRoads[i]["geometry"]["type"] === "MultiPolygon" && visibleLandsAndRoads[i]["geometry"]["coordinates"][0].length > 3) {
                            land_poly = turf.multiPolygon(visibleLandsAndRoads[i]["geometry"]["coordinates"]);
                        }
                        else if (visibleLandsAndRoads[i]["geometry"]["type"] === "Polygon" && visibleLandsAndRoads[i]["geometry"]["coordinates"][0].length > 3) {
                            land_poly = turf.polygon(visibleLandsAndRoads[i]["geometry"]["coordinates"]);
                        }
                        // Check if land_poly intersects the buffer
                        if (land_poly !== null && turf.booleanIntersects(buffer, land_poly) === true) {
                            // Create the turf point element of land center
                            var land_center = turf.centerOfMass(land_poly);
                            // Loop th bounds group
                            for (var j = 0; j < union.bounds_groups.length; j++) {
                                // Create the turf line element of bounds group
                                // var bound_line = turf.lineString([union.bounds_groups[j]["start_coord_global"], union.bounds_groups[j]["end_coord_global"]]);
                                // bound_line details with all lines inside
                                var coordinates = [union.bounds[union.bounds_groups[j].bounds[0]]["start_coord_global"]];
                                for (var k = 0; k < union.bounds_groups[j].bounds.length; k++) {
                                    coordinates.push(union.bounds[union.bounds_groups[j].bounds[k]]["end_coord_global"]);
                                }
                                var bound_line = turf.lineString(coordinates);
                                // Create smaller bound line to avoid angle touches
                                var bound_line_small = turf.length(bound_line) > 0.0003 ? turf.lineSliceAlong(bound_line, 0.00015, (turf.length(bound_line)) - 0.00015) : bound_line;
                                // Create the turf point element of nearest point of bounds group from land center
                                var bound_nearest_point = turf.nearestPointOnLine(bound_line_small, land_center);
                                // Check if nearest point is not a corner of the bound
                                // if (bound_nearest_point.geometry.coordinates !== union.bounds_groups[j]["start_coord_global"] && bound_nearest_point.geometry.coordinates !== union.bounds_groups[j]["end_coord_global"]) {
                                // Create the turf line element
                                var land_nearest_line = turf.lineString([bound_nearest_point.geometry.coordinates, land_center.geometry.coordinates]);
                                var land_distance = bound_nearest_point.properties.dist;
                                var land_nearest_line_splited = turf.lineChunk(land_nearest_line, 0.0001);
                                var check_line = land_nearest_line;
                                if (land_nearest_line_splited.type === "FeatureCollection") {
                                    check_line = land_nearest_line_splited.features[0];
                                }
                                // Test overlapping
                                var overlapping = turf.lineOverlap(bound_line_small, land_poly, { tolerance: 0.0001 });
                                var check_overlap = false;
                                if (overlapping.features.length > 0) {
                                    check_overlap = true;
                                }
                                // Add to bounds group contexte analysis
                                if (union.bounds_groups[j]["context_analysis"]["lands"].length === 0 || union.bounds_groups[j]["context_analysis"]["lands"][0]["distance"] <= land_distance) {
                                    union.bounds_groups[j]["context_analysis"]["lands"].push({
                                        data: visibleLandsAndRoads[i],
                                        point: bound_nearest_point,
                                        line: land_nearest_line,
                                        distance: land_distance,
                                        check_line: check_line,
                                        check_overlap: check_overlap
                                    });
                                }
                                else {
                                    union.bounds_groups[j]["context_analysis"]["lands"].unshift({
                                        data: visibleLandsAndRoads[i],
                                        point: bound_nearest_point,
                                        line: land_nearest_line,
                                        distance: land_distance,
                                        check_line: check_line,
                                        check_overlap: check_overlap
                                    });
                                }
                                // POSSIBLE OPTIMISATION = KEEP ONLY SMALLER ONE INSTEAD OF A LIST
                                // }
                            }
                        }
                    }
                }
            }

            // Check if type road
            if (visibleLandsAndRoads[i]["layer"]["id"] === "road-simple") {
                if (!roads_studied_ids.includes(visibleLandsAndRoads[i].id)) {
                    // Add to studied list to check for doubles later
                    roads_studied_ids.push(visibleLandsAndRoads[i].id);
                    // Create the turf line element of road
                    var road_line;
                    if (visibleLandsAndRoads[i]["geometry"]["type"] === "MultiLineString") {
                        road_line = turf.multiLineString(visibleLandsAndRoads[i]["geometry"]["coordinates"]);
                    }
                    else if (visibleLandsAndRoads[i]["geometry"]["type"] === "LineString") {
                        road_line = turf.lineString(visibleLandsAndRoads[i]["geometry"]["coordinates"]);
                    }
                    // Loop th bounds group
                    for (var j = 0; j < union.bounds_groups.length; j++) {
                        // bound_line details with all lines inside
                        var coordinates = [union.bounds[union.bounds_groups[j].bounds[0]]["start_coord_global"]];
                        for (var k = 0; k < union.bounds_groups[j].bounds.length; k++) {
                            coordinates.push(union.bounds[union.bounds_groups[j].bounds[k]]["end_coord_global"]);
                        }
                        var bound_line = turf.lineString(coordinates);
                        // Get middle bound line point
                        var half_bound_line_coords = turf.getCoords(turf.lineSliceAlong(bound_line, 0, turf.length(bound_line) / 2));
                        // Create the turf point element that is on line and nearest from bounds group i
                        var road_nearest_point = turf.nearestPointOnLine(road_line, turf.point(half_bound_line_coords[half_bound_line_coords.length - 1]));
                        // POSSIBLE AMELIORATION : CHECK IF IT IS INSIDE A LAND
                        // Create the turf line element between bounds group and nearest point of road
                        var road_nearest_line = turf.lineString([half_bound_line_coords[half_bound_line_coords.length - 1], road_nearest_point.geometry.coordinates]);
                        // var road_distance = turf.length(road_nearest_line);
                        var road_distance = road_nearest_point.properties.dist;
                        var road_nearest_line_splited = turf.lineChunk(road_nearest_line, 0.0005);
                        var check_line = road_nearest_line;
                        if (road_nearest_line_splited.type === "FeatureCollection") {
                            check_line = road_nearest_line_splited.features[0];
                        }
                        // Add to bounds group contexte analysis
                        if (union.bounds_groups[j]["context_analysis"]["roads"].length === 0 || union.bounds_groups[j]["context_analysis"]["roads"][0]["distance"] <= road_distance) {
                            union.bounds_groups[j]["context_analysis"]["roads"].push({
                                data: visibleLandsAndRoads[i],
                                point: road_nearest_point,
                                line: road_nearest_line,
                                distance: road_distance,
                                check_line: check_line,
                                check_overlap: false
                            });
                        }
                        else {
                            union.bounds_groups[j]["context_analysis"]["roads"].unshift({
                                data: visibleLandsAndRoads[i],
                                point: road_nearest_point,
                                line: road_nearest_line,
                                distance: road_distance,
                                check_line: check_line,
                                check_overlap: false
                            });
                        }
                        // POSSIBLE OPTIMISATION = KEEP ONLY SMALLER ONE INSTEAD OF A LIST
                    }
                }
            }
        }


        // STEP 02 : Verify check lines
        var bounds_groups_roads = [];
        // Loop th bounds groups
        for (var i = 0; i < union.bounds_groups.length; i++) {

            // Check type "separation" (next to another land)
            // Loop th context_analysis.lands
            for (var j = 0; j < union.bounds_groups[i].context_analysis.lands.length; j++) {
                var check = false;
                // Check overlap
                if (union.bounds_groups[i].context_analysis.lands[j].check_overlap === true) {
                    check = true;
                }
                // If no overlap, check check_line
                else {
                    // Create the turf point element of end check line
                    var check_point = turf.point(union.bounds_groups[i].context_analysis.lands[j].check_line.geometry.coordinates[1]);
                    // Create the turf poly element of the current land
                    var check_poly;
                    if (union.bounds_groups[i].context_analysis.lands[j]["data"]["geometry"]["type"] === "MultiPolygon") {
                        check_poly = turf.multiPolygon(union.bounds_groups[i].context_analysis.lands[j]["data"]["geometry"]["coordinates"]);
                    }
                    else {
                        check_poly = turf.polygon(union.bounds_groups[i].context_analysis.lands[j]["data"]["geometry"]["coordinates"]);
                    }
                    // Check if check line ends inside the current land
                    check = turf.booleanPointInPolygon(check_point, check_poly);
                }
                // If check = true => add type
                if (check) {
                    if (union.bounds_groups[i].context_analysis.result.elements.length === 0) {
                        union.bounds_groups[i].context_analysis.result = {
                            type: "separation",
                            subtype: "side",
                            elements: [union.bounds_groups[i].context_analysis.lands[j].data]
                        }
                    }
                    else {
                        // Verify the land is not already in elements list
                        var isAlreadyListed = false;
                        for (var k = 0; k < union.bounds_groups[i].context_analysis.result.elements.length; k++) {
                            if (union.bounds_groups[i].context_analysis.lands[j].data.properties.id === union.bounds_groups[i].context_analysis.result.elements[k].properties.id) {
                                isAlreadyListed = true;
                                break;
                            }
                        }
                        if (!isAlreadyListed) {
                            union.bounds_groups[i].context_analysis.result.elements.push(union.bounds_groups[i].context_analysis.lands[j].data);
                        }
                    }
                }

            }

            // Check type "public"
            // Only if type is still "nc" (for optimisation)
            if (union.bounds_groups[i].context_analysis.result.type === "nc") {
                // Only on nearest road (for optimisation)
                if (union.bounds_groups[i].context_analysis.roads.length > 0) {
                    // Create the turf point element of end check line
                    var check_point = turf.point(union.bounds_groups[i].context_analysis.roads[0].check_line.geometry.coordinates[1]);
                    // Create the turf poly element of the selected lands
                    var check_poly;
                    if (union["geometry"]["geometry"]["type"] === "MultiPolygon") {
                        check_poly = turf.multiPolygon(union["geometry"]["geometry"]["coordinates"]);
                    }
                    else {
                        check_poly = turf.polygon(union["geometry"]["geometry"]["coordinates"]);
                    }
                    // Check if check line ends inside the selected lands
                    var check = turf.booleanPointInPolygon(check_point, check_poly);
                    if (!check) {
                        // Set bounds group result
                        union.bounds_groups[i].context_analysis.result = {
                            type: "way",
                            subtype: "road_public",
                            elements: [union.bounds_groups[i].context_analysis.roads[0].data]
                        }
                        // Add bounds groups to list for further analysis
                        bounds_groups_roads.push({
                            length: union.bounds_groups[i].length,
                            bearing: turf.bearing(union.bounds_groups[i].context_analysis.roads[0].point.geometry, union.center.geometry),
                            road_point: union.bounds_groups[i].context_analysis.roads[0].point.geometry.coordinates,
                            bounds_group_point: union.bounds_groups[i].middle_coord_global,
                            bounds_group_index: i,
                            bounds_group_id: union.bounds_groups[i]._id
                        });
                    }
                }
            }
        }

        // Sort bounds_groups_roads on length
        bounds_groups_roads.sort(function (a, b) {
            return b["length"] - a["length"];
        });

        // Add to union model
        union.roads_limits = bounds_groups_roads;

        // Add calculated values
        var limit_total_length = 0;
        var limit_separation_length = 0;
        var limit_public_length = 0;
        var limit_nc_length = 0;
        for (var i = 0; i < union.bounds_groups.length; i++) {
            limit_total_length += union.bounds_groups[i].length;
            if (union.bounds_groups[i].context_analysis.result.type === "separation") {
                limit_separation_length += union.bounds_groups[i].length;
            }
            else if (union.bounds_groups[i].context_analysis.result.type === "way") {
                limit_public_length += union.bounds_groups[i].length;
            }
            else {
                limit_nc_length += union.bounds_groups[i].length;
            }
        }
        var calculated_values = {
            limit_total_length: limit_total_length,
            limit_separation_length: limit_separation_length,
            limit_public_length: limit_public_length,
            limit_nc_length: limit_nc_length
        }

        // Add bounds_types
        union.bounds_types = {
            separation: {
                id: "separation",
                label: "Limite séparative",
                subtypes: {
                    side: {
                        id: "side",
                        label: "Limite séparative latérale"
                    },
                    back: {
                        id: "back",
                        label: "Limite séparative de fond de parcelle"
                    },
                }
            },
            way: {
                id: "way",
                label: "Limite sur voie",
                subtypes: {
                    road_public: {
                        id: "road_public",
                        label: "Limite sur voie publique"
                    },
                    road_private: {
                        id: "road_private",
                        label: "Limite sur voie privée"
                    },
                    public: {
                        id: "public",
                        label: "Limite sur emprise publique"
                    },
                    train: {
                        id: "train",
                        label: "Limite sur voie de chemin de fer"
                    },
                    water: {
                        id: "water",
                        label: "Limite sur cours d'eau"
                    },
                }
            },
            nc: {
                id: "nc",
                label: "Autre",
                subtypes: {
                    unknown: {
                        id: "unknown",
                        label: "Limite de type indéfini"
                    },
                }
            }
        }
    }


    // Send result
    setCapacity({ ...capacity, landBase: { lands: landsSelected_before, union: union, buildings_osm: visibleBuildings, sections: sections, calculated_values: calculated_values }, toSave: { autoSave: false, action: "", value: "" } });


    // TEST ROADS
    // roadsLines_Show(visibleLandsAndRoads, map, union);
}










// __________ ROADS
// ROADS LINES : Hide
const roadsLines_Hide = (map) => {
    // Remove layer
    if (map.current.getLayer('layerRoadsLines')) {
        map.current.removeLayer('layerRoadsLines');
    }
    // Remove source
    if (map.current.getSource('sourceRoadsLines')) {
        map.current.removeSource('sourceRoadsLines');
    }

    // Remove layer
    if (map.current.getLayer('layerRoadsLinesLink')) {
        map.current.removeLayer('layerRoadsLinesLink');
    }
    // Remove source
    if (map.current.getSource('sourceRoadsLinesLink')) {
        map.current.removeSource('sourceRoadsLinesLink');
    }

    // Remove layer
    if (map.current.getLayer('layerLandsLinesLink')) {
        map.current.removeLayer('layerLandsLinesLink');
    }
    // Remove source
    if (map.current.getSource('sourceLandsLinesLink')) {
        map.current.removeSource('sourceLandsLinesLink');
    }
}

// ROADS LINES : Show
const roadsLines_Show = (visibleLandsAndRoads, map, union) => {

    var i = 2;

    // Remove if exists already
    roadsLines_Hide(map);

    // Loop th query result
    // for (var i = 0; i < visibleLandsAndRoads.length; i++) {
    //     // Check if the result is a road
    //     if (visibleLandsAndRoads[i]["layer"]["id"] === "road-simple") {
    //         roadsLines_Hide(map);

    //         // Add source
    //         map.current.addSource('sourceRoadsLines', {
    //             type: 'geojson',
    //             data: visibleLandsAndRoads[i]
    //         });
    //         // Add layer
    //         map.current.addLayer({
    //             id: 'layerRoadsLines',
    //             type: 'line',
    //             source: 'sourceRoadsLines',
    //             layout: {
    //                 'line-cap': 'round',
    //                 'line-join': 'round'
    //             },
    //             paint: {
    //                 'line-color': '#00FF00',
    //                 'line-width': 2.5,
    //             },
    //             filter: ['in', '$type', 'LineString']
    //         });

    //     }
    // }

    // ROADS LINES ELEMENTS
    var snapped_data = {
        type: 'FeatureCollection',
        features: [],
    }
    for (var j = 0; j < union.bounds_groups[i].context_analysis.roads.length; j++) {
        snapped_data.features.push(union.bounds_groups[i].context_analysis.roads[j].check_line);
    }

    // Add source
    map.current.addSource('sourceRoadsLinesLink', {
        type: 'geojson',
        data: snapped_data
    });
    console.log("ROADS LINES DATA", snapped_data);
    // Add layer
    map.current.addLayer({
        id: 'layerRoadsLinesLink',
        type: 'line',
        source: 'sourceRoadsLinesLink',
        layout: {
            'line-cap': 'round',
            'line-join': 'round'
        },
        paint: {
            'line-color': '#0000FF',
            'line-width': 2,
        },
        filter: ['in', '$type', 'LineString']
    });


    // LANDS LINES ELEMENTS
    var lands_data = {
        type: 'FeatureCollection',
        features: [],
    }
    for (var j = 0; j < union.bounds_groups[i].context_analysis.lands.length; j++) {
        lands_data.features.push(union.bounds_groups[i].context_analysis.lands[j].check_line);
    }
    // lands_data.features = [union.bounds_groups[i].context_analysis.lands[0].line];

    // Add source
    map.current.addSource('sourceLandsLinesLink', {
        type: 'geojson',
        data: lands_data
    });
    console.log("LANDS LINES DATA", lands_data);
    // Add layer
    map.current.addLayer({
        id: 'layerLandsLinesLink',
        type: 'line',
        source: 'sourceLandsLinesLink',
        layout: {
            'line-cap': 'round',
            'line-join': 'round'
        },
        paint: {
            'line-color': '#FF0000',
            'line-width': 2,
        },
        filter: ['in', '$type', 'LineString']
    });

    // map.current.flyTo({
    //     center: union.bounds_groups[i].context_analysis.lands[0].point.geometry.coordinates,
    //     essential: true
    // });
}




export const mercator_to_local = (point, point0, rounding) => {
    // BASIC VERSION
    var deltaX = (turf.distance(turf.point(point), turf.point([point0[0], point[1]])) * 1000);
    if (point[0] < point0[0]) {
        deltaX = -deltaX;
    }

    var deltaY = (turf.distance(turf.point(point), turf.point([point[0], point0[1]])) * 1000);
    if (point[1] < point0[1]) {
        deltaY = -deltaY;
    }

    // ROUNDING
    if (rounding) {
        deltaX = Math.round(deltaX * rounding) / rounding;
        deltaY = Math.round(deltaY * rounding) / rounding;
    }

    return [deltaX, deltaY];

}


export const local_to_mercator = (local_coord, point0, matrix) => {
    // BASIC VERSION
    // // Lat
    // let lat = matrix["lat_min"] + ((local_coord[0] * matrix["lat_delta"]) / matrix["x_max"]);
    // // Lng
    // let lng = matrix["lng_min"] + ((local_coord[1] * matrix["lng_delta"]) / matrix["y_max"]);
    // // Return
    // return [lat, lng];

    // TRANSLATED VERSION
    // Get local point 0
    var point0_Local = mercator_to_local(point0, [matrix["lat_min"], matrix["lng_min"]]);
    // Lat
    var lat = matrix["lat_min"] + ((local_coord[0] * matrix["lat_delta"]) / matrix["x_max"]) + ((point0_Local[0] * matrix["lat_delta"]) / matrix["x_max"]);
    // Lng
    var lng = matrix["lng_min"] + ((local_coord[1] * matrix["lng_delta"]) / matrix["y_max"]) + ((point0_Local[1] * matrix["lng_delta"]) / matrix["y_max"]);
    // Push
    return [lat, lng];
}




// TURF

export const intersect_line_polygon = (line, polygon) => {
    var intersection = {type: "FeatureCollection", features: []};

    // Set line and polygon as multi elemnts
    if (line.geometry.type === "LineString") {
        line = turf.multiLineString([line.geometry.coordinates]);
    }
    if (polygon.geometry.type === "Polygon") {
        polygon = turf.multiPolygon([polygon.geometry.coordinates])
    }
    // console.log("line", line);
    // console.log("polygon", polygon);

    // Loop on each line parts
    line.geometry.coordinates.forEach(line_part => {
        // Loop on each polygon parts
        polygon.geometry.coordinates.forEach(polygon_part => {

            // Split line on polygon intersections
            var split = turf.lineSplit(turf.lineString(line_part), turf.polygon(polygon_part));
            
            // Check if start point is inside polygon
            var oddPair;
            if (turf.booleanPointInPolygon(turf.point(line_part[0]), turf.polygon(polygon_part))){
                oddPair = 0;
            }
            else {
                oddPair = 1;
            }
            // Loop on each splitted parts
            split.features.forEach((split_part, i) => {
                if ((i + oddPair)%2 === 0) {
                    intersection.features.push(split_part);
                }
            });

        })
    });

    return intersection

}