// Import external libs
import * as THREE from 'three';
import * as turf from '@turf/turf';
import { CSG } from 'three-csg-ts';
import * as lists from '../../ruleCatalog_elements/Lists';
import hatch from '../../../../assets/textures/hatch_test.jpg';

import * as helpers from '../../../../helpers/Other_helpers';
import * as map_helpers from '../../map/Map_helpers';



// VERSION WITH BOUNDS OR BOUNDS GROUPS
var detailedLandBase = true;

// __________ OPERATORS
function getGlobalCoordinates(point0, matrix, localCoordinates) {
    // Initiate list
    var globalCoords = [];

    // New point0
    var point0_Local = getLocalCoordinates(point0, [[matrix["lat_min"], matrix["lng_min"]]])[0];

    // Loop th globalCoords
    for (var i = 0; i < localCoordinates.length; i++) {
        // Lat
        var lat = matrix["lat_min"] + ((localCoordinates[i][0] * matrix["lat_delta"]) / matrix["x_max"]) - ((point0_Local[0] * matrix["lat_delta"]) / matrix["x_max"]);
        // Lng
        var lng = matrix["lng_min"] + ((localCoordinates[i][1] * matrix["lng_delta"]) / matrix["y_max"]) - ((point0_Local[1] * matrix["lng_delta"]) / matrix["y_max"]);
        // Push
        globalCoords.push([lat, lng]);
    }

    // Send result
    return globalCoords;
}

function getLocalCoordinates(point0, globalCoordinates) {
    // Initiate list
    var localCoords = [];

    // Loop th globalCoords
    for (var i = 0; i < globalCoordinates.length; i++) {
        var deltaX = (turf.distance(turf.point(globalCoordinates[i]), turf.point([point0[0], globalCoordinates[i][1]])) * 1000);
        if (globalCoordinates[i][0] < point0[0]) {
            deltaX = -deltaX;
        }
        var deltaY = (turf.distance(turf.point(globalCoordinates[i]), turf.point([globalCoordinates[i][0], point0[1]])) * 1000);
        if (globalCoordinates[i][1] < point0[1]) {
            deltaY = -deltaY;
        }
        // Round 4 decimals
        deltaX = Math.round(deltaX * 1000) / 1000;
        deltaY = Math.round(deltaY * 1000) / 1000;

        localCoords.push([deltaX, deltaY]);
    }

    // Send result
    return localCoords;
}

function getShapeFromPoints(pointList, pointListGlobal) {
    // Initiate shape
    var shape = new THREE.Shape();

    // Set base point of shape
    shape.moveTo(pointList[0][0], pointList[0][1]);

    // Set each points of shape
    for (var i = 0; i < pointList.length; i++) {
        shape.lineTo(pointList[i][0], pointList[i][1]);
    }

    // Get turfData
    //console.log("SHAPE", pointListGlobal);
    var turfData = turf.polygon([pointListGlobal]);
    shape.userData = { turfData: turfData };

    // Send result
    return shape;
}

function getScaledShapeHtoV(shape) {
    if (shape === undefined) {
        return undefined;
    }

    var oldPoints = shape.getPoints();

    var newPoints = [];

    oldPoints.forEach(oldPoint => {
        newPoints.push(new THREE.Vector2(-oldPoint.y, oldPoint.x));
    });

    const newShape = new THREE.Shape(newPoints);

    return newShape;
}

function getTranslatedShape(shape, deltaX, delatY) {
    if (shape === undefined) {
        return undefined;
    }

    var oldPoints = shape.getPoints();

    var newPoints = [];

    oldPoints.forEach(oldPoint => {
        newPoints.push(new THREE.Vector2(oldPoint.x + deltaX, oldPoint.y + delatY));
    });

    const newShape = new THREE.Shape(newPoints);

    return newShape;
}

function getExtrusionFromShape(shape, height) {
    // Initiate extrusion settings
    var extrudeSettings = {
        steps: 1,
        depth: height,
        bevelEnabled: false
    };

    // Create geometry
    var geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);

    // Send result
    return geometry;
}

function getExtrusionPathFromShape(shape, path) {
    var nbSteps = 1;
    if (path.points.length > 2) {
        // nbSteps = 100;
        nbSteps = path.points.length * 10;
    }
    console.log("nbSteps", nbSteps);
    // Initiate extrusion settings
    var extrudeSettings = {
        steps: nbSteps,
        extrudePath: path,
        bevelEnabled: false
    };

    // Create geometry
    var geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);

    // Send result
    return geometry;
}


function getEdgesfromGeometry(geometry, color) {
    // Create edges geometry
    var edges_geometry = new THREE.EdgesGeometry(geometry);
    // var edges_geometry = new THREE.WireframeGeometry(geometry);

    // Create edges mesh
    var edges_mesh = new THREE.LineSegments(edges_geometry, new THREE.LineBasicMaterial({ color: color }));

    // Send result
    return edges_mesh;
}


// __________ BBOX MESH
const getBboxMesh = (capacity) => {
    // Get coords
    var globaCoords = capacity.landBase.union.bbox.coordinates[0];
    var localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, globaCoords);

    // Get shape
    var shape = getShapeFromPoints(localCoords, globaCoords);

    // Get geometry
    var geometry = getExtrusionFromShape(shape, 0.1);

    // Create material
    var material = new THREE.MeshPhongMaterial({
        color: 'orange',
        depthWrite: false,
        transparent: true,
        opacity: 0.3,
        // wireframe: true
    });

    // Create mesh
    var mesh = new THREE.Mesh(geometry, material);

    return [mesh];
}

const getBboxMeshStrip = (capacity, stripRatio, color, perimeter) => {
    // Get global coords
    var globalCoords = capacity.landBase.union.bbox.coordinates[0];
    if (perimeter && perimeter.length > 0 && perimeter[0]?.userData?.turfData_union?.geometry) {
        globalCoords = turf.bboxPolygon(turf.bbox(perimeter[0]?.userData?.turfData_union)).geometry.coordinates[0];
    }

    // Scale to get bigger zone
    var polygon = turf.polygon([globalCoords]);
    var scaledPoly = turf.transformScale(polygon, 3);

    // Get local coords
    var localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, turf.getCoords(scaledPoly)[0]);

    // Get coords of strips
    var stripWidth = 4;
    var stripWidth1 = stripWidth * stripRatio;
    if (stripWidth1 < 0.2) { stripWidth1 = 0.2 }
    if (stripWidth1 > stripWidth) { stripWidth1 = stripWidth }
    var stripWidth2 = stripWidth - stripWidth1;
    var x_min = localCoords[0][0];
    var x_max = localCoords[1][0];
    var y_min = localCoords[0][1];
    var y_max = localCoords[2][1];
    var stripNb = Math.floor(2 * (x_max - x_min) / stripWidth) + 4;
    if (stripNb % 2 === 0) { stripNb++ }
    // console.log("STRIP : nbStrip", stripNb);
    var stripCoords = [localCoords[0], localCoords[3]];
    var x = x_min;
    for (var i = 0; i < stripNb; i++) {
        if (i % 2 === 0) {
            x = x + stripWidth1;
            stripCoords.push([x, y_max]);
            stripCoords.push([x, y_min]);
        }
        else {
            x = x + stripWidth2;
            stripCoords.push([x, y_min]);
            stripCoords.push([x, y_max]);
        }
    }
    stripCoords.push(localCoords[0]);
    // console.log("STRIP : stripCoords", stripCoords);

    // Get shape
    var shape = getShapeFromPoints(stripCoords, capacity.landBase.union.geometry.geometry.coordinates[0]); // MAYBE NOT THE GOOD GLOBAL COORDS

    // Get geometry
    var geometry = getExtrusionFromShape(shape, 0.01);

    // Create material
    var material = new THREE.MeshPhongMaterial({
        color: color,
        depthWrite: false,
        transparent: true,
        opacity: 0.3,
        // wireframe: true
    });

    // Create mesh
    var mesh = new THREE.Mesh(geometry, material);
    mesh.rotation.z = Math.PI / 4;

    return [mesh];
}

// __________ LAND MESH
export const getLandMesh = (capacity, height, buffered) => {

    // Get local coords
    var globaCoords = capacity.landBase.union.geometry.geometry.coordinates[0];
    if (detailedLandBase === false) {
        globaCoords = [capacity.landBase.union.bounds_groups[0].start_coord_global];
        capacity.landBase.union.bounds_groups.forEach(bound_group => {
            globaCoords.push(bound_group.end_coord_global);
        })
    }
    var polygon = turf.polygon([globaCoords]);
    if (buffered === true) {
        var buffered = turf.buffer(polygon, -0.00001, { steps: 1 });
        // var buffered = turf.buffer(polygon, 0.001, { steps: 1 });
        // var buffered = turf.transformScale(polygon, 1.1);
        globaCoords = turf.getCoords(buffered)[0];
    }
    var localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, globaCoords);

    // Get shape
    var shape = getShapeFromPoints(localCoords, globaCoords);

    // Get geometry
    var targetHeight = -0.1;
    if (height) {
        targetHeight = height;
    }
    var geometry = getExtrusionFromShape(shape, targetHeight);

    // Create material
    var material = new THREE.MeshPhongMaterial({
        color: 'grey',
        depthWrite: false,
        // transparent: true,
        // opacity: 0.3,
        // wireframe: true
    });
    if (height) {
        material = new THREE.MeshPhongMaterial({
            color: '#062134',
            depthWrite: false,
            transparent: true,
            opacity: 0.6,
            // wireframe: true
        });
    }
    // Create mesh
    var mesh = new THREE.Mesh(geometry, material);

    // Create flat mesh
    var flat_mesh = null;
    if (height !== 0.01) {
        var flat_geometry = getExtrusionFromShape(shape, 0.01);
        flat_mesh = new THREE.Mesh(flat_geometry, material);
    }

    // Add userData
    mesh.userData = {
        turfData: polygon,
        turfData_union: polygon,
        flatMesh: flat_mesh
    }

    // Send result
    return [mesh];
}



// __________ BUILDABLE

export const buildableInterpretor = (capacity) => {
    // Initialize result
    var result = [];

    // Check if parameters exists
    if (capacity?.buildable?.volume?.parameters) {

        // Get full volume
        var bufferedMesh = getLandMesh(capacity, 1000, true)[0];
        var csgMesh = getLandMesh(capacity, capacity?.buildable?.volume?.parameters?.max_height + 0.02, true)[0];
        var isModified = false;

        // Apply rules modifications
        if (capacity?.rules?.ruleset) {
            // Loop th rulesets
            capacity.rules.ruleset.forEach(ruleset => {
                if (ruleset?.items && ruleset.items.length > 0) {
                    // Loop th items of ruleset
                    ruleset.items.forEach(item => {
                        // Check if item is rule
                        if (item?.type === "rule") {
                            // Get rule model
                            if (item?.data?.composition?.nodes[0]?.type === "Node_Rule_Buildable_Volume") {
                                console.log("ADD VOLUME");
                                // Check if rule has objects
                                if (item?.interpreted && item.interpreted.length > 0) {
                                    // Loop th rules objects
                                    item.interpreted.forEach(object => {
                                        if (object?.type === "Mesh") {
                                            // Get objectMesh
                                            var objectMesh = object;
                                            // Update meshes
                                            bufferedMesh.updateMatrix();
                                            csgMesh.updateMatrix();
                                            objectMesh.updateMatrix();
                                            // Perform csg operation
                                            objectMesh = CSG.intersect(objectMesh, bufferedMesh);
                                            csgMesh = CSG.union(csgMesh, objectMesh);
                                            isModified = true;
                                        }
                                    })

                                }
                            }
                            else if (item?.data?.composition?.nodes[0]?.type === "Node_Rule_Unbuildable_Volume") {
                                console.log("REMOVE VOLUME");
                                // Check if rule has objects
                                if (item?.interpreted && item.interpreted.length > 0) {
                                    console.log(item);
                                    // Loop th rules objects
                                    item.interpreted.forEach(object => {
                                        if (object?.type === "Mesh") {
                                            console.log("Removed");
                                            // Get objectMesh
                                            var objectMesh = object;
                                            // Update meshes
                                            csgMesh.updateMatrix();
                                            objectMesh.updateMatrix();
                                            // Perform csg operation
                                            if (objectMesh.geometry.type === "LatheGeometry") {
                                                csgMesh = CSG.intersect(csgMesh, objectMesh);
                                            }
                                            else {
                                                csgMesh = CSG.subtract(csgMesh, objectMesh);
                                            }
                                            isModified = true;
                                            // BVH VERSION
                                            // const csgEvaluator = new Evaluator();
                                            // const brush1 = new Brush(csgMesh.geometry);
                                            // const brush2 = new Brush(objectMesh.geometry);
                                            // const result = csgEvaluator.evaluate(brush1, brush2, SUBTRACTION);
                                            // console.log("result", result);
                                            // csgMesh = result;
                                        }
                                    })
                                }
                            }
                            else if (item?.interpreted && item?.interpreted.length > 0 && (item?.data?.composition?.nodes[0]?.type === "Node_Rule_Unbuildable_Area" && item?.interpreted[0]?.userData?.unbuildableArea >= item?.interpreted[0]?.userData?.availableArea) || (item?.data?.composition?.nodes[0]?.type === "Node_Rule_Buildable_Area" && item?.interpreted[0]?.userData?.buildableArea <= 0)) {
                                console.log("REMOVE AREA");

                                // Create zone mesh list
                                var mesh_list = [];

                                // Create global coords list if multipolygon
                                var coords_global_list = [];
                                if (item?.interpreted[0]?.userData?.turfData?.geometry?.type === "Polygon") {
                                    coords_global_list.push(item?.interpreted[0]?.userData?.turfData?.geometry?.coordinates);
                                }
                                else if (item?.interpreted[0]?.userData?.turfData?.geometry?.type === "MultiPolygon") {
                                    coords_global_list = item?.interpreted[0]?.userData?.turfData?.geometry?.coordinates;
                                }
                                coords_global_list.forEach(coords_global => {
                                    // Get local coords
                                    var localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, coords_global[0]);
                                    // Get shape
                                    var shape = getShapeFromPoints(localCoords, coords_global[0]);
                                    // Get geometry
                                    var geometry = getExtrusionFromShape(shape, 100);
                                    // Create mesh
                                    var mesh = new THREE.Mesh(geometry, new THREE.MeshPhongMaterial({ color: 'grey', }));
                                    mesh_list.push(mesh);
                                })

                                // Loop th rules objects
                                mesh_list.forEach(object => {
                                    if (object?.type === "Mesh") {
                                        console.log("Removed");
                                        // Get objectMesh
                                        var objectMesh = object;
                                        // Update meshes
                                        csgMesh.updateMatrix();
                                        objectMesh.updateMatrix();
                                        // Perform csg operation
                                        csgMesh = CSG.subtract(csgMesh, objectMesh);
                                        isModified = true;
                                        // BVH VERSION
                                        // const csgEvaluator = new Evaluator();
                                        // const brush1 = new Brush(csgMesh.geometry);
                                        // const brush2 = new Brush(objectMesh.geometry);
                                        // const result = csgEvaluator.evaluate(brush1, brush2, SUBTRACTION);
                                        // console.log("result", result);
                                        // csgMesh = result;
                                    }
                                })
                            }

                        }
                    })
                }
            })
            // Apply other restrictions
            if (capacity?.buildable?.volume?.parameters?.max_height_building_zones && capacity?.buildable?.volume?.parameters?.max_height_building_zones.length > 0) {
                capacity.buildable.volume.parameters.max_height_building_zones.forEach(height_zone => {
                    // Check if zone is lower than max height
                    if (height_zone.height < capacity.buildable.volume.parameters.max_height) {
                        console.log("REMOVE VOLUME HEIGHT", height_zone);

                        // Create zone mesh list
                        var mesh_list = [];

                        if (height_zone?.perimeter?.geometry?.type === "Polygon") {

                            // Get local coords
                            var localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, height_zone.perimeter.geometry.coordinates[0]);
                            // Get shape
                            var shape = getShapeFromPoints(localCoords, height_zone.perimeter.geometry.coordinates[0]);
                            // Get geometry
                            var geometry = getExtrusionFromShape(shape, 100);
                            // Create mesh
                            var mesh = new THREE.Mesh(geometry, new THREE.MeshPhongMaterial({ color: 'grey', }));
                            // Create holes
                            if (height_zone.perimeter.geometry.coordinates.length > 1) {
                                for (var hole_i = 1; hole_i < height_zone.perimeter.geometry.coordinates.length; hole_i++) {
                                    // Get local coords
                                    var hole_localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, height_zone.perimeter.geometry.coordinates[hole_i]);
                                    // Get shape
                                    var hole_shape = getShapeFromPoints(hole_localCoords, height_zone.perimeter.geometry.coordinates[hole_i]);
                                    // Get geometry
                                    var hole_geometry = getExtrusionFromShape(hole_shape, 100);
                                    // Create mesh
                                    var hole_mesh = new THREE.Mesh(hole_geometry, new THREE.MeshPhongMaterial({ color: 'grey', }));
                                    // Update meshes
                                    mesh.updateMatrix();
                                    hole_mesh.updateMatrix();
                                    // Perform csg operation
                                    mesh = CSG.subtract(mesh, hole_mesh);
                                }
                            }
                            // Translate mesh in Z axis
                            mesh.translateZ(height_zone.height);
                            // Add mesh to list
                            mesh_list.push(mesh);
                        }

                        else if (height_zone?.perimeter?.geometry?.type === "MultiPolygon") {

                            for (var i = 0; i < height_zone.perimeter.geometry.coordinates.length; i++) {
                                // Get local coords
                                var localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, height_zone.perimeter.geometry.coordinates[i][0]);
                                // Get shape
                                var shape = getShapeFromPoints(localCoords, height_zone.perimeter.geometry.coordinates[i][0]);
                                // Get geometry
                                var geometry = getExtrusionFromShape(shape, 100);
                                // Create mesh
                                var mesh = new THREE.Mesh(geometry, new THREE.MeshPhongMaterial({ color: 'grey', }));
                                // Create holes
                                if (height_zone.perimeter.geometry.coordinates[i].length > 1) {
                                    for (var hole_i = 1; hole_i < height_zone.perimeter.geometry.coordinates[i].length; hole_i++) {
                                        // Get local coords
                                        var hole_localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, height_zone.perimeter.geometry.coordinates[i][hole_i]);
                                        // Get shape
                                        var hole_shape = getShapeFromPoints(hole_localCoords, height_zone.perimeter.geometry.coordinates[i][hole_i]);
                                        // Get geometry
                                        var hole_geometry = getExtrusionFromShape(hole_shape, 100);
                                        // Create mesh
                                        var hole_mesh = new THREE.Mesh(hole_geometry, new THREE.MeshPhongMaterial({ color: 'grey', }));
                                        // Update meshes
                                        mesh.updateMatrix();
                                        hole_mesh.updateMatrix();
                                        // Perform csg operation
                                        mesh = CSG.subtract(mesh, hole_mesh);
                                    }
                                }
                                // Translate mesh in Z axis
                                mesh.translateZ(height_zone.height);
                                // Add mesh to list
                                mesh_list.push(mesh);
                            }

                        }

                        console.log("mesh_list", mesh_list);


                        // Loop th rules objects
                        mesh_list.forEach(object => {
                            if (object?.type === "Mesh") {
                                console.log("Removed");
                                // Get objectMesh
                                var objectMesh = object;
                                // Update meshes
                                csgMesh.updateMatrix();
                                objectMesh.updateMatrix();
                                // Perform csg operation
                                csgMesh = CSG.subtract(csgMesh, objectMesh);
                                isModified = true;
                                // BVH VERSION
                                // const csgEvaluator = new Evaluator();
                                // const brush1 = new Brush(csgMesh.geometry);
                                // const brush2 = new Brush(objectMesh.geometry);
                                // const result = csgEvaluator.evaluate(brush1, brush2, SUBTRACTION);
                                // console.log("result", result);
                                // csgMesh = result;
                            }
                        })
                    }
                });
            }
        }

        // Intersect with externalMesh
        // var externalMesh = getLandMesh(capacity, 100, false)[0];
        // csgMesh.updateMatrix();
        // externalMesh.updateMatrix();
        // csgMesh = CSG.intersect(csgMesh, externalMesh);




        result = [csgMesh];

        // var edges_geometry = new THREE.WireframeGeometry(csgMesh.geometry);
        // var wireframe = new THREE.LineSegments(edges_geometry, new THREE.LineBasicMaterial({ color: "#FF0000" }));
        // result.push(wireframe);


        // Get edges
        var edges = getEdgesfromGeometry(csgMesh.geometry, '#062134');
        result.push(edges);

        //     // Remove lines
        //     var resetVertices = [];
        //     var lines = [];
        //     var pointIndexes = {};
        //     var count = 0;
        //     var lineCount = 0;
        //     for (var i = 0; i < edges?.geometry?.attributes?.position?.array.length; i += 3) {
        //         // Create point indexs list
        //         var pointString = [edges.geometry.attributes.position.array[i], edges.geometry.attributes.position.array[i + 1], edges.geometry.attributes.position.array[i + 2]].join("|");
        //         if (!(pointString in pointIndexes)) {
        //             pointIndexes[pointString] = [lineCount];
        //         }
        //         else {
        //             pointIndexes[pointString].push(lineCount);
        //         }
        //         // Create line list
        //         if (count % 2 !== 0) {
        //             // allLines.push([edges.geometry.attributes.position.array[i - 3], edges.geometry.attributes.position.array[i - 2], edges.geometry.attributes.position.array[i - 1], edges.geometry.attributes.position.array[i], edges.geometry.attributes.position.array[i + 1], edges.geometry.attributes.position.array[i + 2]].join("|"));
        //             var lineString = [edges.geometry.attributes.position.array[i - 3], edges.geometry.attributes.position.array[i - 2], edges.geometry.attributes.position.array[i - 1], edges.geometry.attributes.position.array[i], edges.geometry.attributes.position.array[i + 1], edges.geometry.attributes.position.array[i + 2]].join("|");
        //             var line = turf.lineString(getGlobalCoordinates(capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix, [[edges.geometry.attributes.position.array[i - 3], edges.geometry.attributes.position.array[i - 2]], [edges.geometry.attributes.position.array[i], edges.geometry.attributes.position.array[i + 1]]]));
        //             if (edges.geometry.attributes.position.array[i - 1] !== edges.geometry.attributes.position.array[i + 2]) {
        //                 line = turf.lineString(getGlobalCoordinates(capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix, [[edges.geometry.attributes.position.array[i - 2], edges.geometry.attributes.position.array[i - 1]], [edges.geometry.attributes.position.array[i + 1], edges.geometry.attributes.position.array[i + 2]]]));
        //             }
        //             lines.push({
        //                 string: lineString,
        //                 point0: [edges.geometry.attributes.position.array[i - 3], edges.geometry.attributes.position.array[i - 2], edges.geometry.attributes.position.array[i - 1]],
        //                 point1: [edges.geometry.attributes.position.array[i], edges.geometry.attributes.position.array[i + 1], edges.geometry.attributes.position.array[i + 2]],
        //                 line: line,
        //             });
        //             lineCount++;
        //         }
        //         count++;
        //     }

        //     // console.log("keepPoints", keepPoints);
        //     // console.log("removePoints", removePoints);
        //     // console.log("testPoints", testPoints);
        //     // console.log("removeLines", removeLines);
        //     console.log("lines", lines);
        //     console.log("pointIndexes", pointIndexes);
        //     // console.log("nbpoint", Object.keys(pointIndexes).length);


        //     // Loop th points and get the lines to remove
        //     var linesToRemove = [];
        //     var keys = Object.keys(pointIndexes);
        //     for (var i = 0; i < keys.length; i++) {
        //         // If only one index => to delete because not an edge
        //         if (pointIndexes[keys[i]].length === 1) {
        //             console.log("TO DELETE - because only one index");
        //             linesToRemove.push(pointIndexes[keys[i]][0]);
        //         }
        //         else {
        //             // if (keys[i] === "-3.228505849838257|-13.00516128540039|0") {
        //             // if (keys[i] === "-2.1070001125335693|1.2269999980926514|9.920000076293945") {
        //             var nbTest = 0;
        //             for (var j = 1; j < pointIndexes[keys[i]].length; j++) {
        //                 nbTest = nbTest + (pointIndexes[keys[i]].length - j);
        //             }
        //             // Make the tests
        //             var first = 0;
        //             var last = 1;
        //             for (var j = 0; j < nbTest; j++) {
        //                 // Check overlapping
        //                 var overlapping = turf.lineOverlap(lines[pointIndexes[keys[i]][first]].line, lines[pointIndexes[keys[i]][last]].line, { tolerance: 0.00001 });
        //                 if (overlapping.features.length > 0) {
        //                     if (turf.length(lines[pointIndexes[keys[i]][first]].line) < turf.length(lines[pointIndexes[keys[i]][last]].line)) {
        //                         if (!(linesToRemove.includes(pointIndexes[keys[i]][first]))) {
        //                             linesToRemove.push(pointIndexes[keys[i]][first]);
        //                         }
        //                     }
        //                     else {
        //                         if (!(linesToRemove.includes(pointIndexes[keys[i]][last]))) {
        //                             linesToRemove.push(pointIndexes[keys[i]][last]);
        //                         }
        //                     }
        //                     console.log("TO DELETE - because overlapping");
        //                 }
        //                 // Next test
        //                 if (last + 1 === pointIndexes[keys[i]].length) {
        //                     first++;
        //                     last = first + 1;
        //                 }
        //                 else {
        //                     last++;
        //                 }
        //             }
        //             // }
        //         }

        //     }
        //     console.log("linesToRemove", linesToRemove);

        //     // Get point to Keep
        //     var pointsToKeep = [];
        //     for (var i = 0; i < lines.length; i++) {
        //         if (!(linesToRemove.includes(i))) {
        //             pointsToKeep = pointsToKeep.concat(lines[i].string.split("|"));
        //         }
        //         else {
        //             pointsToKeep = pointsToKeep.concat([0, 0, 0, 0, 0, 0]);
        //         }
        //     }

        //     var pointIndexes2 = {};
        //     var count = 0;
        //     var lineCount = 0;
        //     for (var i = 0; i < pointsToKeep.length; i += 3) {
        //         // Create point indexs list
        //         var pointString = [pointsToKeep[i], pointsToKeep[i + 1], pointsToKeep[i + 2]].join("|");
        //         if (!(pointString in pointIndexes2)) {
        //             pointIndexes2[pointString] = [lineCount];
        //         }
        //         else {
        //             pointIndexes2[pointString].push(lineCount);
        //         }
        //         // Create line list
        //         if (count % 2 !== 0) {
        //             lineCount++;
        //         }
        //         count++;
        //     }
        //     console.log("pointIndexes2", pointIndexes2);

        //     var linesToRemove2 = [];
        //     var keys = Object.keys(pointIndexes2);
        //     for (var i = 0; i < keys.length; i++) {
        //         // If only one index => to delete because not an edge
        //         if (pointIndexes2[keys[i]].length === 1) {
        //             console.log("TO DELETE - because only one index");
        //             if (!(linesToRemove2.includes(pointIndexes2[keys[i]][0]))) {
        //                 linesToRemove2.push(pointIndexes2[keys[i]][0]);
        //             }
        //         }
        //         else {
        //             // if (keys[i] === "-3.228505849838257|-13.00516128540039|0") {
        //             // if (keys[i] === "-2.1070001125335693|1.2269999980926514|9.920000076293945") {
        //             // var nbTest = 0;
        //             // for (var j = 1; j < pointIndexes2[keys[i]].length; j++) {
        //             //     nbTest = nbTest + (pointIndexes2[keys[i]].length - j);
        //             // }
        //             // // Make the tests
        //             // var first = 0;
        //             // var last = 1;
        //             // for (var j = 0; j < nbTest; j++) {
        //             //     // Check overlapping
        //             //     var overlapping = turf.lineOverlap(lines[pointIndexes2[keys[i]][first]].line, lines[pointIndexes2[keys[i]][last]].line, { tolerance: 0.00001 });
        //             //     if (overlapping.features.length > 0) {
        //             //         if (turf.length(lines[pointIndexes2[keys[i]][first]].line) < turf.length(lines[pointIndexes2[keys[i]][last]].line)) {
        //             //             if (!(linesToRemove2.includes(pointIndexes2[keys[i]][first]))) {
        //             //                 linesToRemove2.push(pointIndexes2[keys[i]][first]);
        //             //             }
        //             //         }
        //             //         else {
        //             //             if (!(linesToRemove2.includes(pointIndexes2[keys[i]][last]))) {
        //             //                 linesToRemove2.push(pointIndexes2[keys[i]][last]);
        //             //             }
        //             //         }
        //             //         console.log("TO DELETE - because overlapping");
        //             //     }
        //             //     // Next test
        //             //     if (last + 1 === pointIndexes2[keys[i]].length) {
        //             //         first++;
        //             //         last = first + 1;
        //             //     }
        //             //     else {
        //             //         last++;
        //             //     }
        //             // }
        //             // }
        //         }
        //     }
        //     console.log("linesToRemove2", linesToRemove2);
        //     linesToRemove = linesToRemove.concat(linesToRemove2);
        //     console.log("linesToRemove updated", linesToRemove);


        //     // Get point to Keep
        //     var pointsToKeep2 = [];
        //     for (var i = 0; i < lines.length; i++) {
        //         if (!(linesToRemove.includes(i))) {
        //             pointsToKeep2 = pointsToKeep2.concat(lines[i].string.split("|"));
        //         }
        //         else {
        //             pointsToKeep2 = pointsToKeep2.concat([0, 0, 0, 0, 0, 0]);
        //         }
        //     }


        //     var pointIndexes3 = {};
        //     var count = 0;
        //     var lineCount = 0;
        //     for (var i = 0; i < pointsToKeep2.length; i += 3) {
        //         // Create point indexs list
        //         var pointString = [pointsToKeep2[i], pointsToKeep2[i + 1], pointsToKeep2[i + 2]].join("|");
        //         if (!(pointString in pointIndexes3)) {
        //             pointIndexes3[pointString] = [lineCount];
        //         }
        //         else {
        //             pointIndexes3[pointString].push(lineCount);
        //         }
        //         // Create line list
        //         if (count % 2 !== 0) {
        //             lineCount++;
        //         }
        //         count++;
        //     }
        //     console.log("pointIndexes3", pointIndexes3);

        //     var linesToRemove3 = [];
        //     var keys = Object.keys(pointIndexes3);
        //     for (var i = 0; i < keys.length; i++) {
        //         // If only one index => to delete because not an edge
        //         if (pointIndexes3[keys[i]].length === 1) {
        //             console.log("TO DELETE - because only one index");
        //             if (!(linesToRemove3.includes(pointIndexes3[keys[i]][0]))) {
        //                 linesToRemove3.push(pointIndexes3[keys[i]][0]);
        //             }
        //         }
        //         else {
        //             // if (keys[i] === "-3.228505849838257|-13.00516128540039|0") {
        //             // if (keys[i] === "-2.1070001125335693|1.2269999980926514|9.920000076293945") {
        //             // var nbTest = 0;
        //             // for (var j = 1; j < pointIndexes2[keys[i]].length; j++) {
        //             //     nbTest = nbTest + (pointIndexes2[keys[i]].length - j);
        //             // }
        //             // // Make the tests
        //             // var first = 0;
        //             // var last = 1;
        //             // for (var j = 0; j < nbTest; j++) {
        //             //     // Check overlapping
        //             //     var overlapping = turf.lineOverlap(lines[pointIndexes2[keys[i]][first]].line, lines[pointIndexes2[keys[i]][last]].line, { tolerance: 0.00001 });
        //             //     if (overlapping.features.length > 0) {
        //             //         if (turf.length(lines[pointIndexes2[keys[i]][first]].line) < turf.length(lines[pointIndexes2[keys[i]][last]].line)) {
        //             //             if (!(linesToRemove2.includes(pointIndexes2[keys[i]][first]))) {
        //             //                 linesToRemove2.push(pointIndexes2[keys[i]][first]);
        //             //             }
        //             //         }
        //             //         else {
        //             //             if (!(linesToRemove2.includes(pointIndexes2[keys[i]][last]))) {
        //             //                 linesToRemove2.push(pointIndexes2[keys[i]][last]);
        //             //             }
        //             //         }
        //             //         console.log("TO DELETE - because overlapping");
        //             //     }
        //             //     // Next test
        //             //     if (last + 1 === pointIndexes2[keys[i]].length) {
        //             //         first++;
        //             //         last = first + 1;
        //             //     }
        //             //     else {
        //             //         last++;
        //             //     }
        //             // }
        //             // }
        //         }
        //     }
        //     console.log("linesToRemove3", linesToRemove3);
        //     linesToRemove = linesToRemove.concat(linesToRemove3);
        //     console.log("linesToRemove updated", linesToRemove);

        //     // Get point to Keep
        //     var pointsToKeep3 = [];
        //     for (var i = 0; i < lines.length; i++) {
        //         if (!(linesToRemove.includes(i))) {
        //             pointsToKeep3 = pointsToKeep3.concat(lines[i].string.split("|"));
        //         }
        //         else {
        //             pointsToKeep3 = pointsToKeep3.concat([0, 0, 0, 0, 0, 0]);
        //         }
        //     }

        //     console.log("pointsToKeep3", pointsToKeep3);







        //     // var keepLines = [];
        //     // keepPoints = [];
        //     // for (var i = 0; i < allLines.length; i++) {
        //     //     if (!removeLines.includes(allLines[i])) {
        //     //         keepLines.push(allLines[i]);
        //     //         keepPoints = keepPoints.concat(allLines[i].split("|"));
        //     //     }
        //     // }

        //     // var oncePoints = [];
        //     // for (var i = 0; i < keepPoints.length; i++) {
        //     //     if (!removePoints.includes(keepPoints[i])) {
        //     //         oncePoints.push(keepPoints[i]);
        //     //     }
        //     // }
        //     // console.log("oncePoints", oncePoints);

        //     // var pBuff = new THREE.ArrayBuffer(keepPoints.length * 3 * 4);
        //     // var positions = new THREE.Float32BufferAttribute(pBuff);
        //     // var delta = 0;
        //     // for (var i = 0; i < keepPoints.length; i++) {
        //     //     var list = keepPoints[i].split("|");
        //     //     positions[i + delta] = list[0];
        //     //     positions[i + delta + 1] = list[1];
        //     //     positions[i + delta + 2] = list[2];
        //     //     delta += 2;
        //     // }
        //     // console.log("positions", positions);

        //     // var final = [];
        //     // for (var i = 0; i < keepPairs.length; i++) {
        //     //     var list = keepPairs[i].split("|");
        //     //     var point0 = [list[0], list[1], list[2]].join("|");
        //     //     var point1 = [list[3], list[4], list[5]].join("|");
        //     //     if (!oncePoints.includes(point0) && !oncePoints.includes(point1)) {
        //     //         final = final.concat(list);
        //     //     }
        //     //     if (i === 0) {
        //     //         console.log("point0", point0);
        //     //         console.log("point1", point1);
        //     //     }
        //     // }
        //     // console.log("final", final);


        //     // var edges2 = getEdgesfromGeometry(csgMesh.geometry, '#FF0000');
        //     // edges2.geometry.attributes.position.set(resetVertices);
        //     // edges2.geometry.attributes.position.set(testPoints);
        //     // result.push(edges2);

        //     var edges3 = getEdgesfromGeometry(csgMesh.geometry, '#FF0000');
        //     edges3.geometry.attributes.position.set(resetVertices);
        //     edges3.geometry.attributes.position.set(pointsToKeep);
        //     result.push(edges3);

    }




    // console.log("GET BUIDABLE");
    return result;
}







// ___________ NODES

export const nodeInterpretorVariables = (node, capacity, nodePerimeter) => {
    //console.log("node from variables", node);

    var i = 0;
    var nodeVariables = [];

    // Get indexes of user input nodes
    var str = node[i].value;
    var searchStr = "Node_UserInput_";
    var searchStrLen = searchStr.length;
    var startIndex = 0, index, indices = [];
    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
        indices.push(index);
        startIndex = index + searchStrLen;
    }
    //console.log("indices", indices);

    // Get node data for each indice
    indices.forEach(indice => {
        // Set substring of node user input
        var substr = node[i].value.substring(indice, node[i].value.length);
        // Find start and end of it
        var nbOpen = 0;
        var nbClose = 0;
        var start_Index = 0;
        var end_Index = 0;
        for (var j = 0; j < substr.length; j++) {
            // //console.log(j + " = " + substr[j]);
            if (substr[j] === "[") {
                nbOpen++;
                if (nbOpen === 1) {
                    start_Index = j;
                }
            }
            else if (substr[j] === "]") {
                nbClose++;
                if (nbOpen > 0 && nbOpen === nbClose) {
                    end_Index = j;
                    break;
                }
            }
        }
        // Get only the node user input
        var nodeIsolated = substr.substring(0, end_Index + 1);
        // Get node data
        var separation = nodeIsolated.indexOf("[");
        var delta = 0;
        if (separation > -1) { delta = 1 }
        const nodeData0 = getNodeDataList(nodeIsolated.substring(separation + 1, nodeIsolated.length - delta));
        var nodeData = [];
        for (var j = 0; j < nodeData0.length; j++) {
            nodeData.push({ value: nodeData0[j] });
        }
        //console.log("nodeData", nodeData);
        var variableData = [];
        for (var j = 0; j < nodeData.length; j++) {
            // Exception for node not to interpret
            if (nodeData[j].value.indexOf("Node_Study_Bounds[") >= 0 || nodeData[j].value.indexOf("Node_Study_Lands[") >= 0 || nodeData[j].value.indexOf("Node_Study_PLUZone[") >= 0 || nodeData[j].value.indexOf("Node_Study_Levels[") >= 0 || nodeData[j].value.indexOf("Node_Study_Existing_Buildings[") >= 0) {
                variableData.push((nodeData[j].value).substring((nodeData[j].value).indexOf("[") + 1, (nodeData[j].value).length - 1));
            }
            else if (nodeData[j].value.indexOf("Node_2DElement_Point[") >= 0 || nodeData[j].value.indexOf("Node_2DElement_Line[") >= 0 || nodeData[j].value.indexOf("Node_2DElement_Polygon[") >= 0) {
                variableData.push([]);
            }
            else {
                variableData.push(nodeInterpretor([nodeData[j]], capacity, nodePerimeter));
            }
        }
        var variableType = nodeData[1].value.substring(0, nodeData[1].value.indexOf("["));
        // Get searchString
        //console.log("nodeIsolated", nodeIsolated);
        var lengthToSubstract = 0;
        for (var j = 1; j < nodeData.length; j++) {
            if (j === 1) {
                lengthToSubstract += nodeData[j].value.length - variableType.length;
            }
            else {
                lengthToSubstract += nodeData[j].value.length + 2;
            }
        }
        // Create variable model
        var variable = {
            type: nodeIsolated.substring(0, nodeIsolated.indexOf("[")),
            label: variableData[0],
            value: variableData[1],
            valueDefault: variableData[1],
            data: variableData,
            // searchString: nodeIsolated.substring(0, nodeIsolated.length - (nodeData[1].value.length - variableType.length))
            searchString: nodeIsolated.substring(0, nodeIsolated.length - lengthToSubstract)
        }
        // Push to list
        nodeVariables.push(variable);
    })

    //console.log("nodeVariables", nodeVariables);
    return nodeVariables;
}

export const nodeInterpretorAcceptance = (node, capacity) => {
    //console.log("node from acceptance", node);

    var i = 0;

    // Get node type
    var separation = node[i].value.indexOf("[");
    const nodeType = node[i].value.substring(0, separation);

    // Get node list
    const block_list = lists.get_blockList();

    // Find matching node type
    var block_list_categories = Object.keys(block_list);
    var acceptance = null;
    for (var k = 0; k < block_list_categories.length; k++) {
        if (acceptance === null) {
            for (var j = 0; j < block_list[block_list_categories[k]].items.length; j++) {
                if (block_list[block_list_categories[k]].items[j].node_type === nodeType) {
                    acceptance = block_list[block_list_categories[k]].items[j].data.source[0].acceptance;
                    break;
                }
            }
        }
    }

    // If acceptance = "tous types" => find the real type
    if (acceptance === "tous types") {
        //console.log("Acceptance all types !", node);
        if (nodeType === "Node_Operator_Condition") {
            // Get nodeData
            var delta = 0;
            if (separation > -1) { delta = 1 }
            const nodeData0 = getNodeDataList(node[i].value.substring(separation + 1, node[i].value.length - delta));
            var nodeData = [];
            for (var j = 0; j < nodeData0.length; j++) {
                nodeData.push({ value: nodeData0[j] });
            }
            //console.log("nodeData", nodeData);

            // Get new node
            var nodeNew = null;
            if (nodeInterpretor([nodeData[0]], capacity) === true) {
                nodeNew = nodeData[1];
            }
            else {
                nodeNew = nodeData[2];
            }

            // Get acceptance
            acceptance = nodeInterpretorAcceptance([nodeNew], capacity);

        }
    }

    return acceptance;
}

export const nodeInterpretorRuleParams = (node, capacity) => {
    //console.log("node from rule params", node);

    var i = 0;

    var result = null;

    if (node[i].value === null) {
        return result
    }

    // Get nodeData
    var separation = node[i].value.indexOf("[");
    const nodeType = node[i].value.substring(0, separation);
    var delta = 0;
    if (separation > -1) { delta = 1 }
    const nodeData0 = getNodeDataList(node[i].value.substring(separation + 1, node[i].value.length - delta));
    var nodeData = [];
    for (var j = 0; j < nodeData0.length; j++) {
        nodeData.push({ value: nodeData0[j] });
    }

    //console.log("nodeType", nodeType);
    //console.log("nodeData", nodeData);

    var result = {
        condition: {
            value: false,
            data: null
        },
        perimeter: {
            value: false,
            data: null
        }
    }
    if (nodeType.indexOf("Node_Rule_") >= 0) {
        result.condition.value = nodeInterpretor([nodeData[0]], capacity);
        result.perimeter.value = nodeInterpretor([nodeData[1]], capacity);
    }

    return result;
}

export const nodeInterpretor = (node, capacity, perimeter) => {
    //console.log("node", node);

    try {

        var i = 0;

        var result = null;

        if (node[i].value === null) {
            return result
        }

        // Get nodeType & nodeData
        var separation = node[i].value.indexOf("[");
        const nodeType = node[i].value.substring(0, separation);
        var delta = 0;
        if (separation > -1) { delta = 1 }
        const nodeData0 = getNodeDataList(node[i].value.substring(separation + 1, node[i].value.length - delta));
        var nodeData = [];
        for (var j = 0; j < nodeData0.length; j++) {
            nodeData.push({ value: nodeData0[j] });
        }

        //console.log("nodeType", nodeType);
        //console.log("nodeData", nodeData);


        if (nodeType === "Node_3DOperator_Extrusion") {
            result = nodeInterpreted_Node_3DOperator_Extrusion(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_3DOperator_ExtrusionPath") {
            result = nodeInterpreted_Node_3DOperator_ExtrusionPath(nodeData, capacity, perimeter);
        }
        // RULES
        else if (nodeType === "Node_Rule_Unbuildable_Volume") {
            result = nodeInterpreted_Node_Rule_Unbuildable_Volume(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Rule_Buildable_Volume") {
            result = nodeInterpreted_Node_Rule_Buildable_Volume(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Rule_Buildable_Area") {
            result = nodeInterpreted_Node_Rule_Buildable_Area(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Rule_Buildable_Area_Total") {
            result = nodeInterpreted_Node_Rule_Buildable_Area_Total(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Rule_Unbuildable_Area") {
            result = nodeInterpreted_Node_Rule_Unbuildable_Area(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Rule_Building_Height") {
            result = nodeInterpreted_Node_Rule_Building_Height(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Rule_Facade_Height") {
            result = nodeInterpreted_Node_Rule_Facade_Height(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Rule_Level_Height") {
            result = nodeInterpreted_Node_Rule_Level_Height(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Rule_Level_Nb") {
            result = nodeInterpreted_Node_Rule_Level_Nb(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Rule_Building_Offset") {
            result = nodeInterpreted_Node_Rule_Building_Offset(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Rule_Roof_Angle") {
            result = nodeInterpreted_Node_Rule_Roof_Angle(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Rule_BoundAlign") {
            result = nodeInterpreted_Node_Rule_BoundAlign(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Rule_Parking_Area_Total") {
            result = nodeInterpreted_Node_Rule_Parking_Area_Total(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Rule_Parking_Type") {
            result = nodeInterpreted_Node_Rule_Parking_Type(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Rule_Level_Offset") {
            result = nodeInterpreted_Node_Rule_Level_Offset(nodeData, capacity, perimeter);
        }
        // CONDITIONS
        else if (nodeType === "Node_Condition") {
            result = nodeInterpreted_Node_Condition(nodeData, capacity, perimeter);
        }
        // PERIMETERS
        else if (nodeType === "Node_Perimeter") {
            result = nodeInterpreted_Node_Perimeter(nodeData, capacity, false, perimeter);
        }
        // NODES
        else if (nodeType === "Node_3DOperator_Watch") {
            result = nodeInterpreted_Node_3DOperator_Watch(nodeData, capacity, perimeter);
        }
        // USER INPUT
        else if (nodeType === "Node_UserInput_Number") {
            result = nodeInterpreted_Node_UserInput_Number(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_UserInput_Number_Range") {
            result = nodeInterpreted_Node_UserInput_Number(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_UserInput_Boolean") {
            result = nodeInterpreted_Node_UserInput_Boolean(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_UserInput_Bounds") {
            result = nodeInterpreted_Node_UserInput_Bounds(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_UserInput_Lands") {
            result = nodeInterpreted_Node_UserInput_Lands(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_UserInput_Levels") {
            result = nodeInterpreted_Node_UserInput_Levels(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_UserInput_PLUZone") {
            result = nodeInterpreted_Node_UserInput_PLUZone(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_UserInput_Buildings") {
            result = nodeInterpreted_Node_UserInput_Buildings(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_UserInput_Draw_Point") {
            result = nodeInterpreted_Node_UserInput_Draw_Point(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_UserInput_Draw_Line") {
            result = nodeInterpreted_Node_UserInput_Draw_Line(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_UserInput_Draw_Polygon") {
            result = nodeInterpreted_Node_UserInput_Draw_Polygon(nodeData, capacity, perimeter);
        }

        else if (nodeType === "Node_Input_Number") {
            result = nodeInterpreted_Node_Input_Number(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Input_String") {
            result = nodeInterpreted_Node_Input_String(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Input_Boolean") {
            result = nodeInterpreted_Node_Input_Boolean(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Input_Property") {
            result = nodeInterpreted_Node_Input_Property(nodeData, capacity, perimeter);
        }

        else if (nodeType === "Node_Operator_Addition") {
            result = nodeInterpreted_Node_Operator_Addition(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Operator_Substraction") {
            result = nodeInterpreted_Node_Operator_Substraction(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Operator_Multiplication") {
            result = nodeInterpreted_Node_Operator_Multiplication(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Operator_Division") {
            result = nodeInterpreted_Node_Operator_Division(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Operator_Calcul_Area") {
            result = nodeInterpreted_Node_Operator_Calcul_Area(nodeData, capacity, perimeter);
        }
        // 2D OPERATORS
        else if (nodeType === "Node_2DOperator_Buffer") {
            result = nodeInterpreted_Node_2DOperator_Buffer(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_2DOperator_Translation") {
            result = nodeInterpreted_Node_2DOperator_Translation(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_2DOperator_Offset") {
            result = nodeInterpreted_Node_2DOperator_Offset(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_2DOperator_Union") {
            result = nodeInterpreted_Node_2DOperator_Union(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_2DOperator_Difference") {
            result = nodeInterpreted_Node_2DOperator_Difference(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_2DOperator_Intersect") {
            result = nodeInterpreted_Node_2DOperator_Intersect(nodeData, capacity, perimeter);
        }
        // STUDY ELEMENTS
        else if (nodeType === "Node_Study_Land") {
            result = nodeInterpreted_Node_Study_Land(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Study_Lands") {
            result = nodeInterpreted_Node_Study_Lands(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Study_Perimeter") {
            result = nodeInterpreted_Node_Study_Perimeter(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Study_Buildable_Height") {
            result = nodeInterpreted_Node_Study_Buildable_Height(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Study_BoundTypes") {
            result = nodeInterpreted_Node_Study_BoundTypes(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Study_BoundItems") {
            result = nodeInterpreted_Node_Study_BoundItems(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Study_Bounds") {
            result = nodeInterpreted_Node_Study_Bounds(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Study_PLUZone") {
            result = nodeInterpreted_Node_Study_PLUZone(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Study_Levels") {
            result = nodeInterpreted_Node_Study_Levels(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Study_Building_Area_Total") {
            result = nodeInterpreted_Node_Study_Building_Area_Total(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Study_Existing_Buildings") {
            result = nodeInterpreted_Node_Study_Existing_Buildings(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Study_Buildings_Footprint") {
            result = nodeInterpreted_Node_Study_Buildings_Footprint(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Study_Buildings_Height") {
            result = nodeInterpreted_Node_Study_Buildings_Height(nodeData, capacity, perimeter);
        }

        else if (nodeType === "Node_Operator_Logical") {
            result = nodeInterpreted_Node_Operator_Logical(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Operator_Comparison") {
            result = nodeInterpreted_Node_Operator_Comparison(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_Operator_Condition") {
            result = nodeInterpreted_Node_Operator_Condition(nodeData, capacity, perimeter);
        }
        // 2D Elements
        else if (nodeType === "Node_2DElement_Point") {
            result = nodeInterpreted_Node_2DElement_Point(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_2DElement_Line") {
            result = nodeInterpreted_Node_2DElement_Line(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_2DElement_Polygon") {
            result = nodeInterpreted_Node_2DElement_Polygon(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_2DElement_MapInput") {
            result = nodeInterpreted_Node_2DElement_MapInput(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_2DElement_Rectangle") {
            result = nodeInterpreted_Node_2DElement_Rectangle(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_2DElement_Triangle") {
            result = nodeInterpreted_Node_2DElement_Triangle(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_2DElement_Trapeze") {
            result = nodeInterpreted_Node_2DElement_Trapeze(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_2DElement_Trapeze_5") {
            result = nodeInterpreted_Node_2DElement_Trapeze_5(nodeData, capacity, perimeter);
        }
        else if (nodeType === "Node_2DElement_Trapeze_6") {
            result = nodeInterpreted_Node_2DElement_Trapeze_6(nodeData, capacity, perimeter);
        }

        return result

    } catch (error) {
        console.log("ERROR INTERPRETING NODE", error);
        return []
    }
}

const getNodeDataList = (nodeData) => {
    // Check if sub level
    var nbOpen = [];
    var nbClose = [];
    for (var i = 0; i < nodeData.length; i++) {
        if (nodeData[i] === "[") {
            nbOpen.push(i);
        }
        else if (nodeData[i] === "]") {
            nbClose.push(i);
        }
    }
    nbOpen = nbOpen.reverse();
    var subLevel_List = [];
    for (var i = 0; i < nbOpen.length; i++) {
        for (var j = 0; j < nbClose.length; j++) {
            if (nbClose[j] > nbOpen[i]) {
                subLevel_List.push({
                    start: nbOpen[i],
                    end: nbClose[j]
                });
                nbClose.splice(j, 1);
                break;
            }
        }
    }

    var newNodeData = nodeData;
    for (var i = 0; i < subLevel_List.length; i++) {
        var replace = "";
        for (var j = 0; j < subLevel_List[i].end - subLevel_List[i].start - 1; j++) {
            replace = replace + "x";
        }
        newNodeData = newNodeData.substring(0, subLevel_List[i].start + 1) + replace + newNodeData.substring(subLevel_List[i].end, newNodeData.length);
    }

    var nodeDataList = newNodeData.split("__");

    var NewNodeDataList = [];
    var start = 0;
    for (var i = 0; i < nodeDataList.length; i++) {
        NewNodeDataList.push(nodeData.substring(start, start + nodeDataList[i].length));
        // //console.log("start", start);
        // //console.log("end", start + nodeDataList[i].length);
        start += nodeDataList[i].length + 2;
    }

    return NewNodeDataList;
}


// __________ USER INPUTS
function nodeInterpreted_Node_UserInput_Number(nodeData, capacity, nodePerimeter) {
    // Get values
    var num = nodeData[1].value;
    if (num.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        num = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
        //console.log("INTERPRETED num", num);
    }
    else {
        num = parseFloat(num);
    }

    return num;
}

function nodeInterpreted_Node_UserInput_Boolean(nodeData, capacity, nodePerimeter) {
    // Get values
    var value = nodeData[1].value;
    if (value.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        value = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
        //console.log("INTERPRETED VALUE", value);
    }

    return value;
}

function nodeInterpreted_Node_UserInput_Bounds(nodeData, capacity, nodePerimeter) {
    // Get values
    var value = nodeData[1].value;
    if (value.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        value = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
        //console.log("INTERPRETED VALUE", value);
    }

    return value;
}

function nodeInterpreted_Node_UserInput_Lands(nodeData, capacity, nodePerimeter) {
    // Get values
    var value = nodeData[1].value;
    if (value.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        value = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
        //console.log("INTERPRETED VALUE", value);
    }

    return value;
}

function nodeInterpreted_Node_UserInput_PLUZone(nodeData, capacity, nodePerimeter) {
    // Get values
    var value = nodeData[1].value;
    if (value.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        value = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
        //console.log("INTERPRETED VALUE", value);
    }

    return value;
}

function nodeInterpreted_Node_UserInput_Levels(nodeData, capacity, nodePerimeter) {
    // Get values
    var value = nodeData[1].value;
    if (value.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        value = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
        //console.log("INTERPRETED VALUE", value);
    }

    return value;
}

function nodeInterpreted_Node_UserInput_Buildings(nodeData, capacity, nodePerimeter) {
    // Get values
    var value = nodeData[1].value;
    if (value.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        value = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
        //console.log("INTERPRETED VALUE", value);
    }

    return value;
}

function nodeInterpreted_Node_UserInput_Draw_Point(nodeData, capacity, nodePerimeter) {
    // Get values
    var value = nodeData[1].value;
    if (value.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        value = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
        //console.log("INTERPRETED VALUE", value);
    }

    return value;
}

function nodeInterpreted_Node_UserInput_Draw_Line(nodeData, capacity, nodePerimeter) {
    // Get values
    var value = nodeData[1].value;
    if (value.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        value = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
        //console.log("INTERPRETED VALUE", value);
    }

    return value;
}

function nodeInterpreted_Node_UserInput_Draw_Polygon(nodeData, capacity, nodePerimeter) {
    // Get values
    var value = nodeData[1].value;
    if (value.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        value = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
        //console.log("INTERPRETED VALUE", value);
    }

    return value;
}


// _________ INPUTS

function nodeInterpreted_Node_Input_Number(nodeData, capacity, nodePerimeter) {
    return parseFloat(nodeData[0].value);
}

function nodeInterpreted_Node_Input_String(nodeData, capacity, nodePerimeter) {
    return nodeData[0].value;
}

function nodeInterpreted_Node_Input_Boolean(nodeData, capacity, nodePerimeter) {
    var value = false;
    if (nodeData[0].value === "true") {
        value = true;
    }
    return value;
}

function nodeInterpreted_Node_Input_Property(nodeData, capacity, nodePerimeter) {
    // Get values
    var element = nodeData[0].value;
    if (element.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        element = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
    }
    console.log("INTERPRETED element", element);

    var key = nodeData[1].value;
    if (key.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        key = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
    }
    console.log("INTERPRETED key", key);


    var result = null;
    if (Array.isArray(element)) {
        result = [];
        // Check if data is grouped inside first element
        if (element.length > 0 && element[0]?.userData?.isPropertyGrouped === true && element[0]?.userData && key in element[0].userData) {
            result = element[0].userData[key];
        }
        else {
            element.forEach(item => {
                if (item?.userData && key in item.userData) {
                    result.push(item.userData[key]);
                }
                else {
                    result.push(null);
                }
            })
        }
    }
    else {
        if (element?.userData && key in element.userData) {
            result = element.userData[key];
        }
        else {
            result = null;
        }
    }
    console.log("Property result", result);

    return result

}


// _________ OPERATOR

function nodeInterpreted_Node_Operator_Addition(nodeData, capacity, nodePerimeter) {
    // Get values
    var a = nodeData[0].value;
    if (a.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        a = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
        //console.log("INTERPRETED a", a);
    }
    else {
        a = parseFloat(a);
    }

    var b = nodeData[1].value;
    if (b.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        b = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
        //console.log("INTERPRETED b", b);
    }
    else {
        b = parseFloat(b);
    }

    return (a + b);
}

function nodeInterpreted_Node_Operator_Substraction(nodeData, capacity, nodePerimeter) {
    // Get values
    var a = nodeData[0].value;
    if (a.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        a = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
        //console.log("INTERPRETED a", a);
    }
    else {
        a = parseFloat(a);
    }

    var b = nodeData[1].value;
    if (b.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        b = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
        //console.log("INTERPRETED b", b);
    }
    else {
        b = parseFloat(b);
    }

    return (a - b);
}

function nodeInterpreted_Node_Operator_Multiplication(nodeData, capacity, nodePerimeter) {
    // Get values
    var a = nodeData[0].value;
    if (a.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        a = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
        //console.log("INTERPRETED a", a);
    }
    else {
        a = parseFloat(a);
    }

    var b = nodeData[1].value;
    if (b.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        b = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
        //console.log("INTERPRETED b", b);
    }
    else {
        b = parseFloat(b);
    }

    return (a * b);
}

function nodeInterpreted_Node_Operator_Division(nodeData, capacity, nodePerimeter) {
    // Get values
    var a = nodeData[0].value;
    if (a.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        a = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
    }
    else {
        a = parseFloat(a);
    }
    if (!Array.isArray(a)) {
        a = [a];
    }
    console.log("INTERPRETED a", a);


    var b = nodeData[1].value;
    if (b.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        b = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
    }
    else {
        b = parseFloat(b);
    }
    if (!Array.isArray(b)) {
        b = [b];
    }
    console.log("INTERPRETED b", b);

    var result = [];
    for (var i = 0; i < a.length; i++) {
        var num = a[i];
        var denom = b[0];
        if (b.length >= i + 1) {
            denom = b[0];
        }
        var res = 0;
        if (denom !== 0) {
            res = num / denom;
        }
        result.push(res);
    }
    if (result.length === 1) {
        result = result[0];
    }

    return (result);
}

function nodeInterpreted_Node_Operator_Logical(nodeData, capacity, nodePerimeter) {
    // Get values
    var operator = nodeData[0].value;
    if (operator.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        operator = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
        //console.log("INTERPRETED OPERATOR", operator);
    }

    var a = nodeData[1].value;
    if (a.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        a = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
        //console.log("INTERPRETED a", a);
    }

    var b = nodeData[2].value;
    if (b.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        b = nodeInterpretor([nodeData[2]], capacity, nodePerimeter);
        //console.log("INTERPRETED b", b);
    }

    // Get value
    var value = null;
    if (a !== null && b !== null) {
        value = false;
        if (operator === "and" && (a === true && b === true)) {
            value = true;
        }
        else if (operator === "or" && (a === true || b === true)) {
            value = true;
        }
        else if (operator === "nand" && !(a === true && b === true)) {
            value = true;
        }
        else if (operator === "nor" && !(a === true || b === true)) {
            value = true;
        }
    }

    return value;
}

function nodeInterpreted_Node_Operator_Comparison(nodeData, capacity, nodePerimeter) {
    // Get values
    var operator = nodeData[0].value;
    if (operator.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        operator = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
        //console.log("INTERPRETED OPERATOR", operator);
    }

    var a = nodeData[1].value;
    if (a.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        a = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
        //console.log("INTERPRETED a", a);
    }

    var b = nodeData[2].value;
    if (b.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        b = nodeInterpretor([nodeData[2]], capacity, nodePerimeter);
        //console.log("INTERPRETED b", b);
    }

    // Get value
    var value = null;
    if (a !== null && b !== null) {
        value = false;
        if (operator === "eq" && a === b) {
            value = true;
        }
        else if (operator === "neq" && a !== b) {
            value = true;
        }
        else if (operator === "gt" && a > b) {
            value = true;
        }
        else if (operator === "geqt" && a >= b) {
            value = true;
        }
        else if (operator === "lt" && a < b) {
            value = true;
        }
        else if (operator === "leqt" && a <= b) {
            value = true;
        }
    }

    return value;
}

function nodeInterpreted_Node_Operator_Condition(nodeData, capacity, nodePerimeter) {
    // Get values
    var test = nodeData[0].value;
    if (test.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        test = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
        //console.log("INTERPRETED TEST", test);
    }

    var a = nodeData[1].value;
    if (a.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        a = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
        //console.log("INTERPRETED a", a);
    }

    var b = nodeData[2].value;
    if (b.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        b = nodeInterpretor([nodeData[2]], capacity, nodePerimeter);
        //console.log("INTERPRETED b", b);
    }

    // Get value
    var value = null;
    if (test === true) {
        value = a;
    }
    else if (test === false) {
        value = b;
    }

    return value;
}

function nodeInterpreted_Node_Operator_Calcul_Area(nodeData, capacity, nodePerimeter) {

    // Get values
    var surface = nodeData[0].value;
    if (surface.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        surface = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
        //console.log("INTERPRETED SURFACE", surface);
    }

    // Get result
    var result = 0;
    if (Array.isArray(surface)) {
        surface.forEach(item => {
            if (item?.userData?.turfData) {
                result += turf.area(item.userData.turfData);
            }
        })
    }
    else {
        if (surface?.userData?.turfData) {
            result = turf.area(surface.userData.turfData);
        }
    }

    return result;
}


// __________ 3D OPERATORS

function nodeInterpreted_Node_3DOperator_Extrusion(nodeData, capacity, nodePerimeter) {

    // Get values
    var surface = nodeData[0].value;
    if (surface.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        surface = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
        // console.log("INTERPRETED SURFACE", surface);
    }

    var hmin = nodeData[1].value;
    if (hmin.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        hmin = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
        //console.log("INTERPRETED HMIN", hmin);
    }
    else {
        hmin = parseFloat(hmin);
    }

    var hmax = nodeData[2].value;
    if (hmax.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        hmax = nodeInterpretor([nodeData[2]], capacity, nodePerimeter);
        //console.log("INTERPRETED HMAX", hmax);
    }
    else {
        hmax = parseFloat(hmax);
    }

    if (surface === undefined || isNaN(hmin) || isNaN(hmax)) {
        //console.log("UNDEFINED");
        return undefined;
    }

    // Create geometry
    var result;
    if (Array.isArray(surface)) {
        result = [];
        surface.forEach(item => {
            if (item === undefined) {
                result.push(undefined);
            }
            else {
                var geometry = getExtrusionFromShape(item, (hmax - hmin));
                // Move geometry of hmin
                geometry.translate(0, 0, hmin);

                var turfData = item?.userData?.turfData;
                var area = null;
                if (turfData?.geometry?.type === "Polygon" || turfData?.geometry?.type === "MultiPolygon") {
                    area = turf.area(turfData);
                }
                var volume = null;
                if (area !== null) {
                    volume = area * (hmax - hmin);
                }
                geometry.userData = {
                    turfData,
                    area,
                    volume
                };

                result.push(geometry);
            }
        });
    }
    else {
        var geometry = getExtrusionFromShape(surface, (hmax - hmin));
        // Move geometry of hmin
        geometry.translate(0, 0, hmin);
        
        var turfData = surface?.userData?.turfData;
        var area = null;
        if (turfData?.geometry?.type === "Polygon" || turfData?.geometry?.type === "MultiPolygon") {
            area = turf.area(turfData);
        }
        var volume = null;
        if (area !== null) {
            volume = area * (hmax - hmin);
        }
        geometry.userData = {
            turfData,
            area,
            volume
        };

        result = geometry;
    }

    // Send result
    return result;

}

function nodeInterpreted_Node_3DOperator_ExtrusionPath(nodeData, capacity, nodePerimeter) {

    // Get values
    var surface = nodeData[0].value;
    if (surface.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        surface = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
    }
    if (!Array.isArray(surface)) {
        surface = [surface];
    }
    console.log("INTERPRETED SURFACE", surface);

    var path = nodeData[1].value;
    if (path.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        path = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
    }
    if (!Array.isArray(path)) {
        path = [path];
    }
    console.log("INTERPRETED PATH", path);

    if (surface === undefined || path === undefined) {
        return undefined;
    }


    // Create geometry
    var result;
    if (Array.isArray(surface)) {
        result = [];
        surface.forEach(item => {
            if (item === undefined) {
                result.push(undefined);
            }
            else {
                if (Array.isArray(path)) {
                    path.forEach(item2 => {
                        if (item2 === undefined) {
                            result.push(undefined);
                        }
                        else {
                            var geometry = getExtrusionPathFromShape(getScaledShapeHtoV(item), item2);
                            result.push(geometry);
                        }
                    })
                }
                else {
                    var geometry = getExtrusionPathFromShape(getScaledShapeHtoV(item), path);
                    result.push(geometry);
                }
            }
        });
    }
    else {
        if (Array.isArray(path)) {
            result = [];
            path.forEach(item2 => {
                if (item2 === undefined) {
                    result.push(undefined);
                }
                else {
                    //console.log("PATH TO CHECK:", item2.points);
                    var pathList = [];
                    var pointList = item2.points;
                    if (pointList.length > 2) {
                        var pathCurrent = [pointList[0], pointList[1]];
                        for (var i = 2; i < pointList.length; i++) {
                            var point0_coords = getGlobalCoordinates(capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix, [[pointList[i - 2].x, pointList[i - 2].y]])[0];
                            var point1_coords = getGlobalCoordinates(capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix, [[pointList[i - 1].x, pointList[i - 1].y]])[0];
                            var point2_coords = getGlobalCoordinates(capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix, [[pointList[i].x, pointList[i].y]])[0];
                            var b01 = turf.bearing(turf.point(point0_coords), turf.point(point1_coords));
                            // //console.log("BEARING01 deg", b01);
                            var b02 = turf.bearing(turf.point(point1_coords), turf.point(point2_coords));
                            // //console.log("BEARING02 deg", b02);
                            var angle = b01 - b02;
                            //console.log("BEARING02 total", angle);

                            if (angle > 0) {
                                pathList.push(pathCurrent);
                                pathCurrent = [pointList[i - 1], pointList[i]];
                            }
                            else {
                                pathCurrent.push(pointList[i]);
                            }

                            if (i === pointList.length - 1) {
                                pathList.push(pathCurrent);
                            }

                        }
                    }
                    else {
                        pathList = [pointList];
                    }
                    console.log("pathList", pathList);

                    var lines = [];
                    pathList.forEach(line => {
                        var three_geom = new THREE.CatmullRomCurve3(line);
                        three_geom.curveType = 'catmullrom';
                        three_geom.tension = 0;
                        lines.push(three_geom);
                    })
                    console.log("lines", lines);

                    lines.forEach(line => {
                        var geometry = getExtrusionPathFromShape(getScaledShapeHtoV(surface), line);
                        result.push(geometry);
                    })


                }
            })
        }
        else {
            var geometry = getExtrusionPathFromShape(getScaledShapeHtoV(surface), path);
            result = geometry;
        }
    }

    // Get angles
    var angles = [];
    if (path.length > 1) {
        for (var i = 0; i < path.length; i++) {
            var i_prev = i - 1;
            if (i_prev < 0) { i_prev = path.length - 1 }
            var i_current = i;

            // Check if common point
            if (path[i_current].points[0].x === path[i_prev].points[path[i_prev].points.length-1].x && path[i_current].points[0].y === path[i_prev].points[path[i_prev].points.length-1].y) {
                // Check if angle if bigger than 90
                var L1_bearing = turf.bearing(turf.point(path[i_prev].userData.turfData.geometry.coordinates[path[i_prev].userData.turfData.geometry.coordinates.length-2]), turf.point(path[i_prev].userData.turfData.geometry.coordinates[path[i_prev].userData.turfData.geometry.coordinates.length-1]));
                var L2_bearing = turf.bearing(turf.point(path[i_current].userData.turfData.geometry.coordinates[0]), turf.point(path[i_current].userData.turfData.geometry.coordinates[1]));
                // var L1_bearing = turf.bearing(turf.point(path[i_current].userData.turfData.geometry.coordinates[0]), turf.point(path[i_current].userData.turfData.geometry.coordinates[1]));
                // var L2_bearing = turf.bearing(turf.point(path[i_prev].userData.turfData.geometry.coordinates[path[i_prev].userData.turfData.geometry.coordinates.length-2]), turf.point(path[i_prev].userData.turfData.geometry.coordinates[path[i_prev].userData.turfData.geometry.coordinates.length-1]));
                var bearing_angle = L2_bearing - L1_bearing + 180;
                if (bearing_angle > 360) {
                    bearing_angle -= 360;
                }
                if (bearing_angle < -360) {
                    bearing_angle += 360;
                }
                if (bearing_angle < 0) {
                    bearing_angle += 360;
                }
                // if (bearing_angle > 180) {
                //     bearing_angle -= 180;
                // }
                console.log("angle " + i_prev + "-" + i_current, bearing_angle);

                if (bearing_angle > 180) {
                    angles.push({
                        lines: [i_prev, i_current],
                        bearing: (L2_bearing - 90),
                        value_base: bearing_angle,
                        value: (bearing_angle - 180) * (Math.PI / 180),
                        point: path[i_current].points[0]
                    });
                }
            }
        }
    }
    console.log("angles", angles);
    if (angles.length > 0) {
        // Create angle geometry
        var shape = surface[0];
        var points = [];
        var max_x = 0;
        var max_y = 0;
        for ( var i = 0; i < shape.curves.length; i++ ) {
            var point = shape.curves[i].v1;
            if (point.x < 0) { point.x = 0 }
            points.push(point);
            if (point.x > max_x) { max_x = point.x }
            if (point.y > max_y) { max_y = point.y }
        }
        points.push(points[0]);
        console.log("points", points);
        angles.forEach(angle => {
            var geometry_lathe = new THREE.LatheGeometry( points, 1, 0, angle.value );
            geometry_lathe.rotateX(Math.PI / 2);

            // Get check points for adaptation
            // var check_points = [];
            // var line_prev = result[angle.lines[0]];
            // for (var i = 0; i < line_prev.attributes.position.array.length; i += 3) {
            //     check_points.push([line_prev.attributes.position.array[i], line_prev.attributes.position.array[i+1], line_prev.attributes.position.array[i+2]]);
            // }
            // var line_next = result[angle.lines[1]];
            // for (var i = 0; i < line_next.attributes.position.array.length; i += 3) {
            //     check_points.push([line_next.attributes.position.array[i], line_next.attributes.position.array[i+1], line_next.attributes.position.array[i+2]]);
            // }
            // console.log("check_points", check_points);

            
            // Get box_points
            var x_min = Infinity;
            var x_max = -Infinity;
            var y_min = Infinity;
            var y_max = -Infinity;
            for (var i = 0; i < geometry_lathe.attributes.position.array.length; i += 3) {
                // get min / max values
                if (geometry_lathe.attributes.position.array[i] < x_min) { x_min = geometry_lathe.attributes.position.array[i] }
                if (geometry_lathe.attributes.position.array[i] > x_max) { x_max = geometry_lathe.attributes.position.array[i] }
                if (geometry_lathe.attributes.position.array[i+1] < y_min) { y_min = geometry_lathe.attributes.position.array[i+1] }
                if (geometry_lathe.attributes.position.array[i+1] > y_max) { y_max = geometry_lathe.attributes.position.array[i+1] }
            }
            console.log("x y", {x_min, x_max, y_min, y_max});
            var box = new THREE.Shape();
            box.lineTo(0, 0);
            box.lineTo(x_min, y_min);
            box.lineTo(x_max, y_min);
            box.lineTo(x_max, y_max);
            box.lineTo(x_min, y_max);
            box.lineTo(0, 0);
            var box_geometry = new THREE.ExtrudeGeometry(box, {
                steps: 1,
                depth: max_y,
                bevelEnabled: false
            });

            geometry_lathe.rotateZ((180 - angle.bearing) * Math.PI / 180);
            geometry_lathe.translate(path[angle.lines[1]].points[0].x, path[angle.lines[1]].points[0].y, 0);
            box_geometry.rotateZ((180 - angle.bearing) * Math.PI / 180);
            box_geometry.translate(path[angle.lines[1]].points[0].x, path[angle.lines[1]].points[0].y, 0);


            geometry_lathe.userData = {
                box: box_geometry,
                // check_points: check_points,
                lines: angle.lines
            }

            result.push(geometry_lathe);
        })
    
    }

    // Send result
    return result;

}


//__________ 2D OPERATORS

function nodeInterpreted_Node_2DOperator_Buffer(nodeData, capacity, nodePerimeter) {
    console.log("nodeData", nodeData);
    // Get values
    var element2D = nodeData[0].value;
    if (element2D.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        element2D = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
    }
    console.log("INTERPRETED ELEMENT 2D", element2D);

    var offset = nodeData[1].value;
    if (offset.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        offset = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
    }
    else {
        offset = parseFloat(offset);
    }
    console.log("INTERPRETED OFFSET", offset);
    if (!Array.isArray(offset)) {
        offset = [offset];
    }

    if (element2D === undefined || isNaN(offset[0])) {
        console.log("Buffer error");
        return undefined;
    }


    // Create geometry
    var result;
    //console.log("TYPEOF ELEMENT 2D", Array.isArray(element2D));
    if (Array.isArray(element2D)) {
        result = [];
        element2D.forEach((item, index) => {
            if (item === undefined) {
                result.push(undefined);
            }
            else {
                var buffered = item.userData.turfData;
                var offset_item = offset[0];
                if (offset.length >= index + 1) { offset_item = offset[index] }
                var steps = 2;
                if ((item.userData.turfData.geometry.type === "Point" && offset_item > 0) || (item.userData.turfData.geometry.type === "MultiPoint" && offset_item > 0)) {
                    steps = 8;
                    console.log("turfData", item.userData.turfData);
                    buffered = turf.buffer(item.userData.turfData, offset_item / 1000, { steps: steps });
                }
                if ((item.userData.turfData.geometry.type === "LineString" && offset_item > 0) || (item.userData.turfData.geometry.type === "MultiLineString" && offset_item > 0) || (item.userData.turfData.geometry.type === "Polygon" && offset_item !== 0) || (item.userData.turfData.geometry.type === "MultiPolygon" && offset_item !== 0)) {
                    buffered = turf.buffer(item.userData.turfData, offset_item / 1000, { steps: steps });
                }
                console.log("BUFFER buffered", buffered);
                var localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, buffered.geometry.coordinates[0]);
                var shape = getShapeFromPoints(localCoords, buffered.geometry.coordinates[0]);
                shape.userData = { turfData: buffered };
                result.push(shape);
            }
        })
    }
    else {
        var buffered = element2D.userData.turfData;
        if (offset[0] > 0) {
            buffered = turf.buffer(element2D.userData.turfData, offset[0] / 1000);
        }
        console.log("BUFFER buffered", buffered);
        var localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, buffered.geometry.coordinates[0]);
        var shape = getShapeFromPoints(localCoords, buffered.geometry.coordinates[0]);
        shape.userData = { turfData: buffered };
        result = shape;
    }
    console.log("BUFFER result", result);

    // Send result
    return result;
}

function nodeInterpreted_Node_2DOperator_Translation(nodeData, capacity, nodePerimeter) {
    // Get values
    var element2D = nodeData[0].value;
    if (element2D.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        element2D = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
        //console.log("INTERPRETED ELEMENT 2D", element2D);
    }

    var deltaX = nodeData[1].value;
    if (deltaX.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        deltaX = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
        //console.log("INTERPRETED DELTA X", deltaX);
    }

    var deltaY = nodeData[2].value;
    if (deltaY.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        deltaY = nodeInterpretor([nodeData[2]], capacity, nodePerimeter);
        //console.log("INTERPRETED DELTA Y", deltaY);
    }

    if (element2D === undefined || isNaN(deltaX) || isNaN(deltaY)) {
        return undefined;
    }

    // Create shape
    var result;
    if (Array.isArray(element2D)) {
        result = [];
        element2D.forEach(item => {
            if (item === undefined) {
                result.push(undefined);
            }
            else {
                var shape = getTranslatedShape(item, deltaX, deltaY);
                // Get turf Data
                var globalPoints = shape.getPoints();
                var localPoints = [];
                globalPoints.forEach(point => {
                    localPoints.push([point.x, point.y]);
                })
                localPoints.push([globalPoints[0].x, globalPoints[0].y]);
                var turfData = turf.polygon([getGlobalCoordinates(capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix, localPoints)]);
                shape.userData = { turfData: turfData };
                result.push(shape);
            }
        })
    }
    else {
        var shape = getTranslatedShape(element2D, deltaX, deltaY);
        // Get turf Data
        var globalPoints = shape.getPoints();
        var localPoints = [];
        globalPoints.forEach(point => {
            localPoints.push([point.x, point.y]);
        })
        localPoints.push([globalPoints[0].x, globalPoints[0].y]);
        var turfData = turf.polygon([getGlobalCoordinates(capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix, localPoints)]);
        shape.userData = { turfData: turfData };
        result = shape;
    }

    return result;

}

function nodeInterpreted_Node_2DOperator_Union(nodeData, capacity, nodePerimeter) {
    // Get values
    var shape1 = nodeData[0].value;
    if (shape1.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        shape1 = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
        //console.log("INTERPRETED SHAPE1", shape1);
    }

    var shape2 = nodeData[1].value;
    if (shape2.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        shape2 = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
        //console.log("INTERPRETED SHAPE2", shape2);
    }

    if (shape1 === undefined || shape2 === undefined) {
        return undefined;
    }

    // Create shape
    var result = undefined;
    if (!Array.isArray(shape1)) {
        shape1 = [shape1];
    }
    if (!Array.isArray(shape2)) {
        shape2 = [shape2];
    }
    var shapeList = shape1.concat(shape2);
    shapeList.forEach(shape => {
        if (shape === undefined || !shape?.userData?.turfData) {
        }
        else {
            if (result === undefined) {
                result = shape.userData.turfData;
            }
            else {
                result = turf.union(result, shape.userData.turfData);
            }
        }
    })

    //console.log("result", result);

    if (result !== undefined) {
        if (result.geometry.type === "Polygon") {
            var localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, result.geometry.coordinates[0]);
            var threeElement = getShapeFromPoints(localCoords, result.geometry.coordinates[0]);
            //console.log("threeElement", threeElement);
            result = threeElement;
        }
        else if (result.geometry.type === "MultiPolygon") {
            var resultList = [];
            result.geometry.coordinates.forEach(result_coords => {
                var localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, result_coords[0]);
                var threeElement = getShapeFromPoints(localCoords, result_coords[0]);
                //console.log("threeElement", threeElement);
                resultList.push(threeElement);
            })
            result = resultList;
        }
    }

    return result;

}

function nodeInterpreted_Node_2DOperator_Difference(nodeData, capacity, nodePerimeter) {
    // Get values
    var shape1 = nodeData[0].value;
    if (shape1.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        shape1 = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
        //console.log("INTERPRETED SHAPE1", shape1);
    }

    var shape2 = nodeData[1].value;
    if (shape2.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        shape2 = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
        //console.log("INTERPRETED SHAPE2", shape2);
    }

    if (shape1 === undefined || shape2 === undefined) {
        return undefined;
    }

    // Create unified shape 1
    if (!Array.isArray(shape1)) {
        shape1 = [shape1];
    }
    var ushape1 = undefined;
    shape1.forEach(shape => {
        if (shape === undefined || !shape?.userData?.turfData) {
        }
        else {
            if (ushape1 === undefined) {
                ushape1 = shape.userData.turfData;
            }
            else {
                ushape1 = turf.union(ushape1, shape.userData.turfData);
            }
        }
    })

    // Create unified shape 2
    if (!Array.isArray(shape2)) {
        shape2 = [shape2];
    }
    var ushape2 = undefined;
    shape2.forEach(shape => {
        if (shape === undefined || !shape?.userData?.turfData) {
        }
        else {
            if (ushape2 === undefined) {
                ushape2 = shape.userData.turfData;
            }
            else {
                ushape2 = turf.union(ushape2, shape.userData.turfData);
            }
        }
    })

    // Get difference
    var result = undefined;
    if (ushape1 === undefined || ushape2 === undefined) {

    }
    else {
        result = turf.difference(ushape1, ushape2);
    }
    if (result === null) {
        result = undefined;
    }

    //console.log("result", result);

    if (result !== undefined) {
        if (result.geometry.type === "Polygon") {
            var localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, result.geometry.coordinates[0]);
            var threeElement = getShapeFromPoints(localCoords, result.geometry.coordinates[0]);
            //console.log("threeElement", threeElement);
            result = threeElement;
        }
        else if (result.geometry.type === "MultiPolygon") {
            var resultList = [];
            result.geometry.coordinates.forEach(result_coords => {
                var localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, result_coords[0]);
                var threeElement = getShapeFromPoints(localCoords, result_coords[0]);
                //console.log("threeElement", threeElement);
                resultList.push(threeElement);
            })
            result = resultList;
        }
    }

    return result;

}

function nodeInterpreted_Node_2DOperator_Intersect(nodeData, capacity, nodePerimeter) {
    // Get values
    var shape1 = nodeData[0].value;
    if (shape1.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        shape1 = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
        console.log("INTERPRETED SHAPE1", shape1);
    }

    var shape2 = nodeData[1].value;
    if (shape2.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        shape2 = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
        console.log("INTERPRETED SHAPE2", shape2);
    }

    if (shape1 === undefined || shape2 === undefined) {
        return undefined;
    }

    // Create unified shape 1
    if (!Array.isArray(shape1)) {
        shape1 = [shape1];
    }
    var ushape1 = undefined;
    shape1.forEach(shape => {
        if (shape === undefined || !shape?.userData?.turfData) {
        }
        else {
            if (ushape1 === undefined) {
                ushape1 = shape.userData.turfData;
            }
            else {
                ushape1 = turf.union(ushape1, shape.userData.turfData);
            }
        }
    })

    // Create unified shape 2
    if (!Array.isArray(shape2)) {
        shape2 = [shape2];
    }
    var ushape2 = undefined;
    shape2.forEach(shape => {
        if (shape === undefined || !shape?.userData?.turfData) {
        }
        else {
            if (ushape2 === undefined) {
                ushape2 = shape.userData.turfData;
            }
            else {
                ushape2 = turf.union(ushape2, shape.userData.turfData);
            }
        }
    })

    console.log("ushape1", ushape1, "ushape2", ushape2);

    // Get difference
    var result = undefined;
    if (ushape1 === undefined || ushape2 === undefined) {

    }
    else {
        result = turf.intersect(ushape1, ushape2);
    }
    if (result === null) {
        result = undefined;
    }

    console.log("result pre treatment", result);

    if (result !== undefined) {
        if (result.geometry.type === "Polygon") {
            var localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, result.geometry.coordinates[0]);
            var threeElement = getShapeFromPoints(localCoords, result.geometry.coordinates[0]);
            //console.log("threeElement", threeElement);
            result = threeElement;
        }
        else if (result.geometry.type === "MultiPolygon") {
            var resultList = [];
            result.geometry.coordinates.forEach(result_coords => {
                var localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, result_coords[0]);
                var threeElement = getShapeFromPoints(localCoords, result_coords[0]);
                //console.log("threeElement", threeElement);
                resultList.push(threeElement);
            })
            result = resultList;
        }
    }

    console.log("result", result);


    return result;

}

function nodeInterpreted_Node_2DOperator_Offset(nodeData, capacity, nodePerimeter) {
    // Get values
    var element2D = nodeData[0].value;
    if (element2D.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        element2D = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
        //console.log("INTERPRETED ELEMENT 2D", element2D);
    }

    var offset = nodeData[1].value;
    if (offset.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        offset = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
        //console.log("INTERPRETED OFFSET", offset);
    }

    if (element2D === undefined || isNaN(offset)) {
        return undefined;
    }

    // Get result
    var result;
    if (Array.isArray(element2D)) {
        result = [];
        element2D.forEach(item => {
            var points = [];
            if (item?.userData?.turfData) {
                var line = turf.getCoords(turf.lineOffset(item?.userData?.turfData, -offset / 1000));
                //console.log("line", line);
                line.forEach(line_coord => {
                    var line_localCoord = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, [line_coord])[0];
                    points.push(new THREE.Vector3(line_localCoord[0], line_localCoord[1], 0));
                })
                var three_geom = new THREE.CatmullRomCurve3(points);
                three_geom.curveType = 'catmullrom';
                three_geom.tension = 0;
                three_geom.userData = { turfData: turf.lineString(line) };
                result.push(three_geom);
            }
            else {
                result.push(undefined);
            }
        })
    }
    else {
        var points = [];
        if (element2D?.userData?.turfData) {
            var line = turf.getCoords(turf.lineOffset(element2D?.userData?.turfData, -offset / 1000));
            //console.log("line", line);
            line.forEach(line_coord => {
                var line_localCoord = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, [line_coord])[0];
                points.push(new THREE.Vector3(line_localCoord[0], line_localCoord[1], 0));
            })
            var three_geom = new THREE.CatmullRomCurve3(points);
            three_geom.curveType = 'catmullrom';
            three_geom.tension = 0;
            three_geom.userData = { turfData: turf.lineString(line) };
            result.push(three_geom);
        }
        else {
            result.push(undefined);
        }
    }

    return result;
}


//_________ STUDY ELEMENTS

function nodeInterpreted_Node_Study_Land(nodeData, capacity, nodePerimeter) {
    // Get local land coordinates
    var localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.geometry.geometry.coordinates[0]);
    // Get shape from global coords
    var landShape = getShapeFromPoints(localCoords, capacity.landBase.union.geometry.geometry.coordinates[0]);

    return landShape;
}

function nodeInterpreted_Node_Study_Lands(nodeData, capacity, nodePerimeter) {

    // Get bounds
    var lands = [];
    capacity.landBase.lands.forEach(land => {
        if (nodeData[0].value.includes("|" + land.generated_id + "|")) {
            lands.push(land);
        }
    })
    console.log("lands", lands);

    // Create union
    var result = [];
    if (lands.length > 1) {
        var polyLand = null;
        if (lands[0]["geometry"]["type"] === "MultiPolygon") {
            polyLand = turf.multiPolygon(lands[0]["geometry"]["coordinates"]);
        }
        else {
            polyLand = turf.polygon(lands[0]["geometry"]["coordinates"]);
        }
        for (var i = 1; i < lands.length; i++) {
            var poly2 = null;
            if (lands[i]["geometry"]["type"] === "MultiPolygon") {
                poly2 = turf.multiPolygon(lands[i]["geometry"]["coordinates"]);
            }
            else {
                poly2 = turf.polygon(lands[i]["geometry"]["coordinates"]);
            }
            polyLand = turf.union(polyLand, poly2);
        }
        polyLand.properties.area = turf.area(polyLand);
        result.push(polyLand);
    }
    else if (lands.length === 1) {
        var polyLand = null;
        if (lands[0]["geometry"]["type"] === "MultiPolygon") {
            polyLand = turf.multiPolygon(lands[0]["geometry"]["coordinates"]);
        }
        else {
            polyLand = turf.polygon(lands[0]["geometry"]["coordinates"]);
        }
        polyLand.properties.area = turf.area(polyLand);
        result.push(polyLand);
    }
    console.log("result", result);


    var lands_result = [];
    for (var i = 0; i < result.length; i++) {
        if (result[i].geometry.type === "Polygon") {
            // Get local land coordinates
            var localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, result[i].geometry.coordinates[0]);
            // Get shape from global coords
            var landShape = getShapeFromPoints(localCoords, result[i].geometry.coordinates[0]);
            // Push
            lands_result.push(landShape);
        }
        else if (result[i].geometry.type === "MultiPolygon") {
            for (var j = 0; j < result[i].geometry.coordinates.length; j++) {
                // Get local land coordinates
                var localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, result[i].geometry.coordinates[j][0]);
                // Get shape from global coords
                var landShape = getShapeFromPoints(localCoords, result[i].geometry.coordinates[j][0]);
                // Push
                lands_result.push(landShape);
            }
        }
    }
    console.log("lands_result", lands_result);



    return lands_result;
}

function nodeInterpreted_Node_Study_Buildings_Footprint(nodeData, capacity, nodePerimeter) {

    // Get bounds
    var buildings_footrpints = [];
    // Check buildings land & buildings close
    if ((nodeData[0].value.includes("|buildings_land|") || nodeData[0].value.includes("|buildings_close|")) && capacity?.landBase?.buildings?.buildings && capacity?.landBase?.buildings?.buildings_land && capacity?.landBase?.buildings?.buildings_close) {
        capacity.landBase.buildings.buildings.forEach((building, index) => {
            if (nodeData[0].value.includes("|buildings_land|") && capacity.landBase.buildings.buildings_land.includes(index)) {
                // Check if not destroyed
                if (!building?.properties?.demolition === true) {
                    buildings_footrpints.push(building);
                }
            }
            else if (nodeData[0].value.includes("|buildings_close|") && capacity.landBase.buildings.buildings_close.includes(index) && !capacity.landBase.buildings.buildings_land.includes(index)) {
                // Check if not destroyed
                if (!building?.properties?.demolition === true) {
                    buildings_footrpints.push(building);
                }
            }
        })
    }
    console.log("buildings_footrpints", buildings_footrpints);

    // Create union
    var result = [];
    // if (buildings_footrpints.length > 1) {
    //     var polyLand = buildings_footrpints[0];
    //     // if (buildings_footrpints[0]["geometry"]["type"] === "MultiPolygon") {
    //     //     polyLand = turf.multiPolygon(buildings_footrpints[0]["geometry"]["coordinates"]);
    //     // }
    //     // else {
    //     //     polyLand = turf.polygon(buildings_footrpints[0]["geometry"]["coordinates"]);
    //     // }
    //     for (var i = 1; i < buildings_footrpints.length; i++) {
    //         var poly2 = buildings_footrpints[i];
    //         // if (buildings_footrpints[i]["geometry"]["type"] === "MultiPolygon") {
    //         //     poly2 = turf.multiPolygon(buildings_footrpints[i]["geometry"]["coordinates"]);
    //         // }
    //         // else {
    //         //     poly2 = turf.polygon(buildings_footrpints[i]["geometry"]["coordinates"]);
    //         // }
    //         polyLand = turf.union(polyLand, poly2);
    //     }
    //     result.push(polyLand);
    // }
    // else if (buildings_footrpints.length === 1) {
    //     var polyLand = buildings_footrpints[0];
    //     // if (buildings_footrpints[0]["geometry"]["type"] === "MultiPolygon") {
    //     //     polyLand = turf.multiPolygon(buildings_footrpints[0]["geometry"]["coordinates"]);
    //     // }
    //     // else {
    //     //     polyLand = turf.polygon(buildings_footrpints[0]["geometry"]["coordinates"]);
    //     // }
    //     result.push(polyLand);
    // }
    result = buildings_footrpints;
    console.log("result", result);


    var lands_result = [];
    for (var i = 0; i < result.length; i++) {
        if (result[i].geometry.type === "Polygon") {
            // Get local land coordinates
            var localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, result[i].geometry.coordinates[0]);
            // Get shape from global coords
            var landShape = getShapeFromPoints(localCoords, result[i].geometry.coordinates[0]);
            // Get data
            landShape.userData.height = result[i]?.properties?.height_max || 0;
            // Push
            lands_result.push(landShape);
        }
        else if (result[i].geometry.type === "MultiPolygon") {
            for (var j = 0; j < result[i].geometry.coordinates.length; j++) {
                // Get local land coordinates
                var localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, result[i].geometry.coordinates[j][0]);
                // Get shape from global coords
                var landShape = getShapeFromPoints(localCoords, result[i].geometry.coordinates[j][0]);
                // Get data
                landShape.userData.height = result[i]?.properties?.height_max || 0;
                // Push
                lands_result.push(landShape);
            }
        }
    }
    console.log("lands_result", lands_result);



    return lands_result;
}

function nodeInterpreted_Node_Study_Existing_Buildings(nodeData, capacity, nodePerimeter) {

    // Get values
    var category = nodeData[0].value;
    if (category.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        category = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
    }
    // console.log("INTERPRETED CATEGORY", category);

    var filters = nodeData[1].value;
    if (filters.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        filters = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
    }
    // console.log("INTERPRETED FILTERS", filters);

    // Get bounds
    var buildings_footrpints = [];
    // TYPES
    if (category === "type" && capacity?.landBase?.buildings?.buildings && capacity?.landBase?.buildings?.buildings_land && capacity?.landBase?.buildings?.buildings_close) {
        capacity.landBase.buildings.buildings.forEach((building, index) => {
            if (filters.includes("|buildings_land|") && capacity.landBase.buildings.buildings_land.includes(index)) {
                // Check if not destroyed
                if (!building?.properties?.demolition === true) {
                    buildings_footrpints.push(building);
                }
            }
            else if (filters.includes("|buildings_close|") && capacity.landBase.buildings.buildings_close.includes(index) && !capacity.landBase.buildings.buildings_land.includes(index)) {
                // Check if not destroyed
                if (!building?.properties?.demolition === true) {
                    buildings_footrpints.push(building);
                }
            }
        })
    }
    else if (category === "item" && capacity?.landBase?.buildings?.buildings && capacity?.landBase?.buildings?.buildings_land && capacity?.landBase?.buildings?.buildings_close) {
        capacity.landBase.buildings.buildings_land.forEach((building_index, index) => {
            if (filters.includes("|" + building_index + "|")) {
                buildings_footrpints.push(capacity.landBase.buildings.buildings[building_index]);
            }
        })
    }
    console.log("buildings_footrpints", buildings_footrpints);

    // Create union
    var result = [];

    result = buildings_footrpints;
    console.log("result", result);


    var lands_result = [];
    for (var i = 0; i < result.length; i++) {
        if (result[i].geometry.type === "Polygon") {
            // Get local land coordinates
            var localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, result[i].geometry.coordinates[0]);
            // Get shape from global coords
            var landShape = getShapeFromPoints(localCoords, result[i].geometry.coordinates[0]);
            // Get data
            landShape.userData.height = result[i]?.properties?.height_max || 0;
            // Push
            lands_result.push(landShape);
        }
        else if (result[i].geometry.type === "MultiPolygon") {
            for (var j = 0; j < result[i].geometry.coordinates.length; j++) {
                // Get local land coordinates
                var localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, result[i].geometry.coordinates[j][0]);
                // Get shape from global coords
                var landShape = getShapeFromPoints(localCoords, result[i].geometry.coordinates[j][0]);
                // Get data
                landShape.userData.height = result[i]?.properties?.height_max || 0;
                // Push
                lands_result.push(landShape);
            }
        }
    }
    console.log("lands_result", lands_result);



    return lands_result;
}

function nodeInterpreted_Node_Study_PLUZone(nodeData, capacity, nodePerimeter) {

    // Get bounds
    var lands = [];
    capacity.rules.gpu_data.zoneUrba.forEach(land => {
        if (nodeData[0].value.includes("|" + land.id + "|")) {
            lands.push(land);
        }
    })
    console.log("lands", lands);

    // Create union
    var result = [];
    if (lands.length > 1) {
        var polyLand = null;
        if (lands[0]["geometry"]["type"] === "MultiPolygon") {
            polyLand = turf.multiPolygon(lands[0]["geometry"]["coordinates"]);
        }
        else {
            polyLand = turf.polygon(lands[0]["geometry"]["coordinates"]);
        }
        for (var i = 1; i < lands.length; i++) {
            var poly2 = null;
            if (lands[i]["geometry"]["type"] === "MultiPolygon") {
                poly2 = turf.multiPolygon(lands[i]["geometry"]["coordinates"]);
            }
            else {
                poly2 = turf.polygon(lands[i]["geometry"]["coordinates"]);
            }
            polyLand = turf.union(polyLand, poly2);
        }
        result.push(polyLand);
    }
    else if (lands.length === 1) {
        var polyLand = null;
        if (lands[0]["geometry"]["type"] === "MultiPolygon") {
            polyLand = turf.multiPolygon(lands[0]["geometry"]["coordinates"]);
        }
        else {
            polyLand = turf.polygon(lands[0]["geometry"]["coordinates"]);
        }
        result.push(polyLand);
    }
    console.log("result", result);


    var lands_result = [];
    for (var i = 0; i < result.length; i++) {
        if (result[i].geometry.type === "Polygon") {
            // Get local land coordinates
            var localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, result[i].geometry.coordinates[0]);
            // Get shape from global coords
            var landShape = getShapeFromPoints(localCoords, result[i].geometry.coordinates[0]);
            // Push
            lands_result.push(landShape);
        }
        else if (result[i].geometry.type === "MultiPolygon") {
            for (var j = 0; j < result[i].geometry.coordinates.length; j++) {
                // Get local land coordinates
                var localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, result[i].geometry.coordinates[j][0]);
                // Get shape from global coords
                var landShape = getShapeFromPoints(localCoords, result[i].geometry.coordinates[j][0]);
                // Push
                lands_result.push(landShape);
            }
        }
    }
    console.log("lands_result", lands_result);



    return lands_result;
}

function nodeInterpreted_Node_Study_Perimeter(nodeData, capacity, nodePerimeter) {
    console.log("nodePerimeter", nodePerimeter);
    var perimeter = getPerimeter(nodePerimeter, capacity);

    return perimeter;
}

function nodeInterpreted_Node_Study_Buildable_Height(nodeData, capacity, nodePerimeter) {
    // Get height
    var height = 3;
    if (capacity?.buildable?.volume?.parameters?.max_height) {
        height = capacity?.buildable?.volume?.parameters?.max_height + 0.02;
    }

    return height;
}

function nodeInterpreted_Node_Study_Buildings_Height(nodeData, capacity, nodePerimeter) {
    // Get height
    var height = -1000;

    return height;
}

function nodeInterpreted_Node_Study_Building_Area_Total(nodeData, capacity, nodePerimeter) {
    // Get area
    var area = 1;

    return area;
}

function nodeInterpreted_Node_Study_Levels(nodeData, capacity, nodePerimeter) {

    const levels_list = [
        { id: "ground", label: "Rez-de-Chaussée" },
        { id: "current", label: "Etages courants" },
        { id: "top", label: "Dernier étage" },
        { id: "sublevels", label: "Niveaux de sous-sol" },
    ];

    // Get levels
    var levels = [];
    levels_list.forEach(level => {
        if (nodeData[0].value.includes("|" + level.id + "|")) {
            levels.push(level.id);
        }
    })
    console.log("levels", levels);

    return levels

}

function nodeInterpreted_Node_Study_BoundTypes(nodeData, capacity, nodePerimeter) {

    //console.log("INTERPRETATION OF BOUNDS");
    // Get bounds
    var bounds = [];
    capacity.landBase.union.bounds_groups.forEach(bound_group => {
        if (nodeData[0].value.includes("|" + bound_group.context_analysis.result.subtype + "|")) {
            bound_group.bounds.forEach(bound_index => {
                bounds.push(capacity.landBase.union.bounds[bound_index]);
            })
        }
    })
    //console.log("BOUNDS", bounds);

    // Get lines
    var lines = [];
    var line = [];
    bounds.forEach((bound, index) => {
        if (index === 0 || bound.start_coord_global[0] !== line[line.length - 1][0] || bound.start_coord_global[1] !== line[line.length - 1][1]) {
            // Push line to list
            if (line.length > 0) {
                lines.push(line);
            }
            // New line
            line = [bound.start_coord_global];
        }
        // Add end point of line
        line.push(bound.end_coord_global);
        if (index === (bounds.length - 1)) {
            lines.push(line);
        }
    })
    //console.log("LINES", lines);

    // Get line geometries
    var line_geometries = [];
    lines.forEach(line_coords => {
        var points = [];
        line_coords.forEach(line_coord => {
            var line_localCoord = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, [line_coord])[0];
            points.push(new THREE.Vector3(line_localCoord[0], line_localCoord[1], 0));
        })
        var three_geom = new THREE.CatmullRomCurve3(points);
        three_geom.curveType = 'catmullrom';
        three_geom.tension = 0;
        three_geom.userData = { turfData: turf.lineString(line_coords) };
        line_geometries.push(three_geom);
    })
    //console.log("LINE GEOM", line_geometries);

    return line_geometries;
    // return line_geometries[0];
}

function nodeInterpreted_Node_Study_BoundItems(nodeData, capacity, nodePerimeter) {

    //console.log("INTERPRETATION OF BOUNDS");
    // Get bounds
    var bounds = [];
    capacity.landBase.union.bounds_groups.forEach(bound_group => {
        if (nodeData[0].value.includes("|" + bound_group.context_analysis.result.subtype + "|")) {
            bound_group.bounds.forEach(bound_index => {
                bounds.push(capacity.landBase.union.bounds[bound_index]);
            })
        }
    })
    //console.log("BOUNDS", bounds);

    // Get lines
    var lines = [];
    var line = [];
    bounds.forEach((bound, index) => {
        if (index === 0 || bound.start_coord_global[0] !== line[line.length - 1][0] || bound.start_coord_global[1] !== line[line.length - 1][1]) {
            // Push line to list
            if (line.length > 0) {
                lines.push(line);
            }
            // New line
            line = [bound.start_coord_global];
        }
        // Add end point of line
        line.push(bound.end_coord_global);
        if (index === (bounds.length - 1)) {
            lines.push(line);
        }
    })
    //console.log("LINES", lines);

    // Get line geometries
    var line_geometries = [];
    lines.forEach(line_coords => {
        var points = [];
        line_coords.forEach(line_coord => {
            var line_localCoord = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, [line_coord])[0];
            points.push(new THREE.Vector3(line_localCoord[0], line_localCoord[1], 0));
        })
        var three_geom = new THREE.CatmullRomCurve3(points);
        three_geom.curveType = 'catmullrom';
        three_geom.tension = 0;
        three_geom.userData = { turfData: turf.lineString(line_coords) };
        line_geometries.push(three_geom);
    })
    //console.log("LINE GEOM", line_geometries);

    return line_geometries;
    // return line_geometries[0];
}

function nodeInterpreted_Node_Study_Bounds(nodeData, capacity, nodePerimeter) {

    var detailedLines = detailedLandBase;
    detailedLines = false;

    // Get values
    var category = nodeData[0].value;
    if (category.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        category = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
        //console.log("INTERPRETED CATEGORY", category);
    }

    var filters = nodeData[1].value;
    if (filters.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        filters = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
        //console.log("INTERPRETED FILTERS", filters);
    }

    //console.log("INTERPRETATION OF BOUNDS");
    // Get bounds
    var bounds = [];
    capacity.landBase.union.bounds_groups.forEach(bound_group => {
        if (category === "type" && filters.includes("|" + bound_group.context_analysis.result.subtype + "|")) {
            if (detailedLines) {
                bound_group.bounds.forEach(bound_index => {
                    bounds.push(capacity.landBase.union.bounds[bound_index]);
                })
            }
            else {
                var bound = bound_group;
                bound_group.start_angle = capacity.landBase.union.bounds[bound_group.bounds[0]].start_angle;
                bound_group.end_angle = capacity.landBase.union.bounds[bound_group.bounds[bound_group.bounds.length - 1]].end_angle;
                bounds.push(bound);
            }
        }
        else if (category === "item" && filters.includes("|" + bound_group._id + "|")) {
            if (detailedLines) {
                bound_group.bounds.forEach(bound_index => {
                    bounds.push(capacity.landBase.union.bounds[bound_index]);
                })
            }
            else {
                var bound = bound_group;
                bound_group.start_angle = capacity.landBase.union.bounds[bound_group.bounds[0]].start_angle;
                bound_group.end_angle = capacity.landBase.union.bounds[bound_group.bounds[bound_group.bounds.length - 1]].end_angle;
                bounds.push(bound);
            }
        }
    })
    console.log("BOUNDS", bounds);

    // Get lines
    var lines = [];
    var line = [];
    var continuous_lines = [];
    var continuous_line = [];
    var extended_lines = [];
    var extended_line = [];
    bounds.forEach((bound, index) => {
        // PRECISE VERSION
        if (index === 0 || bound.start_coord_global[0] !== continuous_line[continuous_line.length - 1][0] || bound.start_coord_global[1] !== continuous_line[continuous_line.length - 1][1]) {
            // Push line to list
            if (continuous_line.length > 0) {
                // Check if end_angle is between 130 & 90
                if (bound.end_angle >= 90 && bound.end_angle < 130) {
                    // Get line longer on end direction
                    var coords = turf.getCoord(turf.destination(turf.point(bound.end_coord_global), 0.01, turf.bearing(turf.point(bound.start_coord_global), turf.point(bound.end_coord_global))));
                    continuous_line[continuous_line.length - 1] = coords;
                }
                continuous_lines.push(continuous_line);
            }
            // New line
            continuous_line = [bound.start_coord_global];
            // Check if start_angle is between 130 & 90
            if (bound.start_angle >= 90 && bound.start_angle < 130) {
                // Get line longer on start direction
                var coords = turf.getCoord(turf.destination(turf.point(bound.start_coord_global), 0.01, turf.bearing(turf.point(bound.end_coord_global), turf.point(bound.start_coord_global))));
                continuous_line = [coords];
            }
        }
        // Add end point of line
        continuous_line.push(bound.end_coord_global);
        if (index === (bounds.length - 1)) {
            // Check if end_angle is between 130 & 90
            if (bound.end_angle >= 90 && bound.end_angle < 130) {
                // Get line longer on end direction
                var coords = turf.getCoord(turf.destination(turf.point(bound.end_coord_global), 0.01, turf.bearing(turf.point(bound.start_coord_global), turf.point(bound.end_coord_global))));
                continuous_line[continuous_line.length - 1] = coords;
            }
            continuous_lines.push(continuous_line);
        }

        // SIMPLE VERSION
        line = [bound.start_coord_global, bound.end_coord_global];
        lines.push(line);
        // EXTENDED VERSION
        var extended_line = [bound.start_coord_global, bound.end_coord_global];
        // Test start
        if (bound.start_angle > 90 && bound.start_angle < 180) {
            var isExtended = true;
            if (bounds.length > 1) {
                var index_prev = index - 1;
                if (index_prev < 0) { index_prev = bounds.length - 1 }
                if (bound.start_coord_global[0] === bounds[index_prev].end_coord_global[0] && bound.start_coord_global[1] === bounds[index_prev].end_coord_global[1]) {
                    isExtended = false;
                }
            }
            if (isExtended === true) {
                var coords = turf.getCoord(turf.destination(turf.point(bound.start_coord_global), 0.05, turf.bearing(turf.point(bound.end_coord_global), turf.point(bound.start_coord_global))));
                extended_line[0] = coords;
            }
        }
        // Test end
        if (bound.end_angle > 90 && bound.end_angle < 180) {
            var isExtended = true;
            if (bounds.length > 1) {
                var index_next = index + 1;
                if (index_next > bounds.length - 1) { index_next = 0 }
                if (bound.end_coord_global[0] === bounds[index_next].start_coord_global[0] && bound.end_coord_global[1] === bounds[index_next].start_coord_global[1]) {
                    isExtended = false;
                }
            }
            if (isExtended === true) {
                var coords = turf.getCoord(turf.destination(turf.point(bound.end_coord_global), 0.05, turf.bearing(turf.point(bound.start_coord_global), turf.point(bound.end_coord_global))));
                extended_line[1] = coords;
            }
        }
        extended_lines.push(extended_line);

    })
    console.log("LINES", lines);
    console.log("CONTINUOUS LINES", continuous_lines);

    // Get line geometries
    var line_geometries = [];
    lines.forEach(line_coords => {
        var points = [];
        line_coords.forEach(line_coord => {
            var line_localCoord = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, [line_coord])[0];
            points.push(new THREE.Vector3(line_localCoord[0], line_localCoord[1], 0));
        })
        var three_geom = new THREE.CatmullRomCurve3(points);
        three_geom.curveType = 'catmullrom';
        three_geom.tension = 0;
        three_geom.userData = { turfData: turf.lineString(line_coords), isPropertyGrouped: true };
        line_geometries.push(three_geom);
    })
    console.log("LINE GEOM", line_geometries);

    // Get continuous line geometries
    var continuous_line_geometries = [];
    continuous_lines.forEach(line_coords => {
        var points = [];
        line_coords.forEach(line_coord => {
            var line_localCoord = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, [line_coord])[0];
            points.push(new THREE.Vector3(line_localCoord[0], line_localCoord[1], 0));
        })
        var three_geom = new THREE.CatmullRomCurve3(points);
        three_geom.curveType = 'catmullrom';
        three_geom.tension = 0;
        three_geom.userData = { turfData: turf.lineString(line_coords) };
        continuous_line_geometries.push(three_geom);
    })
    line_geometries[0].userData.continuous_lines = continuous_line_geometries;
    console.log("CONTINUOUS LINE GEOM", continuous_line_geometries);

    // Get extended line geometries
    var extended_line_geometries = [];
    extended_lines.forEach(line_coords => {
        var points = [];
        line_coords.forEach(line_coord => {
            var line_localCoord = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, [line_coord])[0];
            points.push(new THREE.Vector3(line_localCoord[0], line_localCoord[1], 0));
        })
        var three_geom = new THREE.CatmullRomCurve3(points);
        three_geom.curveType = 'catmullrom';
        three_geom.tension = 0;
        three_geom.userData = { turfData: turf.lineString(line_coords) };
        extended_line_geometries.push(three_geom);
    })
    line_geometries[0].userData.extended_lines = extended_line_geometries;
    console.log("EXTENDED LINE GEOM", extended_line_geometries);

    // Get extended lines


    return line_geometries;
    // return line_geometries[0];
}



//__________ 2D ELEMENTS

function nodeInterpreted_Node_2DElement_Point(nodeData, capacity, nodePerimeter) {
    console.log("nodeData", nodeData);

    // JSON NODE
    if (nodeData.length >= 1 && nodeData[0]?.value.includes("Node_2DElement_MapInput[")) {
        var nodeData_obj = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
        console.log("nodeData_obj", nodeData_obj);

        return nodeData_obj;
    }
    // BASIC NODE
    else if (nodeData.length === 2) {

        // Get values
        var x = nodeData[0].value;
        if (x.indexOf("Node_") === 0) {
            //console.log("TO INTERPRET");
            x = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
            //console.log("INTERPRETED SHAPE LENGTH", x);
        }

        var y = 0;
        if (nodeData.length >= 2) {
            y = nodeData[1].value;
            if (y.indexOf("Node_") === 0) {
                //console.log("TO INTERPRET");
                y = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
                //console.log("INTERPRETED SHAPE WIDTH", y);
            }
        }

        var shape = new THREE.Vector3(x, y, 0);

        var pointsLocal = [
            [x, y],
        ]

        // Get turf data
        var turfData = turf.point(getGlobalCoordinates(capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix, pointsLocal)[0]);
        turfData.properties = { isDefault: true };
        shape.userData = { turfData: turfData, coords: [x, y] };

        console.log("circle shape", shape);

        return [shape];

    }
    else {
        return []
    }
}

function nodeInterpreted_Node_2DElement_Line(nodeData, capacity, nodePerimeter) {
    console.log("nodeData", nodeData);

    // JSON NODE
    if (nodeData.length >= 1 && nodeData[0]?.value.includes("Node_2DElement_MapInput[")) {
        var nodeData_obj = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
        console.log("nodeData_obj", nodeData_obj);

        return nodeData_obj;
    }
    // BASIC NODE
    else if (nodeData.length === 2) {

        // Get values
        var pt1 = nodeData[0].value;
        if (pt1.indexOf("Node_") === 0) {
            //console.log("TO INTERPRET");
            pt1 = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
        }
        console.log("INTERPRETED LINE Pt1", pt1);

        var pt2 = nodeData[1].value;
        if (pt2.indexOf("Node_") === 0) {
            //console.log("TO INTERPRET");
            pt2 = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
        }
        console.log("INTERPRETED LINE Pt2", pt2);

        // Create shape
        var points = [
            new THREE.Vector3(pt1[0]?.userData?.coords[0], pt1[0]?.userData?.coords[1], 0),
            new THREE.Vector3(pt2[0]?.userData?.coords[0], pt2[0]?.userData?.coords[1], 0),
        ];
        var shape = new THREE.CatmullRomCurve3(points);
        shape.curveType = 'catmullrom';
        shape.tension = 0;

        var pointsLocal = [
            pt1[0]?.userData?.coords,
            pt2[0]?.userData?.coords,
        ]
        console.log("pointsLocal", pointsLocal);

        // Get turf data
        var turfData = turf.lineString(getGlobalCoordinates(capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix, pointsLocal));
        turfData.properties = { isDefault: true };
        shape.userData = { turfData: turfData, coords: pointsLocal };

        console.log("line shape", shape);

        return [shape];

    }
    else {
        return []
    }
}

function nodeInterpreted_Node_2DElement_Polygon(nodeData, capacity, nodePerimeter) {
    console.log("nodeData", nodeData);

    // JSON NODE
    if (nodeData.length >= 1 && nodeData[0]?.value.includes("Node_2DElement_MapInput[")) {
        var nodeData_obj = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
        console.log("nodeData_obj", nodeData_obj);

        return nodeData_obj;
    }
    // BASIC NODE
    else if (nodeData.length === 3) {

        // Get values
        var pt1 = nodeData[0].value;
        if (pt1.indexOf("Node_") === 0) {
            //console.log("TO INTERPRET");
            pt1 = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
        }
        console.log("INTERPRETED LINE Pt1", pt1);

        var pt2 = nodeData[1].value;
        if (pt2.indexOf("Node_") === 0) {
            //console.log("TO INTERPRET");
            pt2 = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
        }
        console.log("INTERPRETED LINE Pt2", pt2);

        var pt3 = nodeData[2].value;
        if (pt3.indexOf("Node_") === 0) {
            //console.log("TO INTERPRET");
            pt3 = nodeInterpretor([nodeData[2]], capacity, nodePerimeter);
        }
        console.log("INTERPRETED LINE Pt3", pt3);

        // Create shape
        var points = [
            new THREE.Vector2(pt1[0]?.userData?.coords[0], pt1[0]?.userData?.coords[1]),
            new THREE.Vector2(pt2[0]?.userData?.coords[0], pt2[0]?.userData?.coords[1]),
            new THREE.Vector2(pt3[0]?.userData?.coords[0], pt3[0]?.userData?.coords[1]),
            new THREE.Vector2(pt1[0]?.userData?.coords[0], pt1[0]?.userData?.coords[1]),
        ];
        var shape = new THREE.Shape(points);

        // get turfData
        var pointsLocal = [
            pt1[0]?.userData?.coords,
            pt2[0]?.userData?.coords,
            pt3[0]?.userData?.coords,
            pt1[0]?.userData?.coords, // Repeate first point to close the polygon
        ]
        console.log("pointsLocal", pointsLocal);

        // Get turf data
        var turfData = turf.polygon([getGlobalCoordinates(capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix, pointsLocal)]);
        turfData.properties = { isDefault: true };
        shape.userData = { turfData: turfData, coords: pointsLocal };

        console.log("polygon shape", shape);

        return [shape];

    }
    else {
        return []
    }
}

function nodeInterpreted_Node_2DElement_MapInput(nodeData, capacity, nodePerimeter) {
    console.log("nodeData", nodeData);

    var data = nodeData[0].value;

    var separation = data.indexOf("_");
    console.log("separation", separation);
    var type = data.substring(0, separation);
    console.log("type", type);
    var coords_list = JSON.parse(data.substring(separation + 1).replace(/\(/g, '[').replace(/\)/g, ']'));
    console.log("coords", coords_list);

    // var turf_list = [];
    var obj_list = [];
    coords_list.forEach(coords => {
        if (type === "point") {
            // turf_list.push(turf.point(coords));
            var local_point = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, [coords])[0];
            var shape = new THREE.Vector3(local_point[0], local_point[1], 0);
            shape.userData = { turfData: turf.point(coords) };
            obj_list.push(shape);
        }
        else if (type === "line") {
            // turf_list.push(turf.lineString(coords));
            var local_points = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, coords);
            var three_points = [];
            local_points.forEach(point => {
                three_points.push(new THREE.Vector3(point[0], point[1], 0));
            })
            var shape = new THREE.CatmullRomCurve3(three_points);
            shape.curveType = 'catmullrom';
            shape.tension = 0;
            shape.userData = { turfData: turf.lineString(coords) };
            obj_list.push(shape);
        }
        else if (type === "polygon") {
            // turf_list.push(turf.polygon(coords));
            var local_points = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, coords[0]);
            console.log("coords", coords);
            console.log("local_points", local_points);
            var three_points = [];
            local_points.forEach(point => {
                three_points.push(new THREE.Vector2(point[0], point[1]));
            })
            var shape = new THREE.Shape(three_points);
            shape.userData = { turfData: turf.polygon(coords) };
            obj_list.push(shape);
        }
    })

    return obj_list
}

function nodeInterpreted_Node_2DElement_Rectangle(nodeData, capacity, nodePerimeter) {
    // Get values
    var shapeLength = nodeData[0].value;
    if (shapeLength.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        shapeLength = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
        //console.log("INTERPRETED SHAPE LENGTH", shapeLength);
    }

    var shapeWidth = nodeData[1].value;
    if (shapeWidth.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        shapeWidth = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
        //console.log("INTERPRETED SHAPE WIDTH", shapeWidth);
    }

    var points = [
        new THREE.Vector2(0, 0),
        new THREE.Vector2(0, shapeLength),
        new THREE.Vector2(shapeWidth, shapeLength),
        new THREE.Vector2(shapeWidth, 0),
    ];

    var pointsLocal = [
        [0, 0],
        [0, shapeLength],
        [shapeWidth, shapeLength],
        [shapeWidth, 0],
        [0, 0]
    ]


    var shape = new THREE.Shape(points);
    // Get turf data
    var turfData = turf.polygon([getGlobalCoordinates(capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix, pointsLocal)]);
    shape.userData = { turfData: turfData };

    return shape;
}

function nodeInterpreted_Node_2DElement_Triangle(nodeData, capacity, nodePerimeter) {
    // Get values
    var shapeLength = nodeData[0].value;
    if (shapeLength.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        shapeLength = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
        //console.log("INTERPRETED SHAPE LENGTH", shapeLength);
    }

    var shapeWidth = nodeData[1].value;
    if (shapeWidth.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        shapeWidth = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
        //console.log("INTERPRETED SHAPE WIDTH", shapeWidth);
    }

    // Create shape
    // var points = [
    //     new THREE.Vector2(0, 0),
    //     new THREE.Vector2(-shapeLength, 0),
    //     new THREE.Vector2(-shapeLength, shapeWidth),
    // new THREE.Vector2(0, shapeWidth),
    // ];
    var points = [
        new THREE.Vector2(0, 0),
        new THREE.Vector2(0, shapeLength),
        new THREE.Vector2(shapeWidth, shapeLength),
        // new THREE.Vector2(shapeWidth, 0),
    ];

    var pointsLocal = [
        [0, 0],
        [0, shapeLength],
        [shapeWidth, shapeLength],
        [0, 0]
    ]


    var shape = new THREE.Shape(points);
    // Get turf data
    var turfData = turf.polygon([getGlobalCoordinates(capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix, pointsLocal)]);
    shape.userData = { turfData: turfData };

    return shape;
}

function nodeInterpreted_Node_2DElement_Trapeze(nodeData, capacity, nodePerimeter) {
    // Get values
    var shapeLength = nodeData[0].value;
    if (shapeLength.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        shapeLength = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
        //console.log("INTERPRETED SHAPE LENGTH", shapeLength);
    }

    var shapeWidth1 = nodeData[1].value;
    if (shapeWidth1.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        shapeWidth1 = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
        //console.log("INTERPRETED SHAPE WIDTH", shapeWidth1);
    }

    var shapeWidth2 = nodeData[2].value;
    if (shapeWidth2.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        shapeWidth2 = nodeInterpretor([nodeData[2]], capacity, nodePerimeter);
        //console.log("INTERPRETED SHAPE WIDTH", shapeWidth2);
    }

    // Create shape
    var points = [
        new THREE.Vector2(0, 0),
        new THREE.Vector2(0, shapeLength),
        new THREE.Vector2(shapeWidth1, shapeLength),
        new THREE.Vector2(shapeWidth2, 0),
    ];

    var pointsLocal = [
        [0, 0],
        [0, shapeLength],
        [shapeWidth1, shapeLength],
        [shapeWidth2, 0],
        [0, 0]
    ]


    var shape = new THREE.Shape(points);
    // Get turf data
    var turfData = turf.polygon([getGlobalCoordinates(capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix, pointsLocal)]);
    shape.userData = { turfData: turfData };

    return shape;
}

function nodeInterpreted_Node_2DElement_Trapeze_5(nodeData, capacity, nodePerimeter) {
    // Get values
    var H = nodeData[0].value;
    if (H.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        H = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
        //console.log("INTERPRETED SHAPE LENGTH", H);
    }

    var L1 = nodeData[1].value;
    if (L1.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        L1 = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
        //console.log("INTERPRETED SHAPE WIDTH", L1);
    }

    var L2 = nodeData[2].value;
    if (L2.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        L2 = nodeInterpretor([nodeData[2]], capacity, nodePerimeter);
        //console.log("INTERPRETED SHAPE WIDTH", L2);
    }

    var L3 = nodeData[3].value;
    if (L3.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        L3 = nodeInterpretor([nodeData[3]], capacity, nodePerimeter);
        //console.log("INTERPRETED SHAPE WIDTH", L3);
    }

    // Corrections
    if (L2 > L1) { L2 = L1 }
    if (L2 < L3) { L2 = L3 }

    // Create shape
    var points = [
        new THREE.Vector2(0, 0),
        new THREE.Vector2(0, H),
        new THREE.Vector2(L1, H),
        new THREE.Vector2(L2, ((L2 - L3) * H) / (L1 - L3)),
        new THREE.Vector2(L2, 0),
        new THREE.Vector2(L3, 0)
    ];

    var pointsLocal = [
        [0, 0],
        [0, H],
        [L1, H],
        [L2, ((L2 - L3) * H) / (L1 - L3)],
        [L2, 0],
        [L3, 0],
        [0, 0]
    ]


    var shape = new THREE.Shape(points);
    // Get turf data
    var turfData = turf.polygon([getGlobalCoordinates(capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix, pointsLocal)]);
    shape.userData = { turfData: turfData };

    return shape;
}

function nodeInterpreted_Node_2DElement_Trapeze_6(nodeData, capacity, nodePerimeter) {
    // Get values
    var H = nodeData[0].value;
    if (H.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        H = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
        //console.log("INTERPRETED SHAPE LENGTH", H);
    }

    var L1 = nodeData[1].value;
    if (L1.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        L1 = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
        //console.log("INTERPRETED SHAPE WIDTH", L1);
    }

    var L2 = nodeData[2].value;
    if (L2.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        L2 = nodeInterpretor([nodeData[2]], capacity, nodePerimeter);
        //console.log("INTERPRETED SHAPE WIDTH", L2);
    }

    var L3 = nodeData[3].value;
    if (L3.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        L3 = nodeInterpretor([nodeData[3]], capacity, nodePerimeter);
        //console.log("INTERPRETED SHAPE WIDTH", L3);
    }

    var L4 = nodeData[4].value;
    if (L4.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        L4 = nodeInterpretor([nodeData[4]], capacity, nodePerimeter);
        //console.log("INTERPRETED SHAPE WIDTH", L4);
    }

    // Corrections
    if (L2 > L1) { L2 = L1 }
    if (L3 > L2) { L3 = L2 }
    if (L3 < L4) { L3 = L4 }

    // Create shape
    var points = [
        new THREE.Vector2(0, 0),
        new THREE.Vector2(0, H),
        new THREE.Vector2(L2, H),
        new THREE.Vector2(L2, ((L2 - L4) * H) / (L1 - L4)),
        new THREE.Vector2(L3, ((L3 - L4) * H) / (L1 - L4)),
        new THREE.Vector2(L3, 0),
        new THREE.Vector2(L4, 0)
    ];

    var pointsLocal = [
        [0, 0],
        [0, H],
        [L2, H],
        [L2, ((L2 - L4) * H) / (L1 - L4)],
        [L3, ((L3 - L4) * H) / (L1 - L4)],
        [L3, 0],
        [L4, 0],
        [0, 0]
    ]


    var shape = new THREE.Shape(points);
    // Get turf data
    var turfData = turf.polygon([getGlobalCoordinates(capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix, pointsLocal)]);
    shape.userData = { turfData: turfData };

    return shape;
}











//__________ RULES

function nodeInterpreted_Node_Rule_Unbuildable_Volume(nodeData, capacity, nodePerimeter) {

    // Get values
    var isPerimeterRestricted = nodeData[1].value;
    if (isPerimeterRestricted.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        isPerimeterRestricted = nodeInterpretor([nodeData[1]], capacity, nodePerimeter);
    }
    console.log("isPerimeterRestricted", isPerimeterRestricted);

    var volume = nodeData[2].value;
    if (volume.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        volume = nodeInterpretor([nodeData[2]], capacity, nodePerimeter);
    }
    if (!Array.isArray(volume)) {
        volume = [volume];
    }
    console.log("INTERPRETED VOLUME", volume);

    // Get Perimeter
    // console.log("nodePerimeter", nodePerimeter);
    var perimeter = getPerimeter(nodePerimeter, capacity);
    // if (isPerimeterRestricted === false) {
    //     perimeter = null;
    // }
    console.log("perimeter", perimeter);

    // Create material
    var material = new THREE.MeshPhongMaterial({
        color: '#FF0000',
        transparent: true,
        opacity: 0.3,
        // wireframe: true
    });

    // Create meshes and edges
    var meshList = [];
    var edgesList = [];

    var area_total = 0;

    volume.forEach(item => {
        if (item !== undefined) {
            var mesh = new THREE.Mesh(item, material);
            var area = 0;
            // var allMesh = [mesh];
            var allMesh = [];
            mesh.updateMatrix();
            if (perimeter !== null) {
                if (Array.isArray(perimeter)) {
                    var allCSGMesh = [];
                    perimeter.forEach(peri => {
                        if (peri?.type === "Mesh") {
                            if (!isPerimeterRestricted === false) {
                                peri.updateMatrix();
                                var csgMesh = null;
                                if (mesh.geometry.type === "LatheGeometry") {
                                    // var boxMesh = new THREE.Mesh(item.userData.box, material);
                                    // boxMesh.updateMatrix();
                                    // var angleMesh = CSG.subtract(boxMesh, mesh);
                                    // angleMesh.updateMatrix();
                                    // angleMesh = CSG.intersect(peri, angleMesh);


                                    // // Adapt points
                                    // if (item?.userData?.lines && item.userData.lines.length > 0) {
                                    //     for (var line_i = 0; line_i < item.userData.lines.length; line_i++) {

                                    //         // angleMesh = CSG.union(angleMesh, meshList[item.userData.lines[line_i]]);
                                    //         // item.userData.lines[line_i] = null;
                                    //         // console.log("union");
                                            
                                           
                                    //         // var check_points = meshList[item.userData.lines[line_i]].geometry.attributes.position.array;
                                    //         // for (var i = 0; i < angleMesh.geometry.attributes.position.array.length; i += 3) {
                                    //         //     var delta = 0.05;
                                    //         //     var found = false;
                                    //         //     // console.log("CHECK POINTS", check_points);
                                    //         //     for (var j = 0; j < check_points.length; j += 3) {
                                    //         //         // Test x
                                    //         //         if (angleMesh.geometry.attributes.position.array[i] >= check_points[j] - delta && angleMesh.geometry.attributes.position.array[i] <= check_points[j] + delta) {
                                    //         //             // Test y
                                    //         //             if (angleMesh.geometry.attributes.position.array[i+1] >= check_points[j+1] - delta && angleMesh.geometry.attributes.position.array[i+1] <= check_points[j+1] + delta) {
                                    //         //                 // Test z
                                    //         //                 if (angleMesh.geometry.attributes.position.array[i+2] >= check_points[j+2] - delta && angleMesh.geometry.attributes.position.array[i+2] <= check_points[j+2] + delta) {
                                    //         //                     console.log("CORRECTION", i, j);
                                    //         //                     console.table({current: [angleMesh.geometry.attributes.position.array[i], angleMesh.geometry.attributes.position.array[i+1], angleMesh.geometry.attributes.position.array[i+2]], check: [check_points[j], check_points[j+1], check_points[j+2]]})
                                    //         //                     angleMesh.geometry.attributes.position.array[i] = check_points[j];
                                    //         //                     angleMesh.geometry.attributes.position.array[i+1] = check_points[j+1];
                                    //         //                     angleMesh.geometry.attributes.position.array[i+2] = check_points[j+2];
                                    //         //                     found = true;
                                    //         //                     break;
                                    //         //                 }
                                    //         //             }
                                    //         //         }
                                    //         //     }
                                    //         //     if (found === true) { break }
                                    //         // }
                                    //     }
                                    // }

                                    // // csgMesh = CSG.intersect(peri, angleMesh);
                                    // csgMesh = angleMesh;
                                    // csgMesh.material = material;
                                }
                                else {
                                    csgMesh = CSG.intersect(mesh, peri);
                                }
                                if (csgMesh !== null) {
                                    allCSGMesh.push(csgMesh);
                                }
                            }
                            else {
                                allCSGMesh.push(mesh);
                            }
                            // area
                            if (item?.userData?.area > 0) {
                                var intersection = turf.intersect(item?.userData?.turfData, peri?.userData?.turfData_union);
                                if (intersection !== null) {
                                    area += turf.area(intersection);
                                }
                            }
                        }
                    })
                    if (allCSGMesh.length > 0) {
                        allMesh = allCSGMesh;
                    }
                }
                else {
                    var peri = perimeter;
                    if (peri?.type === "Mesh") {
                        if (!isPerimeterRestricted === false) {
                            peri.updateMatrix();
                            var csgMesh = CSG.intersect(mesh, peri);
                            allMesh = [csgMesh];
                        }
                        // area
                        if (item?.userData?.area > 0) {
                            var intersection = turf.intersect(item?.userData?.turfData, peri?.userData?.turfData_union);
                            if (intersection !== null) {
                                area += turf.area(intersection);
                            }
                        }
                    }
                }
            }

            meshList = meshList.concat(allMesh);

            // var edges = getEdgesfromGeometry(item, '#1ecd97');
            // edgesList.push(edges);
            var allEdges = [];
            allMesh.forEach(mesh => {
                allEdges.push(getEdgesfromGeometry(mesh.geometry, '#FF0000'));
            })
            edgesList = edgesList.concat(allEdges);

            // Add perimeter Surface
            // if (perimeterSurface !== null) {
            //     meshList = meshList.concat(perimeterSurface);
            // }

            // Result
            area_total += area;

        }
    })

    meshList[0].userData.result = area_total.toFixed(2);



    var result = meshList.concat(edgesList);


    // Send result
    return result;
}

function nodeInterpreted_Node_Rule_Buildable_Volume(nodeData, capacity, nodePerimeter) {

    // Get values
    var volume = nodeData[3].value;
    if (volume.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        volume = nodeInterpretor([nodeData[3]], capacity, nodePerimeter);
        console.log("INTERPRETED VOLUME", volume);
    }
    if (!Array.isArray(volume)) {
        volume = [volume];
    }

    // Get Perimeter
    // console.log("nodePerimeter", nodePerimeter);
    var perimeter = getPerimeter(nodePerimeter, capacity);
    // console.log("perimeter", perimeter);

    // Create material
    var material = new THREE.MeshPhongMaterial({
        color: '#1ecd97',
        transparent: true,
        opacity: 0.3,
        // wireframe: true
    });

    // Create meshes and edges
    var meshList = [];
    var edgesList = [];
    if (Array.isArray(volume)) {
        volume.forEach(item => {
            var depth = item?.parameters?.options?.depth;
            if (item !== undefined) {
                var mesh = new THREE.Mesh(item, material);
                var allMesh = [mesh];
                mesh.updateMatrix();
                console.log("Peri intersect");
                if (perimeter !== null) {
                    if (Array.isArray(perimeter)) {
                        var allCSGMesh = [];
                        perimeter.forEach(peri => {
                            if (peri?.type === "Mesh") {
                                peri.updateMatrix();
                                var csgMesh = CSG.intersect(mesh, peri);
                                allCSGMesh.push(csgMesh);
                            }
                        })
                        if (allCSGMesh.length > 0) {
                            allMesh = allCSGMesh;
                        }
                    }
                    else {
                        var peri = perimeter;
                        if (peri?.type === "Mesh") {
                            peri.updateMatrix();
                            var csgMesh = CSG.intersect(mesh, peri);
                            allMesh = [csgMesh];
                        }
                    }
                }

                allMesh.forEach(cMesh => {
                    cMesh.userData.depth = depth;
                })

                meshList = meshList.concat(allMesh);

                // var edges = getEdgesfromGeometry(item, '#1ecd97');
                // edgesList.push(edges);
                var allEdges = [];
                allMesh.forEach(mesh => {
                    allEdges.push(getEdgesfromGeometry(mesh.geometry, '#1ecd97'));
                })
                edgesList = edgesList.concat(allEdges);

                // Add perimeter Surface
                // if (perimeterSurface !== null) {
                //     meshList = meshList.concat(perimeterSurface);
                // }
            }
        })
    }
    else {
        var mesh = new THREE.Mesh(volume, material);
        mesh.userData.depth = volume?.parameters?.options?.depth;
        meshList.push(mesh);
        var edges = getEdgesfromGeometry(volume, '#1ecd97');
        edgesList.push(edges);
    }

    var result = meshList.concat(edgesList);


    // Send result
    return result;
}

function nodeInterpreted_Node_Rule_Buildable_Area(nodeData, capacity, nodePerimeter) {

    // Get values
    var isSuperStructure = nodeData[2].value;
    if (isSuperStructure.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        isSuperStructure = nodeInterpretor([nodeData[2]], capacity, nodePerimeter);
        console.log("INTERPRETED isSuperStructure", isSuperStructure);
    }

    var isInfraStructure = nodeData[3].value;
    if (isInfraStructure.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        isInfraStructure = nodeInterpretor([nodeData[3]], capacity, nodePerimeter);
        console.log("INTERPRETED isInfraStructure", isInfraStructure);
    }

    var area = nodeData[4].value;
    if (area.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        area = nodeInterpretor([nodeData[4]], capacity, nodePerimeter, nodePerimeter);
        console.log("INTERPRETED area", area);
    }

    // Get Perimeter
    // console.log("nodePerimeter", nodePerimeter);
    var perimeter = getPerimeter(nodePerimeter, capacity);
    // console.log("perimeter", perimeter);

    // Strips
    var perimeterArea = null;
    if (perimeter[0]?.userData?.turfData) {
        perimeterArea = turf.area(perimeter[0].userData.turfData);
    }
    var stripRatio = 0.5;
    if (perimeterArea !== null && area !== null) { stripRatio = area / perimeterArea }
    var color = "#1ecd97";
    if (stripRatio <= 0) {
        color = "#FF0000";
        stripRatio = 1;
    }
    var strips = getBboxMeshStrip(capacity, stripRatio, color);

    // Create material
    var material = new THREE.MeshPhongMaterial({
        color: '#FF0000',
        transparent: true,
        opacity: 0.3,
        // wireframe: true
    });

    // Create meshes and edges
    var meshList = [];
    var edgesList = [];
    perimeter.forEach(peri => {
        if (peri?.type === "Mesh") {
            strips[0].updateMatrix();
            peri.updateMatrix();
            var csgMesh = CSG.intersect(strips[0], peri);
            meshList.push(csgMesh);
            // var edges = getEdgesfromGeometry(peri.geometry, '#1ecd97');
            // edgesList.push(edges);
        }
    })

    // Add userData
    if (meshList.length > 0) {
        meshList[0].userData = {
            isSuper: isSuperStructure,
            isInfra: isInfraStructure,
            availableArea: perimeterArea,
            buildableArea: area,
            result: helpers.get_pretty_num(area.toFixed(2)),
            turfData: perimeter[0]?.userData?.turfData
        }
    }


    var result = meshList.concat(edgesList);


    return result;
}

function nodeInterpreted_Node_Rule_BoundAlign(nodeData, capacity, nodePerimeter) {

    // Get values
    var lines = nodeData[2].value;
    if (lines.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        lines = nodeInterpretor([nodeData[2]], capacity, nodePerimeter);
        console.log("INTERPRETED lines", lines);
    }

    var alignAll = nodeData[3].value;
    if (alignAll.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        alignAll = nodeInterpretor([nodeData[3]], capacity, nodePerimeter);
        console.log("INTERPRETED alignAll", alignAll);
    }

    var facadeMain = nodeData[4].value;
    if (facadeMain.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        facadeMain = nodeInterpretor([nodeData[4]], capacity, nodePerimeter);
        console.log("INTERPRETED facadeMain", facadeMain);
    }

    var facadeSecond = nodeData[5].value;
    if (facadeSecond.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        facadeSecond = nodeInterpretor([nodeData[5]], capacity, nodePerimeter);
        console.log("INTERPRETED facadeSecond", facadeSecond);
    }

    var offset = nodeData[6].value;
    if (offset.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        offset = nodeInterpretor([nodeData[6]], capacity, nodePerimeter);
        console.log("INTERPRETED offset", offset);
    }

    var offset_max = nodeData[7].value;
    if (offset_max.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        offset_max = nodeInterpretor([nodeData[7]], capacity, nodePerimeter);
        console.log("INTERPRETED offset_max", offset_max);
    }

    // Corrections to avoid bad comportments
    if (offset < 0) {
        offset = 0;
    }
    if (offset_max < 0) {
        offset_max = 0;
    }
    if (offset_max > 0 && offset_max < offset) {
        offset_max = 0;
    }
    if (offset_max > 0 && offset_max === offset) {
        offset_max = offset + 0.1;
    }

    // Get Perimeter
    // console.log("nodePerimeter", nodePerimeter);
    var perimeter = getPerimeter(nodePerimeter, capacity);
    console.log("perimeter", perimeter);

    // 2D surfaces
    var bounds_buffers_line = [];
    var bounds_buffers_offset_min = [];
    var bounds_buffers_offset_max = [];
    var bounds_buffer_three = [];
    for (var i = 0; i < lines.length; i++) {
        if (lines[i]?.userData?.turfData) {

            // ____Offset Min
            var buffer_offset = null;
            if (offset > 0) {
                var buffer = turf.buffer(lines[i]?.userData?.turfData, offset / 1000, { steps: 1 });
                if (perimeter[0]?.userData?.turfData_union) {
                    buffer = turf.intersect(buffer, perimeter[0]?.userData?.turfData_union);
                }
                else if (perimeter[0]?.userData?.turfData) {
                    buffer = turf.intersect(buffer, perimeter[0]?.userData?.turfData);
                }
                if (buffer !== null && (buffer?.geometry?.type === "Polygon" || buffer?.geometry?.type === "MultiPolygon")) {
                    buffer_offset = buffer;
                    bounds_buffers_offset_min.push(buffer);
                    // Get shape & geometry
                    if (buffer?.geometry?.type === "Polygon") {
                        var localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, buffer?.geometry?.coordinates[0]);
                        var shape = getShapeFromPoints(localCoords, buffer?.geometry?.coordinates[0]);
                        var geometry = getExtrusionFromShape(shape, 0.011);
                        var material = new THREE.MeshPhongMaterial({
                            color: 'orange',
                            transparent: true,
                            opacity: 0.2,
                            depthWrite: false,
                            // wireframe: true
                        });
                        var mesh = new THREE.Mesh(geometry, material);
                        bounds_buffer_three.push(mesh);
                    }
                    else if (buffer?.geometry?.type === "MultiPolygon") {
                        for (var k = 0; k < buffer?.geometry?.coordinates.length; k++) {
                            var localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, buffer?.geometry?.coordinates[k][0]);
                            var shape = getShapeFromPoints(localCoords, buffer?.geometry?.coordinates[k][0]);
                            var geometry = getExtrusionFromShape(shape, 0.011);
                            var material = new THREE.MeshPhongMaterial({
                                color: 'orange',
                                transparent: true,
                                opacity: 0.2,
                                depthWrite: false,
                                // wireframe: true
                            });
                            var mesh = new THREE.Mesh(geometry, material);
                            bounds_buffer_three.push(mesh);
                        }
                    }
                }
            }

            // ____Offset Max
            if (offset_max > 0) {
                var buffer = turf.buffer(lines[i]?.userData?.turfData, offset_max / 1000, { steps: 1 });
                if (perimeter[0]?.userData?.turfData_union) {
                    buffer = turf.intersect(buffer, perimeter[0]?.userData?.turfData_union);
                }
                else if (perimeter[0]?.userData?.turfData) {
                    buffer = turf.intersect(buffer, perimeter[0]?.userData?.turfData);
                }
                if (buffer !== null && buffer_offset !== null) {
                    buffer = turf.difference(buffer, buffer_offset);
                }

                // Detailed lines
                if (lines[i]?.userData?.turfData?.geometry?.coordinates.length > 2) {
                    var detailed_lines = [];
                    for (var j = 1; j < lines[i]?.userData?.turfData?.geometry?.coordinates.length; j++) {
                        detailed_lines.push(turf.lineString([lines[i]?.userData?.turfData?.geometry?.coordinates[j - 1], lines[i]?.userData?.turfData?.geometry?.coordinates[j]]));
                    }
                    detailed_lines.forEach(detailed_line => {
                        var buffer2 = turf.buffer(detailed_line, offset_max / 1000, { steps: 1 });
                        if (perimeter[0]?.userData?.turfData) {
                            buffer2 = turf.intersect(buffer2, perimeter[0]?.userData?.turfData);
                        }
                        if (buffer2 !== null && buffer_offset !== null) {
                            buffer2 = turf.difference(buffer2, buffer_offset);
                        }
                        if (buffer2 !== null) {
                            var bearing = turf.bearing(turf.point(detailed_line?.geometry?.coordinates[0]), detailed_line?.geometry?.coordinates[1]);
                            if (bearing < 0) { bearing += 180 };
                            buffer2.properties.bearing = bearing;
                            bounds_buffers_offset_max.push(buffer2);
                        }
                    })
                }
                else {
                    if (buffer !== null) {
                        var bearing = turf.bearing(turf.point(lines[0]?.userData?.turfData?.geometry?.coordinates[0]), lines[0]?.userData?.turfData?.geometry?.coordinates[1]);
                        if (bearing < 0) { bearing += 180 };
                        buffer.properties.bearing = bearing;
                        bounds_buffers_offset_max.push(buffer);
                    }
                }


                if (buffer !== null && (buffer?.geometry?.type === "Polygon" || buffer?.geometry?.type === "MultiPolygon")) {
                    // Get shape & geometry
                    if (buffer?.geometry?.type === "Polygon") {
                        var localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, buffer?.geometry?.coordinates[0]);
                        var shape = getShapeFromPoints(localCoords, buffer?.geometry?.coordinates[0]);
                        var geometry = getExtrusionFromShape(shape, 0.01);
                        var material = new THREE.MeshPhongMaterial({
                            color: 'green',
                            transparent: true,
                            opacity: 0.2,
                            depthWrite: false,
                            // wireframe: true
                        });
                        var mesh = new THREE.Mesh(geometry, material);
                        bounds_buffer_three.push(mesh);
                    }
                    else if (buffer?.geometry?.type === "MultiPolygon") {
                        for (var k = 0; k < buffer?.geometry?.coordinates.length; k++) {
                            var localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, buffer?.geometry?.coordinates[k][0]);
                            var shape = getShapeFromPoints(localCoords, buffer?.geometry?.coordinates[k][0]);
                            var geometry = getExtrusionFromShape(shape, 0.01);
                            var material = new THREE.MeshPhongMaterial({
                                color: 'green',
                                transparent: true,
                                opacity: 0.2,
                                depthWrite: false,
                                // wireframe: true
                            });
                            var mesh = new THREE.Mesh(geometry, material);
                            bounds_buffer_three.push(mesh);
                        }
                    }
                }
            }

            //____Line
            // Get buffer
            var buffer = turf.buffer(lines[i]?.userData?.turfData, 0.0004, { steps: 1 });
            if (perimeter[0]?.userData?.turfData_union) {
                buffer = turf.intersect(buffer, perimeter[0]?.userData?.turfData_union);
            }
            else if (perimeter[0]?.userData?.turfData) {
                buffer = turf.intersect(buffer, perimeter[0]?.userData?.turfData);
            }

            // Detailed lines
            if (lines[i]?.userData?.turfData?.geometry?.coordinates.length > 2) {
                var detailed_lines = [];
                for (var j = 1; j < lines[i]?.userData?.turfData?.geometry?.coordinates.length; j++) {
                    detailed_lines.push(turf.lineString([lines[i]?.userData?.turfData?.geometry?.coordinates[j - 1], lines[i]?.userData?.turfData?.geometry?.coordinates[j]]));
                }
                detailed_lines.forEach(detailed_line => {
                    var buffer2 = turf.buffer(detailed_line, 0.0004, { steps: 1 });
                    if (perimeter[0]?.userData?.turfData) {
                        buffer2 = turf.intersect(buffer2, perimeter[0]?.userData?.turfData);
                    }
                    if (buffer2 !== null) {
                        bounds_buffers_line.push(buffer2);
                    }
                })
            }
            else {
                if (buffer !== null) {
                    bounds_buffers_line.push(buffer);
                }
            }

            if (buffer !== null && (buffer?.geometry?.type === "Polygon" || buffer?.geometry?.type === "MultiPolygon")) {
                // Get shape & geometry
                if (buffer?.geometry?.type === "Polygon") {
                    var localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, buffer?.geometry?.coordinates[0]);
                    var shape = getShapeFromPoints(localCoords, buffer?.geometry?.coordinates[0]);
                    var geometry = getExtrusionFromShape(shape, 0.012);
                    var material = new THREE.MeshPhongMaterial({
                        color: 'green',
                        // transparent: true,
                        // opacity: 1,
                        depthWrite: false,
                        // wireframe: true
                    });
                    var mesh = new THREE.Mesh(geometry, material);
                    bounds_buffer_three.push(mesh);
                }
                else if (buffer?.geometry?.type === "MultiPolygon") {
                    for (var k = 0; k < buffer?.geometry?.coordinates.length; k++) {
                        var localCoords = getLocalCoordinates(capacity.landBase.union.center.geometry.coordinates, buffer?.geometry?.coordinates[k][0]);
                        var shape = getShapeFromPoints(localCoords, buffer?.geometry?.coordinates[k][0]);
                        var geometry = getExtrusionFromShape(shape, 0.012);
                        var material = new THREE.MeshPhongMaterial({
                            color: 'green',
                            // transparent: true,
                            // opacity: 1,
                            depthWrite: false,
                            // wireframe: true
                        });
                        var mesh = new THREE.Mesh(geometry, material);
                        bounds_buffer_three.push(mesh);
                    }
                }
            }
        }
    }
    // console.log("bounds_buffer_three", bounds_buffer_three);

    // Lines turf
    var lines_inside_perimeter = [];
    lines.forEach(line => {
        lines_inside_perimeter = lines_inside_perimeter.concat(map_helpers.intersect_line_polygon(line.userData.turfData, perimeter[0]?.userData?.turfData_union).features)
    })


    // Add userData
    if (bounds_buffer_three.length > 0) {
        bounds_buffer_three[0].userData = {
            alignAll,
            bounds_buffers_line,
            bounds_buffers_offset_min,
            bounds_buffers_offset_max,
            facades: {
                main: facadeMain,
                second: facadeSecond
            },
            lines: lines_inside_perimeter
        }
    }

    return bounds_buffer_three;
}

function nodeInterpreted_Node_Rule_Buildable_Area_Total(nodeData, capacity, nodePerimeter) {

    // Get values
    var isSuperStructure = nodeData[2].value;
    if (isSuperStructure.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        isSuperStructure = nodeInterpretor([nodeData[2]], capacity, nodePerimeter);
        console.log("INTERPRETED isSuperStructure", isSuperStructure);
    }

    var isInfraStructure = nodeData[3].value;
    if (isInfraStructure.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        isInfraStructure = nodeInterpretor([nodeData[3]], capacity, nodePerimeter);
        console.log("INTERPRETED isInfraStructure", isInfraStructure);
    }

    var area = nodeData[4].value;
    if (area.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        area = nodeInterpretor([nodeData[4]], capacity, nodePerimeter, nodePerimeter);
        console.log("INTERPRETED area", area);
    }

    // Get Perimeter
    // console.log("nodePerimeter", nodePerimeter);
    var perimeter = getPerimeter(nodePerimeter, capacity);
    // console.log("perimeter", perimeter);
    var perimeterArea = null;
    if (perimeter[0]?.userData?.turfData) {
        perimeterArea = turf.area(perimeter[0].userData.turfData);
    }
    if (perimeter[0]?.userData?.turfData_union) {
        perimeterArea = turf.area(perimeter[0].userData.turfData_union);
    }


    // Create meshes and edges
    var meshList = [{}];


    // Add userData
    if (meshList.length > 0) {
        meshList[0].userData = {
            isSuper: isSuperStructure,
            isInfra: isInfraStructure,
            availableArea: perimeterArea,
            totalBuildableArea: area,
            result: helpers.get_pretty_num(area.toFixed(2)),
            turfData: perimeter[0]?.userData?.turfData_union || perimeter[0]?.userData?.turfData
        }
    }


    var result = meshList;


    return result;
}

function nodeInterpreted_Node_Rule_Parking_Area_Total(nodeData, capacity, nodePerimeter) {

    // Get values
    var value = nodeData[2].value;
    if (value.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        value = nodeInterpretor([nodeData[2]], capacity, nodePerimeter, nodePerimeter);
        console.log("INTERPRETED value", value);
    }

    var type = nodeData[3].value;
    if (type.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        type = nodeInterpretor([nodeData[3]], capacity, nodePerimeter, nodePerimeter);
        console.log("INTERPRETED type", type);
    }

    // Get Perimeter
    // console.log("nodePerimeter", nodePerimeter);
    var perimeter = getPerimeter(nodePerimeter, capacity);
    // console.log("perimeter", perimeter);

    // Strips
    var perimeterArea = null;
    if (perimeter[0]?.userData?.turfData) {
        perimeterArea = turf.area(perimeter[0].userData.turfData_union);
    }

    var result = [{
        userData: {
            availableArea: perimeterArea,
            value: value,
            type: type,
            result: helpers.get_pretty_num(value, 2),
            turfData: perimeter[0]?.userData?.turfData_union
        }
    }]


    return result;
}

function nodeInterpreted_Node_Rule_Level_Nb(nodeData, capacity, nodePerimeter) {

    // Get values
    var isSuperStructure = nodeData[2].value;
    if (isSuperStructure.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        isSuperStructure = nodeInterpretor([nodeData[2]], capacity, nodePerimeter);
        console.log("INTERPRETED isSuperStructure", isSuperStructure);
    }

    var isInfraStructure = nodeData[3].value;
    if (isInfraStructure.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        isInfraStructure = nodeInterpretor([nodeData[3]], capacity, nodePerimeter);
        console.log("INTERPRETED isInfraStructure", isInfraStructure);
    }

    var nb_max = nodeData[4].value;
    if (nb_max.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        nb_max = nodeInterpretor([nodeData[4]], capacity, nodePerimeter, nodePerimeter);
        console.log("INTERPRETED nb_max", nb_max);
    }
    if (isSuperStructure === true && nb_max <= 0) { nb_max = 1 }
    if (isInfraStructure === true && nb_max <= 0) { nb_max = 0 }

    // Get Perimeter
    // console.log("nodePerimeter", nodePerimeter);
    var perimeter = getPerimeter(nodePerimeter, capacity);
    // console.log("perimeter", perimeter);

    // Strips
    var perimeterArea = null;
    if (perimeter[0]?.userData?.turfData) {
        perimeterArea = turf.area(perimeter[0].userData.turfData);
    }

    // Create material
    var material = new THREE.MeshPhongMaterial({
        color: '#FF0000',
        transparent: true,
        opacity: 0.3,
        // wireframe: true
    });

    // Create meshes and edges
    var meshList = [{}];

    var result = "RDC";
    if (nb_max > 1) { result = "R+" + (nb_max - 1)}

    // Add userData
    if (meshList.length > 0) {
        meshList[0].userData = {
            isSuper: isSuperStructure,
            isInfra: isInfraStructure,
            availableArea: perimeterArea,
            nb_max: nb_max,
            result: result,
            turfData: perimeter[0]?.userData?.turfData
        }
    }


    var result = meshList;


    return result;
}

function nodeInterpreted_Node_Rule_Roof_Angle(nodeData, capacity, nodePerimeter) {

    // Get values
    var roof_angle = nodeData[2].value;
    if (roof_angle.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        roof_angle = nodeInterpretor([nodeData[2]], capacity, nodePerimeter);
        console.log("INTERPRETED roof_angle", roof_angle);
    }


    // Get Perimeter
    // console.log("nodePerimeter", nodePerimeter);
    var perimeter = getPerimeter(nodePerimeter, capacity);
    // console.log("perimeter", perimeter);

    // Perimeter
    var perimeterArea = null;
    if (perimeter[0]?.userData?.turfData) {
        perimeterArea = turf.area(perimeter[0].userData.turfData_union);
    }

    var result = [{
        userData: {
            roof_angle: roof_angle,
            availableArea: perimeterArea,
            turfData: perimeter[0]?.userData?.turfData_union
        }
    }]

    return result;
}

function nodeInterpreted_Node_Rule_Level_Offset(nodeData, capacity, nodePerimeter) {

    // Get values
    var offset = nodeData[2].value;
    if (offset.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        offset = nodeInterpretor([nodeData[2]], capacity, nodePerimeter);
        console.log("INTERPRETED offset", offset);
    }

    var nb_level = nodeData[3].value;
    if (nb_level.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        nb_level = nodeInterpretor([nodeData[3]], capacity, nodePerimeter);
        console.log("INTERPRETED nb_level", nb_level);
    }


    // Get Perimeter
    // console.log("nodePerimeter", nodePerimeter);
    var perimeter = getPerimeter(nodePerimeter, capacity);
    // console.log("perimeter", perimeter);

    // Perimeter
    var perimeterArea = null;
    if (perimeter[0]?.userData?.turfData) {
        perimeterArea = turf.area(perimeter[0].userData.turfData_union);
    }

    // Verifications
    if (offset < 0) { offset = 0 }

    var result = [{
        userData: {
            offset: offset,
            nb_level: nb_level,
            turfData: perimeter[0]?.userData?.turfData_union
        }
    }]

    return result;
}

function nodeInterpreted_Node_Rule_Building_Offset(nodeData, capacity, nodePerimeter) {

    // Get values
    var offset_Main = nodeData[2].value;
    if (offset_Main.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        offset_Main = nodeInterpretor([nodeData[2]], capacity, nodePerimeter);
        console.log("INTERPRETED offset_Main", offset_Main);
    }
    var offset_Main_min = nodeData[3].value;
    if (offset_Main_min.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        offset_Main_min = nodeInterpretor([nodeData[3]], capacity, nodePerimeter);
        console.log("INTERPRETED offset_Main_min", offset_Main_min);
    }
    var offset_Main_max = nodeData[4].value;
    if (offset_Main_max.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        offset_Main_max = nodeInterpretor([nodeData[4]], capacity, nodePerimeter);
        console.log("INTERPRETED offset_Main_max", offset_Main_max);
    }

    var offset_Second = nodeData[5].value;
    if (offset_Second.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        offset_Second = nodeInterpretor([nodeData[5]], capacity, nodePerimeter);
        console.log("INTERPRETED offset_Second", offset_Second);
    }
    var offset_Second_min = nodeData[6].value;
    if (offset_Second_min.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        offset_Second_min = nodeInterpretor([nodeData[6]], capacity, nodePerimeter);
        console.log("INTERPRETED offset_Second_min", offset_Second_min);
    }
    var offset_Second_max = nodeData[7].value;
    if (offset_Second_max.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        offset_Second_max = nodeInterpretor([nodeData[7]], capacity, nodePerimeter);
        console.log("INTERPRETED offset_Second_max", offset_Second_max);
    }


    // Get Perimeter
    // console.log("nodePerimeter", nodePerimeter);
    var perimeter = getPerimeter(nodePerimeter, capacity);
    // console.log("perimeter", perimeter);



    var result = [{
        userData: {
            offsetMain: offset_Main,
            offsetMain_min: offset_Main_min,
            offsetMain_max: offset_Main_max,
            offsetSecond: offset_Second,
            offsetSecond_min: offset_Second_min,
            offsetSecond_max: offset_Second_max,
        }
    }]

    return result;
}

function nodeInterpreted_Node_Rule_Parking_Type(nodeData, capacity, nodePerimeter) {

    // Get values
    var isExt = nodeData[2].value;
    if (isExt.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        isExt = nodeInterpretor([nodeData[2]], capacity, nodePerimeter);
        console.log("INTERPRETED isExt", isExt);
    }

    var isSub = nodeData[3].value;
    if (isSub.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        isSub = nodeInterpretor([nodeData[3]], capacity, nodePerimeter);
        console.log("INTERPRETED isSub", isSub);
    }

    // Get Perimeter
    // console.log("nodePerimeter", nodePerimeter);
    var perimeter = getPerimeter(nodePerimeter, capacity);
    // console.log("perimeter", perimeter);
    var perimeterArea = null;
    if (perimeter[0]?.userData?.turfData) {
        perimeterArea = turf.area(perimeter[0].userData.turfData);
    }
    // var stripRatio = 1;
    // var strips = getBboxMeshStrip(capacity, stripRatio, "#1ecd97");

    // // Create material
    // var material = new THREE.MeshPhongMaterial({
    //     color: '#FF0000',
    //     transparent: true,
    //     opacity: 0.3,
    //     // wireframe: true
    // });

    // // Create meshes and edges
    // var meshList = [];
    // var edgesList = [];
    // perimeter.forEach(peri => {
    //     if (peri?.type === "Mesh") {
    //         strips[0].updateMatrix();
    //         peri.updateMatrix();
    //         var csgMesh = CSG.intersect(strips[0], peri);
    //         meshList.push(csgMesh);
    //         // var edges = getEdgesfromGeometry(peri.geometry, '#1ecd97');
    //         // edgesList.push(edges);
    //     }
    // })
    // // perimeterSurface.forEach(periSurface => {
    // //     if (periSurface.type === "Mesh") {
    // //         var edges = getEdgesfromGeometry(periSurface.geometry, '#1ecd97');
    // //         edgesList.push(edges);
    // //     }
    // // })

    // // Add userData
    // if (meshList.length > 0) {
    //     meshList[0].userData = {
    //         isExt: isExt,
    //         isSub: isSub,
    //         availableArea: perimeterArea,
    //         turfData: perimeter[0]?.userData?.turfData
    //     }
    // }


    // var result = meshList.concat(edgesList);

    var result = [
        {
            userData: {
                isExt: isExt,
                isSub: isSub,
                availableArea: perimeterArea,
                turfData: perimeter[0]?.userData?.turfData
            }       
        }
    ]


    return result;
}


function nodeInterpreted_Node_Rule_Unbuildable_Area(nodeData, capacity, nodePerimeter) {

    // Get values
    var isSuperStructure = nodeData[2].value;
    if (isSuperStructure.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        isSuperStructure = nodeInterpretor([nodeData[2]], capacity, nodePerimeter);
        console.log("INTERPRETED isSuperStructure", isSuperStructure);
    }

    var isInfraStructure = nodeData[3].value;
    if (isInfraStructure.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        isInfraStructure = nodeInterpretor([nodeData[3]], capacity, nodePerimeter);
        console.log("INTERPRETED isInfraStructure", isInfraStructure);
    }

    var area = nodeData[4].value;
    if (area.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        area = nodeInterpretor([nodeData[4]], capacity, nodePerimeter, nodePerimeter);
        console.log("INTERPRETED area", area);
    }

    // Get Perimeter
    // console.log("nodePerimeter", nodePerimeter);
    var perimeter = getPerimeter(nodePerimeter, capacity);
    // console.log("perimeter", perimeter);

    // Create Strips
    var perimeterArea = null;
    if (perimeter[0]?.userData?.turfData_union) {
        perimeterArea = turf.area(perimeter[0].userData.turfData_union);
    }
    var stripRatio = 0.5;
    if (perimeterArea !== null && area !== null) { stripRatio = area / perimeterArea }
    var color = "#FF0000";
    if (stripRatio <= 0) {
        color = "#1ecd97";
        stripRatio = 1;
    }
    var strips = getBboxMeshStrip(capacity, stripRatio, color, perimeter);

    // Create meshes and edges
    var meshList = [];
    var edgesList = [];
    perimeter.forEach(peri => {
        if (peri?.type === "Mesh") {
            strips[0].updateMatrix();
            peri.updateMatrix();
            var csgMesh = CSG.intersect(strips[0], peri);
            meshList.push(csgMesh);
            if (peri?.userData?.flatMesh) {
                var edges = getEdgesfromGeometry(peri.userData.flatMesh.geometry, '#FF0000');
                edgesList.push(edges);
            }
        }
    })

    // Add userData
    if (meshList.length > 0) {
        meshList[0].userData = {
            isSuper: isSuperStructure,
            isInfra: isInfraStructure,
            availableArea: perimeterArea,
            unbuildableArea: area,
            result: helpers.get_pretty_num(area.toFixed(2)),
            turfData: perimeter[0]?.userData?.turfData
        }
    }

    var result = meshList.concat(edgesList);


    return result;
}

function nodeInterpreted_Node_Rule_Building_Height(nodeData, capacity, nodePerimeter) {

    // Get values
    var height = nodeData[2].value;
    if (height.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        height = nodeInterpretor([nodeData[2]], capacity, nodePerimeter, nodePerimeter);
        console.log("INTERPRETED height", height);
    }

    // Get Perimeter
    // console.log("nodePerimeter", nodePerimeter);
    var perimeter = getPerimeter(nodePerimeter, capacity);
    console.log("perimeter", perimeter);
    var perimeter_turf = [];
    perimeter.forEach(peri => {
        if (peri?.type === "Mesh" && peri?.userData?.turfData) {
            perimeter_turf.push(peri?.userData?.turfData);
        }
    })

    // Get 3D surface
    var result3D = [];
    perimeter.forEach(peri => {
        if (peri?.type === "Mesh") {
            var new_peri = null;
            if (peri?.userData?.flatMesh?.type === "Mesh") {
                new_peri = new THREE.Mesh(peri?.userData?.flatMesh.geometry, new THREE.MeshPhongMaterial({ color: '#ed8282', }));
                console.log("FLAT MESH");
            }
            else {
                new_peri = new THREE.Mesh(peri?.geometry, new THREE.MeshPhongMaterial({ color: '#ed8282', }));
                console.log("BIG MESH");
            }
            if (new_peri !== null) {
                new_peri.translateZ(height);
                result3D.push(new_peri);
                var edges = getEdgesfromGeometry(new_peri.geometry, '#ed8282');
                edges.translateZ(height);
                result3D.push(edges);
            }
        }
    })


    // Add userData
    if (result3D.length <= 0) {
        result3D.push({});
    }
    result3D[0].userData = {
        height: height,
        perimeter: perimeter_turf,
    }

    var result = [
        {
            userData: {
                height: height,
                perimeter: perimeter_turf,
            }
        }
    ];

    return result3D
}

function nodeInterpreted_Node_Rule_Facade_Height(nodeData, capacity, nodePerimeter) {

    // Get values
    var height = nodeData[2].value;
    if (height.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        height = nodeInterpretor([nodeData[2]], capacity, nodePerimeter, nodePerimeter);
        console.log("INTERPRETED height", height);
    }

    // Get Perimeter
    // console.log("nodePerimeter", nodePerimeter);
    var perimeter = getPerimeter(nodePerimeter, capacity);
    // console.log("perimeter", perimeter);
    var perimeter_turf = [];
    perimeter.forEach(peri => {
        if (peri?.type === "Mesh" && peri?.userData?.turfData) {
            perimeter_turf.push(peri?.userData?.turfData);
        }
    })

    // Get 3D surface
    var result3D = [];
    perimeter.forEach(peri => {
        if (peri?.type === "Mesh") {
            var new_peri = null;
            if (peri?.userData?.flatMesh?.type === "Mesh") {
                new_peri = new THREE.Mesh(peri?.userData?.flatMesh.geometry, new THREE.MeshPhongMaterial({ color: '#e8b479', }));
            }
            else {
                new_peri = new THREE.Mesh(peri?.geometry, new THREE.MeshPhongMaterial({ color: '#e8b479', }));
            }
            if (new_peri !== null) {
                new_peri.translateZ(height);
                result3D.push(new_peri);
                var edges = getEdgesfromGeometry(new_peri.geometry, '#e8b479');
                edges.translateZ(height);
                result3D.push(edges);
            }
        }
    })


    // Add userData
    if (result3D.length <= 0) {
        result3D.push({});
    }
    result3D[0].userData = {
        height: height,
        perimeter: perimeter_turf,
    }

    var result = [
        {
            userData: {
                height: height,
                perimeter: perimeter_turf,
            }
        }
    ];

    return result3D
}

function nodeInterpreted_Node_Rule_Level_Height(nodeData, capacity, nodePerimeter) {

    // Get values
    var level = nodeData[2].value;
    if (level.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        level = nodeInterpretor([nodeData[2]], capacity, nodePerimeter, nodePerimeter);
        console.log("INTERPRETED level", level);
    }

    var height = nodeData[3].value;
    if (height.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        height = nodeInterpretor([nodeData[3]], capacity, nodePerimeter, nodePerimeter);
        console.log("INTERPRETED height", height);
    }


    var result = [
        {
            userData: {
                levels: level,
                height: height
            }
        }
    ]

    return result
}


function nodeInterpreted_Node_3DOperator_Watch(nodeData, capacity, nodePerimeter) {

    // Get values
    var volume = nodeData[0].value;
    if (volume.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        volume = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
        //console.log("INTERPRETED VOLUME", volume);
    }

    // Create material
    var material = new THREE.MeshPhongMaterial({
        color: '#1ecd97',
        transparent: true,
        opacity: 0.3,
        // wireframe: true
    });

    // Create meshes and edges
    var meshList = [];
    var edgesList = [];
    if (Array.isArray(volume)) {
        volume.forEach(item => {
            if (item !== undefined) {
                var mesh = new THREE.Mesh(item, material);
                meshList.push(mesh);
                var edges = getEdgesfromGeometry(item, '#1ecd97');
                edgesList.push(edges);
            }
        })
    }
    else {
        var mesh = new THREE.Mesh(volume, material);
        meshList.push(mesh);
        var edges = getEdgesfromGeometry(volume, '#1ecd97');
        edgesList.push(edges);
    }

    var result = meshList.concat(edgesList);

    // Get default buildable volume
    var landShape = nodeInterpreted_Node_Study_Land(nodeData, capacity);
    var landGeometry = getExtrusionFromShape(landShape, 10);
    var landMaterial = new THREE.MeshPhongMaterial({
        color: 'orange',
        transparent: false,
        // opacity: 0.3,
    });
    var landMesh = new THREE.Mesh(landGeometry, landMaterial);
    result.push(landMesh);

    landMesh.updateMatrix();
    var csgMesh = landMesh;
    meshList.forEach(mesh => {
        csgMesh.updateMatrix();
        mesh.updateMatrix();
        csgMesh = CSG.subtract(csgMesh, mesh);
    });

    var csgEdges = getEdgesfromGeometry(csgMesh.geometry, 'black');

    result = [csgMesh, csgEdges];

    // Send result
    return result;
}


// CONDITION
function nodeInterpreted_Node_Condition(nodeData, capacity, nodePerimeter) {

    // Get values
    var conditionValue = nodeData[0].value;
    if (conditionValue.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        conditionValue = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
        //console.log("INTERPRETED CONDITION VALUE", conditionValue);
    }

    return conditionValue;

}

// PERIMETERS
function getPerimeter(nodePerimeter, capacity) {
    // Initialize values
    var perimeter = null;
    // var perimeterSurface = null;

    // Check if perimeter exists
    if (nodePerimeter && nodePerimeter?.data?.data?.composition?.nodes[0]?.data?.source) {
        //console.log("=====PERIMETER ANALYSIS HERE", nodePerimeter?.data?.data?.composition?.nodes[0]?.data?.source);
        perimeter = nodePerimeter?.data?.data?.composition?.nodes[0]?.data?.source[0].value;
        if (perimeter.indexOf("Node_") === 0) {
            //console.log("TO INTERPRET");
            // perimeter = nodeInterpretor([nodePerimeter?.data?.data?.composition?.nodes[0]?.data?.source[0]], capacity, nodePerimeter);
            var node = [nodePerimeter?.data?.data?.composition?.nodes[0]?.data?.source[0]];
            var i = 0;
            var separation = node[i].value.indexOf("[");
            var delta = 0;
            if (separation > -1) { delta = 1 }
            const nodeData0 = getNodeDataList(node[i].value.substring(separation + 1, node[i].value.length - delta));
            var nodeDataPerimeter = [];
            for (var j = 0; j < nodeData0.length; j++) {
                nodeDataPerimeter.push({ value: nodeData0[j] });
            }
            perimeter = getInterpretedPerimeter(nodeDataPerimeter, capacity, true);
            // perimeterSurface = getInterpretedPerimeter(nodeDataPerimeter, capacity, false);
            // console.log("INTERPRETED PERIMETER", perimeter);
        }
    }
    // If no perimeter return land
    else {
        perimeter = getLandMesh(capacity, 100, false);
        // perimeterSurface = perimeter;
    }

    return perimeter
}

function getInterpretedPerimeter(nodeData, capacity, isExtruded) {

    // Get values
    var surface = nodeData[0].value;
    if (surface.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        surface = nodeInterpretor([nodeData[0]], capacity, null);
        if (!Array.isArray(surface)) {
            surface = [surface];
        }
        console.log("INTERPRETED SURFACE ------", surface);
    }

    // Get global area
    var surface_union = surface[0].userData.turfData;
    if (surface.length > 1) {
        for (var i = 1; i < surface.length; i++) {
            surface_union = turf.union(surface_union, surface[i].userData.turfData);
        }
    }
    surface_union.properties.area = turf.area(surface_union);
    console.log("INTERPRETED SURFACE UNION ------", surface_union);


    // Create geometry
    var volume = [];
    surface.forEach(item => {
        if (item === undefined) {
            volume.push(undefined);
        }
        else {
            var geometry = getExtrusionFromShape(item, 100);
            var geometry_flat = getExtrusionFromShape(item, 0.01);

            geometry.userData.turfData = item?.userData?.turfData;
            geometry.userData.flat = geometry_flat;

            volume.push(geometry);
        }
    });

    // Create material
    var material = new THREE.MeshPhongMaterial({
        color: '#062134',
        transparent: true,
        opacity: 0.3,
        // wireframe: true
    });

    // Create meshes and edges
    var meshList = [];
    var edgesList = [];
    volume.forEach(item => {
        if (item !== undefined) {
            var mesh = new THREE.Mesh(item, material);
            mesh.userData = { turfData: item?.userData?.turfData };
            if (item?.userData?.flat) {
                var mesh_flat = new THREE.Mesh(item.userData.flat, material);
                mesh.userData.flatMesh = mesh_flat;
            }
            meshList.push(mesh);
            var edges = getEdgesfromGeometry(item, '#062134');
            edgesList.push(edges);
        }
    })

    // Add global area to first element
    meshList[0].userData.turfData_union = surface_union;

    // Send result
    var result = meshList.concat(edgesList);
    return result;
}


function nodeInterpreted_Node_Perimeter(nodeData, capacity, fromRule, nodePerimeter, elevation) {

    // Get values
    var surface = nodeData[0].value;
    if (surface.indexOf("Node_") === 0) {
        //console.log("TO INTERPRET");
        surface = nodeInterpretor([nodeData[0]], capacity, nodePerimeter);
        // console.log("INTERPRETED SURFACE", surface);
    }

    // Create geometry
    var area_total = 0;
    var volume;
    if (Array.isArray(surface)) {
        volume = [];
        surface.forEach(item => {
            if (item === undefined) {
                volume.push(undefined);
            }
            else {
                var geometry;
                if (fromRule) {
                    geometry = getExtrusionFromShape(item, 100);
                }
                else {
                    geometry = getExtrusionFromShape(item, 0.01);
                }
                volume.push(geometry);
                if (item?.userData?.turfData) {
                    area_total += turf.area(item.userData.turfData);
                }
            }
        });
    }
    else {
        var geometry;
        if (fromRule) {
            geometry = getExtrusionFromShape(surface, 100);
        }
        else {
            geometry = getExtrusionFromShape(surface, 0.01);
        }
        volume = geometry;
        if (surface?.userData?.turfData) {
            area_total += turf.area(surface.userData.turfData);
        }
    }

    // Create material
    var material = new THREE.MeshPhongMaterial({
        color: '#062134',
        transparent: true,
        opacity: 0.3,
        // wireframe: true
    });

    // Create meshes and edges
    var meshList = [];
    var edgesList = [];
    if (Array.isArray(volume)) {
        volume.forEach(item => {
            if (item !== undefined) {
                var mesh = new THREE.Mesh(item, material);
                if (elevation && elevation !== 0) {
                    mesh.translateZ(elevation);
                }
                mesh.userData = { turfData: surface?.userData?.turfData };
                meshList.push(mesh);
                var edges = getEdgesfromGeometry(item, '#062134');
                edgesList.push(edges);
            }
        })
    }
    else {
        var mesh = new THREE.Mesh(volume, material);
        if (elevation && elevation !== 0) {
            mesh.translateZ(elevation);
        }
        mesh.userData = { turfData: surface?.userData?.turfData };
        meshList.push(mesh);
        var edges = getEdgesfromGeometry(volume, '#062134');
        edgesList.push(edges);
    }

    var result = meshList.concat(edgesList);

    if (result.length > 0) {
        result[0].userData.result = helpers.get_pretty_num(area_total.toFixed(2));
    }

    // Send result
    return result;
}