import * as turf from '@turf/turf';
import mapboxgl from 'mapbox-gl';


const sources_ids = ['sourceCommoditiesTrain'];
const layers_ids = ['layerCommoditiesTrain_Points'];
var markers = [];
var marker_img = '<svg width="10" height="14" viewBox="0 0 600 800" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M447.188 676.119V675.126H154.305V676.119H137.83L41.0391 800H112.358L133.772 768.386H467.106L488.516 800H559.858L463.061 676.119H447.188ZM154.439 737.887L176.295 705.625H424.595L446.452 737.887H154.439Z" fill="white"/><path d="M300.445 0C76.6125 0 12 81.2219 12 143.223V547.288C12 601.811 56.2031 646.063 110.787 646.063H490.108C544.705 646.063 588.884 601.811 588.884 547.288V143.223C588.884 81.2219 524.284 0 300.445 0ZM195.897 89.0422H404.983C418.87 89.0422 430.148 100.308 430.148 114.195C430.148 128.12 418.87 139.373 404.983 139.373H195.897C182.008 139.373 170.744 128.12 170.744 114.195C170.742 100.308 182.006 89.0422 195.897 89.0422ZM150.872 560.061C133.539 560.061 119.491 546.013 119.491 528.68C119.491 511.345 133.539 497.298 150.872 497.298C168.205 497.298 182.253 511.345 182.253 528.68C182.252 546.013 168.205 560.061 150.872 560.061ZM278.478 373.872H110.959V220.647H278.478V373.872ZM450.031 560.061C432.697 560.061 418.65 546.013 418.65 528.68C418.65 511.345 432.697 497.298 450.031 497.298C467.362 497.298 481.412 511.345 481.412 528.68C481.412 546.013 467.362 560.061 450.031 560.061ZM497.391 373.872H322.412V220.647H497.391V373.872Z" fill="white"/></svg>';

export const get_ids = () => {
    // Get all source ids
    var sources = [];
    sources_ids.forEach(source_id => {
        sources.push({ id: source_id });
    })
    // Get all layer ids
    var layers = [];
    layers_ids.forEach(layer_id => {
        layers.push({ id: layer_id });
    })

    return {
        sources: sources,
        layers: layers,
        markers: markers,
    }
}


export const get_data = (capacity) => {
    // Set map data
    var map_data = {
        sources: [],
        layers: [],
        markers: [],
        bbox: null
    }

    // Check needed data
    if (!capacity?.context?.commodities?.transports_train?.items || !capacity.context.commodities.transports_train.items.length > 0) {
        return map_data
    }

    // Preset layer data
    var layer_data = {
        type: 'FeatureCollection',
        features: [],
    };

    // Add data
    for (var i = 0; i < capacity.context.commodities.transports_train.items.length; i++) {
        var item = capacity.context.commodities.transports_train.items[i];
        var item_marker_coords = null;
        // Generate polygon
        if (item?.type === "way" && item?.coords && item.coords.length > 3 && item.coords[0].length === 2 && item.coords[0][0] === item.coords[item.coords.length - 1][0] && item.coords[0][1] === item.coords[item.coords.length - 1][1]) {
            layer_data.features.push({
                type: "Feature",
                properties: item?.properties || null,
                geometry: {
                    type: "Polygon",
                    coordinates: [item.coords],
                }
            });
            try {
                var center = turf.centroid(turf.polygon([item.coords]));
                item_marker_coords = center?.geometry?.coordinates || null;
            } catch (error) {
                item_marker_coords = null;
            }
        }
        // Generate point
        else if (item?.type === "node" && item?.coords && item.coords.length === 2) {
            layer_data.features.push({
                type: "Feature",
                properties: item?.properties || null,
                geometry: {
                    type: "Point",
                    coordinates: item.coords,
                }
            });
            item_marker_coords = item.coords;
        }
        // Generate marker
        if (item_marker_coords !== null && item_marker_coords.length === 2) {
            var title = "Gare ou halte ferroviaire";
            var title_txt = '<span style="color:#062134;font-size:15px;font-weight:700"><strong>' + title + '</strong></span>';
            var infos = [
                { label: "Nom", value: item?.properties?.name || "NC" },
                { label: "Opérateur", value: item?.properties?.operator || "NC" },
                { label: "Distance", value: item?.properties?.distance ? item?.properties?.distance + " m" : "NC" },
            ]
            var infos_txt = infos.map(info => { return ('<br /><span style="font-size:12px;font-weight: 600;color: grey;">' + info.label + ' :</span><br /><span style="color:#062134;font-weight:500;">' + info.value + '</span>') }).join('');
            var marker_label = item?.properties?.name || "";
            var el = document.createElement('div');
            el.className = 'marker';
            el.innerHTML = '<div style="cursor:pointer;padding:3px 8px 2px;background-color:#062134;border-radius:6px;display:flex;justify-content:center;align-items:center;gap:4px"><div style="display:flex;justify-content:center">' + marker_img + '</div><div style="font-family:Manrope;color:#FFF;">' + marker_label + '</div></div>';
            map_data.markers.push(new mapboxgl.Marker(el)
                .setLngLat(item_marker_coords)
                .setPopup(new mapboxgl.Popup({ offset: 15 })
                    .setHTML('<p style="font-family:Manrope">' + title_txt + infos_txt + '</p>'))
            )
        }
    }

    // Check data exists
    if (!layer_data.features.length > 0) {
        return map_data
    }

    // Create sources
    map_data.sources.push(
        {
            id: sources_ids[0],
            value: {
                type: 'geojson',
                data: layer_data
            }
        }
    )

    // Create layers
    map_data.layers.push(
        {
            id: layers_ids[0],
            type: 'fill',
            source: sources_ids[0],
            paint: {
                'fill-color': '#062134',
                'fill-opacity': 0.5,
            },
            filter: ['in', '$type', 'Polygon']
        }
    );

    // Set global markers
    if (map_data.markers.length > 0) {
        markers = map_data.markers;
    }
    else {
        markers = [];
    }

    // Get bbox
    var bbox_data = {
        type: 'FeatureCollection',
        features: layer_data.features.concat([capacity?.landBase?.union?.geometry]),
    };
    var bbox = turf.bbox(bbox_data);
    if (bbox && bbox.length === 4) {
        var bbox_poly = [
            [bbox[0], bbox[1]],
            [bbox[2], bbox[1]],
            [bbox[2], bbox[3]],
            [bbox[0], bbox[3]],
            [bbox[0], bbox[1]],
        ]
        map_data.bbox = [bbox_poly];
    }


    // Return
    // console.log("map_data", map_data);
    return map_data

}

