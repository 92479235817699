import * as turf from '@turf/turf';
import mapboxgl from 'mapbox-gl';


const sources_ids = ['sourceCommoditiesHospital'];
const layers_ids = ['layerCommoditiesHospital_Points'];
var markers = [];
var marker_img = '<svg width="14" height="14" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M672.594 225.5H597.708V146.021C597.708 89.2029 552.874 43 495.904 43H304.111C247.111 43 202.292 89.2029 202.292 146.021V225.5H127.391C76.3515 225.5 35 264.905 35 315.792V665.69C35 716.562 76.3515 757.792 127.391 757.792H672.594C723.618 757.792 765 716.562 765 665.675V315.792C765 264.905 723.618 225.5 672.594 225.5ZM263.125 146.021C263.125 124.745 282.774 103.833 304.111 103.833H495.904C517.18 103.833 536.875 124.745 536.875 146.021V225.5H263.125V146.021ZM567.292 560.083H460.833V666.542H339.167V560.083H232.708V438.417H339.167V331.958H460.833V438.417H567.292V560.083Z" fill="white"/></svg>';

export const get_ids = () => {
    // Get all source ids
    var sources = [];
    sources_ids.forEach(source_id => {
        sources.push({ id: source_id });
    })
    // Get all layer ids
    var layers = [];
    layers_ids.forEach(layer_id => {
        layers.push({ id: layer_id });
    })

    return {
        sources: sources,
        layers: layers,
        markers: markers,
    }
}


export const get_data = (capacity) => {
    // Set map data
    var map_data = {
        sources: [],
        layers: [],
        markers: [],
        bbox: null
    }

    var data_items = capacity?.context?.commodities?.health_hospital?.items?.clinic || [];
    var data_cat = Array(data_items.length).fill("Clinique");
    if (capacity?.context?.commodities?.health_hospital?.items?.hospital) {
        data_items = data_items.concat(capacity?.context?.commodities?.health_hospital?.items?.hospital);
        data_cat = data_cat.concat(Array(capacity?.context?.commodities?.health_hospital?.items?.hospital.length).fill("Hôpital"));
    }
    if (capacity?.context?.commodities?.health_hospital?.items?.laboratory) {
        data_items = data_items.concat(capacity?.context?.commodities?.health_hospital?.items?.laboratory);
        data_cat = data_cat.concat(Array(capacity?.context?.commodities?.health_hospital?.items?.laboratory.length).fill("Laboratoire d'analyse"));
    }


    // Check needed data
    if (!data_items.length > 0) {
        return map_data
    }

    // Preset layer data
    var layer_data = {
        type: 'FeatureCollection',
        features: [],
    };

    // Add data
    for (var i = 0; i < data_items.length; i++) {
        var item = data_items[i];
        var item_marker_coords = null;
        // Generate polygon
        if (item?.type === "way" && item?.coords && item.coords.length > 3 && item.coords[0].length === 2 && item.coords[0][0] === item.coords[item.coords.length - 1][0] && item.coords[0][1] === item.coords[item.coords.length - 1][1]) {
            layer_data.features.push({
                type: "Feature",
                properties: item?.properties || null,
                geometry: {
                    type: "Polygon",
                    coordinates: [item.coords],
                }
            });
            try {
                var center = turf.centroid(turf.polygon([item.coords]));
                item_marker_coords = center?.geometry?.coordinates || null;
            } catch (error) {
                item_marker_coords = null;
            }
        }
        // Generate point
        else if (item?.type === "node" && item?.coords && item.coords.length === 2) {
            layer_data.features.push({
                type: "Feature",
                properties: item?.properties || null,
                geometry: {
                    type: "Point",
                    coordinates: item.coords,
                }
            });
            item_marker_coords = item.coords;
        }
        // Generate marker
        if (item_marker_coords !== null && item_marker_coords.length === 2) {
            var title = data_cat.length >= (i-1) ? data_cat[i] : "Étabilssement de soins";
            var title_txt = '<span style="color:#062134;font-size:15px;font-weight:700"><strong>' + title + '</strong></span>';
            var infos = [
                { label: "Nom", value: item?.properties?.name || "NC" },
                { label: "Distance", value: item?.distance ? item?.distance + " m" : "NC"},
            ]
            var infos_txt = infos.map(info => { return ('<br /><span style="font-size:12px;font-weight: 600;color: grey;">' + info.label + ' :</span><br /><span style="color:#062134;font-weight:500;">' + info.value + '</span>') }).join('');
            var marker_label = item?.properties?.name || "Étabilssement de soins";
            var el = document.createElement('div');
            el.className = 'marker';
            el.innerHTML = '<div style="cursor:pointer;padding:3px 8px 2px;background-color:#062134;border-radius:6px;display:flex;justify-content:center;align-items:center;gap:4px"><div style="display:flex;justify-content:center">' + marker_img + '</div><div style="font-family:Manrope;color:#FFF;">' + marker_label + '</div></div>';
            map_data.markers.push(new mapboxgl.Marker(el)
                .setLngLat(item_marker_coords)
                .setPopup(new mapboxgl.Popup({ offset: 15 })
                    .setHTML('<p style="font-family:Manrope">' + title_txt + infos_txt + '</p>'))
            )
        }
    }

    // Check data exists
    if (!layer_data.features.length > 0) {
        return map_data
    }

    // Create sources
    map_data.sources.push(
        {
            id: sources_ids[0],
            value: {
                type: 'geojson',
                data: layer_data
            }
        }
    )

    // Create layers
    map_data.layers.push(
        {
            id: layers_ids[0],
            type: 'fill',
            source: sources_ids[0],
            paint: {
                'fill-color': '#062134',
                'fill-opacity': 0.5,
            },
            filter: ['in', '$type', 'Polygon']
        }
    );

    // Set global markers
    if (map_data.markers.length > 0) {
        markers = map_data.markers;
    }
    else {
        markers = [];
    }

    // Get bbox
    var bbox_data = {
        type: 'FeatureCollection',
        features: layer_data.features.concat([capacity?.landBase?.union?.geometry]),
    };
    var bbox = turf.bbox(bbox_data);
    if (bbox && bbox.length === 4) {
        var bbox_poly = [
            [bbox[0], bbox[1]],
            [bbox[2], bbox[1]],
            [bbox[2], bbox[3]],
            [bbox[0], bbox[3]],
            [bbox[0], bbox[1]],
        ]
        map_data.bbox = [bbox_poly];
    }


    // Return
    // console.log("map_data", map_data);
    return map_data

}

