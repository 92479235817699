import * as turf from '@turf/turf';
import mapboxgl from 'mapbox-gl';


const sources_ids = ['sourceCommoditiesPharmacy'];
const layers_ids = ['layerCommoditiesPharmacy_Points'];
var markers = [];
var marker_img = '<svg width="14" height="14" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1108_9)"><path d="M695.309 83.9505H272.593C214.866 83.9505 167.901 130.915 167.901 188.642V219.587H39.5062V54.9806C39.5062 44.069 30.6607 35.2275 19.7531 35.2275C8.84543 35.2275 0 44.069 0 54.9806V745.023C0 755.935 8.84543 764.776 19.7531 764.776C30.6607 764.776 39.5062 755.935 39.5062 745.023V580.413H167.901V611.362C167.901 669.088 214.866 716.053 272.593 716.053H695.309C753.035 716.053 800 669.088 800 611.362V188.646C800 130.915 753.035 83.9505 695.309 83.9505ZM39.5062 259.093H167.901V540.903H39.5062V259.093ZM668.97 463.194C668.97 474.106 660.124 482.947 649.217 482.947H566.894V565.27C566.894 576.182 558.048 585.023 547.141 585.023H420.76C409.853 585.023 401.007 576.182 401.007 565.27V482.947H318.684C307.777 482.947 298.931 474.106 298.931 463.194V336.814C298.931 325.902 307.777 317.061 318.684 317.061H401.007V234.734C401.007 223.822 409.853 214.981 420.76 214.981H547.141C558.048 214.981 566.894 223.822 566.894 234.734V317.061H649.217C660.124 317.061 668.97 325.902 668.97 336.814V463.194Z" fill="white"/></g><defs><clipPath id="clip0_1108_9"><rect width="800" height="800" fill="white"/></clipPath></defs></svg>';

export const get_ids = () => {
    // Get all source ids
    var sources = [];
    sources_ids.forEach(source_id => {
        sources.push({ id: source_id });
    })
    // Get all layer ids
    var layers = [];
    layers_ids.forEach(layer_id => {
        layers.push({ id: layer_id });
    })

    return {
        sources: sources,
        layers: layers,
        markers: markers,
    }
}


export const get_data = (capacity) => {
    // Set map data
    var map_data = {
        sources: [],
        layers: [],
        markers: [],
        bbox: null
    }

    // Check needed data
    if (!capacity?.context?.commodities?.health_pharmacy?.items || !capacity.context.commodities.health_pharmacy.items.length > 0) {
        return map_data
    }

    // Preset layer data
    var layer_data = {
        type: 'FeatureCollection',
        features: [],
    };

    // Add data
    for (var i = 0; i < capacity.context.commodities.health_pharmacy.items.length; i++) {
        var item = capacity.context.commodities.health_pharmacy.items[i];
        var item_marker_coords = null;
        // Generate polygon
        if (item?.type === "way" && item?.coords && item.coords.length > 3 && item.coords[0].length === 2 && item.coords[0][0] === item.coords[item.coords.length - 1][0] && item.coords[0][1] === item.coords[item.coords.length - 1][1]) {
            layer_data.features.push({
                type: "Feature",
                properties: item?.properties || null,
                geometry: {
                    type: "Polygon",
                    coordinates: [item.coords],
                }
            });
            try {
                var center = turf.centroid(turf.polygon([item.coords]));
                item_marker_coords = center?.geometry?.coordinates || null;
            } catch (error) {
                item_marker_coords = null;
            }
        }
        // Generate point
        else if (item?.type === "node" && item?.coords && item.coords.length === 2) {
            layer_data.features.push({
                type: "Feature",
                properties: item?.properties || null,
                geometry: {
                    type: "Point",
                    coordinates: item.coords,
                }
            });
            item_marker_coords = item.coords;
        }
        // Generate marker
        if (item_marker_coords !== null && item_marker_coords.length === 2) {
            var title = "Pharmacie";
            var title_txt = '<span style="color:#062134;font-size:15px;font-weight:700"><strong>' + title + '</strong></span>';
            var infos = [
                { label: "Nom", value: item?.properties?.name || "NC" },
                { label: "Distance", value: item?.distance ? item?.distance + " m" : "NC"},
            ]
            var infos_txt = infos.map(info => { return ('<br /><span style="font-size:12px;font-weight: 600;color: grey;">' + info.label + ' :</span><br /><span style="color:#062134;font-weight:500;">' + info.value + '</span>') }).join('');
            var marker_label = item?.properties?.name || "Pharmacie";
            var el = document.createElement('div');
            el.className = 'marker';
            el.innerHTML = '<div style="cursor:pointer;padding:3px 8px 2px;background-color:#062134;border-radius:6px;display:flex;justify-content:center;align-items:center;gap:4px"><div style="display:flex;justify-content:center">' + marker_img + '</div><div style="font-family:Manrope;color:#FFF;">' + marker_label + '</div></div>';
            map_data.markers.push(new mapboxgl.Marker(el)
                .setLngLat(item_marker_coords)
                .setPopup(new mapboxgl.Popup({ offset: 15 })
                    .setHTML('<p style="font-family:Manrope">' + title_txt + infos_txt + '</p>'))
            )
        }
    }

    // Check data exists
    if (!layer_data.features.length > 0) {
        return map_data
    }

    // Create sources
    map_data.sources.push(
        {
            id: sources_ids[0],
            value: {
                type: 'geojson',
                data: layer_data
            }
        }
    )

    // Create layers
    map_data.layers.push(
        {
            id: layers_ids[0],
            type: 'fill',
            source: sources_ids[0],
            paint: {
                'fill-color': '#062134',
                'fill-opacity': 0.5,
            },
            filter: ['in', '$type', 'Polygon']
        }
    );

    // Set global markers
    if (map_data.markers.length > 0) {
        markers = map_data.markers;
    }
    else {
        markers = [];
    }

    // Get bbox
    var bbox_data = {
        type: 'FeatureCollection',
        features: layer_data.features.concat([capacity?.landBase?.union?.geometry]),
    };
    var bbox = turf.bbox(bbox_data);
    if (bbox && bbox.length === 4) {
        var bbox_poly = [
            [bbox[0], bbox[1]],
            [bbox[2], bbox[1]],
            [bbox[2], bbox[3]],
            [bbox[0], bbox[3]],
            [bbox[0], bbox[1]],
        ]
        map_data.bbox = [bbox_poly];
    }


    // Return
    // console.log("map_data", map_data);
    return map_data

}

