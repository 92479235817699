import * as turf from '@turf/turf';
import mapboxgl from 'mapbox-gl';


const sources_ids = ['sourceCommoditiesEducationBaby'];
const layers_ids = ['layerCommoditiesEducationBaby_Points'];
var markers = [];
var marker_img = '<svg width="14" height="14" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1108_2)"><path d="M775.053 320.412C775.053 245.693 750.473 172.649 703.708 114.737C657.544 57.5747 593.206 17.0244 521.467 0.552404C510.404 -1.99564 499.567 4.48098 496.286 15.3445L404.578 320.352H146.805V244.855C146.805 232.942 139.374 223.284 127.461 223.284H46.5708C34.6583 223.284 25 232.942 25 244.855C25 256.767 34.6583 266.425 46.5708 266.425H103.663V446.328C103.663 485.463 137.616 517.185 176.748 517.185H365.538L396.543 548.191L332.769 611.967C317.098 602.115 298.585 596.393 278.75 596.393C222.618 596.393 176.95 642.064 176.95 698.199C176.95 754.329 222.618 799.997 278.75 799.997C326.651 799.997 366.905 766.738 377.693 722.108H476.409C487.197 766.738 527.45 799.997 575.345 799.997C631.481 799.997 677.151 754.332 677.151 698.199C677.151 642.064 631.481 596.393 575.345 596.393C555.514 596.393 536.998 602.115 521.33 611.965L457.558 548.191L488.564 517.185H706.423C745.558 517.185 775.053 485.46 775.053 446.328V320.412ZM427.548 517.188L427.049 517.687L426.553 517.188H427.548ZM278.748 756.858C246.402 756.858 220.089 730.545 220.089 698.202C220.089 665.854 246.402 639.537 278.748 639.537C311.096 639.537 337.412 665.854 337.412 698.202C337.412 730.545 311.096 756.858 278.748 756.858ZM575.345 756.858C543 756.858 516.687 730.545 516.687 698.202C516.687 665.854 543 639.537 575.345 639.537C607.693 639.537 634.01 665.854 634.01 698.202C634.01 730.545 607.693 756.858 575.345 756.858ZM490.451 642.104C483.168 653.092 477.967 665.565 475.392 678.969H378.709C376.134 665.565 370.933 653.092 363.647 642.104L427.052 578.7L490.451 642.104Z" fill="white"/></g><defs><clipPath id="clip0_1108_2"><rect width="800" height="800" fill="white"/></clipPath></defs></svg>';

export const get_ids = () => {
    // Get all source ids
    var sources = [];
    sources_ids.forEach(source_id => {
        sources.push({ id: source_id });
    })
    // Get all layer ids
    var layers = [];
    layers_ids.forEach(layer_id => {
        layers.push({ id: layer_id });
    })

    return {
        sources: sources,
        layers: layers,
        markers: markers,
    }
}


export const get_data = (capacity) => {
    // Set map data
    var map_data = {
        sources: [],
        layers: [],
        markers: [],
        bbox: null
    }

    // Check needed data
    if (!capacity?.context?.commodities?.education_baby?.items || !capacity.context.commodities.education_baby.items.length > 0) {
        return map_data
    }

    // Preset layer data
    var layer_data = {
        type: 'FeatureCollection',
        features: [],
    };

    // Add data
    for (var i = 0; i < capacity.context.commodities.education_baby.items.length; i++) {
        var item = capacity.context.commodities.education_baby.items[i];
        var item_marker_coords = null;
        // Generate polygon
        if (item?.type === "way" && item?.coords && item.coords.length > 3 && item.coords[0].length === 2 && item.coords[0][0] === item.coords[item.coords.length - 1][0] && item.coords[0][1] === item.coords[item.coords.length - 1][1]) {
            layer_data.features.push({
                type: "Feature",
                properties: item?.properties || null,
                geometry: {
                    type: "Polygon",
                    coordinates: [item.coords],
                }
            });
            try {
                var center = turf.centroid(turf.polygon([item.coords]));
                item_marker_coords = center?.geometry?.coordinates || null;
            } catch (error) {
                item_marker_coords = null;
            }
        }
        // Generate point
        else if (item?.type === "node" && item?.coords && item.coords.length === 2) {
            layer_data.features.push({
                type: "Feature",
                properties: item?.properties || null,
                geometry: {
                    type: "Point",
                    coordinates: item.coords,
                }
            });
            item_marker_coords = item.coords;
        }
        // Generate marker
        if (item_marker_coords !== null && item_marker_coords.length === 2) {
            var title = "Crèche";
            var title_txt = '<span style="color:#062134;font-size:15px;font-weight:700"><strong>' + title + '</strong></span>';
            var infos = [
                { label: "Nom", value: item?.properties?.name || "NC" },
                { label: "Opérateur", value: item?.properties?.operator || "NC"},
                { label: "Distance", value: item?.properties?.distance ? item?.properties?.distance + " m" : "NC"},
            ]
            var infos_txt = infos.map(info => { return ('<br /><span style="font-size:12px;font-weight: 600;color: grey;">' + info.label + ' :</span><br /><span style="color:#062134;font-weight:500;">' + info.value + '</span>') }).join('');
            var marker_label = item?.properties?.name || "Crèche";
            var el = document.createElement('div');
            el.className = 'marker';
            el.innerHTML = '<div style="cursor:pointer;padding:3px 8px 2px;background-color:#062134;border-radius:6px;display:flex;justify-content:center;align-items:center;gap:4px"><div style="display:flex;justify-content:center">' + marker_img + '</div><div style="font-family:Manrope;color:#FFF;">' + marker_label + '</div></div>';
            map_data.markers.push(new mapboxgl.Marker(el)
                .setLngLat(item_marker_coords)
                .setPopup(new mapboxgl.Popup({ offset: 15 })
                    .setHTML('<p style="font-family:Manrope">' + title_txt + infos_txt + '</p>'))
            )
        }
    }

    // Check data exists
    if (!layer_data.features.length > 0) {
        return map_data
    }

    // Create sources
    map_data.sources.push(
        {
            id: sources_ids[0],
            value: {
                type: 'geojson',
                data: layer_data
            }
        }
    )

    // Create layers
    map_data.layers.push(
        {
            id: layers_ids[0],
            type: 'fill',
            source: sources_ids[0],
            paint: {
                'fill-color': '#062134',
                'fill-opacity': 0.5,
            },
            filter: ['in', '$type', 'Polygon']
        }
    );

    // Set global markers
    if (map_data.markers.length > 0) {
        markers = map_data.markers;
    }
    else {
        markers = [];
    }

    // Get bbox
    var bbox_data = {
        type: 'FeatureCollection',
        features: layer_data.features.concat([capacity?.landBase?.union?.geometry]),
    };
    var bbox = turf.bbox(bbox_data);
    if (bbox && bbox.length === 4) {
        var bbox_poly = [
            [bbox[0], bbox[1]],
            [bbox[2], bbox[1]],
            [bbox[2], bbox[3]],
            [bbox[0], bbox[3]],
            [bbox[0], bbox[1]],
        ]
        map_data.bbox = [bbox_poly];
    }


    // Return
    // console.log("map_data", map_data);
    return map_data

}

