import * as turf from '@turf/turf';
import mapboxgl from 'mapbox-gl';


const sources_ids = ['sourceCommoditiesDoctor'];
const layers_ids = ['layerCommoditiesDoctor_Points'];
var markers = [];
var marker_img = '<svg width="14" height="14" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1108_5)"><path d="M379.869 80.1335C401.998 80.1335 419.936 62.195 419.936 40.0668C419.936 17.9385 401.998 0 379.869 0C357.741 0 339.803 17.9385 339.803 40.0668C339.803 62.195 357.741 80.1335 379.869 80.1335Z" fill="white"/><path d="M592.152 80.1335C614.28 80.1335 632.218 62.195 632.218 40.0668C632.218 17.9385 614.28 0 592.152 0C570.023 0 552.085 17.9385 552.085 40.0668C552.085 62.195 570.023 80.1335 592.152 80.1335Z" fill="white"/><path d="M711.529 76.5095C691.172 41.2509 654.714 20.2022 613.999 20.2022V59.9392C640.349 59.9392 663.94 73.561 677.117 96.3779C690.294 119.199 690.294 146.442 677.121 169.263L549.478 390.344C536.305 413.161 512.71 426.783 486.36 426.787C460.01 426.787 436.415 413.165 423.238 390.344L295.599 169.259C282.426 146.442 282.426 119.195 295.599 96.3779C308.772 73.561 332.371 59.9352 358.721 59.9352V20.1982C318.007 20.1982 281.544 41.2469 261.187 76.5095C240.829 111.772 240.829 153.869 261.187 189.128L388.826 410.208C405.801 439.61 433.991 459.081 466.491 464.755V571.377C466.491 675.532 405.007 760.267 329.431 760.267C253.855 760.267 192.374 675.532 192.374 571.377V444.426C237.635 435.199 271.8 395.081 271.8 347.126C271.8 292.372 227.255 247.827 172.502 247.827C117.748 247.827 73.2031 292.372 73.2031 347.126C73.2031 395.077 107.369 435.195 152.633 444.426V571.377C152.633 697.443 231.944 800.004 329.431 800.004C426.914 800.004 506.224 697.443 506.224 571.377V464.755C538.721 459.081 566.915 439.61 583.886 410.208L711.529 189.128C731.891 153.869 731.891 111.768 711.529 76.5095Z" fill="white"/></g><defs><clipPath id="clip0_1108_5"><rect width="800" height="800" fill="white"/></clipPath></defs></svg>';

export const get_ids = () => {
    // Get all source ids
    var sources = [];
    sources_ids.forEach(source_id => {
        sources.push({ id: source_id });
    })
    // Get all layer ids
    var layers = [];
    layers_ids.forEach(layer_id => {
        layers.push({ id: layer_id });
    })

    return {
        sources: sources,
        layers: layers,
        markers: markers,
    }
}


export const get_data = (capacity) => {
    // Set map data
    var map_data = {
        sources: [],
        layers: [],
        markers: [],
        bbox: null
    }

    var data_items = capacity?.context?.commodities?.health_doctor?.items?.doctor || [];
    var data_cat = Array(data_items.length).fill("Cabinet médical");
    if (capacity?.context?.commodities?.health_doctor?.items?.nurse) {
        data_items = data_items.concat(capacity?.context?.commodities?.health_doctor?.items?.nurse);
        data_cat = data_cat.concat(Array(capacity?.context?.commodities?.health_doctor?.items?.nurse.length).fill("Cabinet infirmier"));
    }


    // Check needed data
    if (!data_items.length > 0) {
        return map_data
    }

    // Preset layer data
    var layer_data = {
        type: 'FeatureCollection',
        features: [],
    };

    // Add data
    for (var i = 0; i < data_items.length; i++) {
        var item = data_items[i];
        var item_marker_coords = null;
        // Generate polygon
        if (item?.type === "way" && item?.coords && item.coords.length > 3 && item.coords[0].length === 2 && item.coords[0][0] === item.coords[item.coords.length - 1][0] && item.coords[0][1] === item.coords[item.coords.length - 1][1]) {
            layer_data.features.push({
                type: "Feature",
                properties: item?.properties || null,
                geometry: {
                    type: "Polygon",
                    coordinates: [item.coords],
                }
            });
            try {
                var center = turf.centroid(turf.polygon([item.coords]));
                item_marker_coords = center?.geometry?.coordinates || null;
            } catch (error) {
                item_marker_coords = null;
            }
        }
        // Generate point
        else if (item?.type === "node" && item?.coords && item.coords.length === 2) {
            layer_data.features.push({
                type: "Feature",
                properties: item?.properties || null,
                geometry: {
                    type: "Point",
                    coordinates: item.coords,
                }
            });
            item_marker_coords = item.coords;
        }
        // Generate marker
        if (item_marker_coords !== null && item_marker_coords.length === 2) {
            var title = data_cat.length >= (i-1) ? data_cat[i] : "Cabinet médical";
            var title_txt = '<span style="color:#062134;font-size:15px;font-weight:700"><strong>' + title + '</strong></span>';
            var infos = [
                { label: "Nom", value: item?.properties?.name || "NC" },
                { label: "Spécialité", value: item?.properties?.speciality || "NC" },
                { label: "Distance", value: item?.distance ? item?.distance + " m" : "NC"},
            ]
            var infos_txt = infos.map(info => { return ('<br /><span style="font-size:12px;font-weight: 600;color: grey;">' + info.label + ' :</span><br /><span style="color:#062134;font-weight:500;">' + info.value + '</span>') }).join('');
            var marker_label = item?.properties?.name || "Cabinet médical";
            var el = document.createElement('div');
            el.className = 'marker';
            el.innerHTML = '<div style="cursor:pointer;padding:3px 8px 2px;background-color:#062134;border-radius:6px;display:flex;justify-content:center;align-items:center;gap:4px"><div style="display:flex;justify-content:center">' + marker_img + '</div><div style="font-family:Manrope;color:#FFF;">' + marker_label + '</div></div>';
            map_data.markers.push(new mapboxgl.Marker(el)
                .setLngLat(item_marker_coords)
                .setPopup(new mapboxgl.Popup({ offset: 15 })
                    .setHTML('<p style="font-family:Manrope">' + title_txt + infos_txt + '</p>'))
            )
        }
    }

    // Check data exists
    if (!layer_data.features.length > 0) {
        return map_data
    }

    // Create sources
    map_data.sources.push(
        {
            id: sources_ids[0],
            value: {
                type: 'geojson',
                data: layer_data
            }
        }
    )

    // Create layers
    map_data.layers.push(
        {
            id: layers_ids[0],
            type: 'fill',
            source: sources_ids[0],
            paint: {
                'fill-color': '#062134',
                'fill-opacity': 0.5,
            },
            filter: ['in', '$type', 'Polygon']
        }
    );

    // Set global markers
    if (map_data.markers.length > 0) {
        markers = map_data.markers;
    }
    else {
        markers = [];
    }

    // Get bbox
    var bbox_data = {
        type: 'FeatureCollection',
        features: layer_data.features.concat([capacity?.landBase?.union?.geometry]),
    };
    var bbox = turf.bbox(bbox_data);
    if (bbox && bbox.length === 4) {
        var bbox_poly = [
            [bbox[0], bbox[1]],
            [bbox[2], bbox[1]],
            [bbox[2], bbox[3]],
            [bbox[0], bbox[3]],
            [bbox[0], bbox[1]],
        ]
        map_data.bbox = [bbox_poly];
    }



    // Return
    // console.log("map_data", map_data);
    return map_data

}

