import * as turf from '@turf/turf';
import mapboxgl from 'mapbox-gl';


const sources_ids = ['sourceCommoditiesEducationPrim'];
const layers_ids = ['layerCommoditiesEducationPrim_Points'];
var markers = [];
var marker_img = '<svg width="14" height="14" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M777.483 180.154H613.131V162.679C613.131 114.95 574.299 76.1133 526.566 76.1133H273.434C225.701 76.1133 186.869 114.946 186.869 162.679V180.154H22.5172C10.101 180.154 0 190.255 0 202.671V277.499L165.196 417.103C168.63 410.857 175.196 406.57 182.828 406.57H237.374C248.529 406.57 257.576 415.616 257.576 426.772V439.754H542.424V426.772C542.424 415.616 551.471 406.57 562.626 406.57H617.172C624.804 406.57 631.37 410.853 634.804 417.103L800 277.499V202.671C800 190.255 789.899 180.154 777.483 180.154ZM227.273 162.679C227.273 137.224 247.98 116.517 273.434 116.517H526.562C552.016 116.517 572.723 137.224 572.723 162.679V180.154H227.269L227.273 162.679ZM637.374 467.83L778.857 348.267V665.948C778.857 697.895 752.865 723.883 720.921 723.883H79.0828C47.1353 723.883 21.1475 697.891 21.1475 665.948V348.267L162.63 467.83V513.135C162.63 524.291 171.677 533.338 182.832 533.338H237.378C248.533 533.338 257.58 524.291 257.58 513.135V480.158H542.428V513.135C542.428 524.291 551.475 533.338 562.63 533.338H617.176C628.331 533.338 637.378 524.291 637.378 513.135V467.83H637.374Z" fill="white"/></svg>';

export const get_ids = () => {
    // Get all source ids
    var sources = [];
    sources_ids.forEach(source_id => {
        sources.push({ id: source_id });
    })
    // Get all layer ids
    var layers = [];
    layers_ids.forEach(layer_id => {
        layers.push({ id: layer_id });
    })

    return {
        sources: sources,
        layers: layers,
        markers: markers,
    }
}


export const get_data = (capacity) => {
    // Set map data
    var map_data = {
        sources: [],
        layers: [],
        markers: [],
        bbox: null
    }

    var data_items = capacity?.context?.commodities?.education_primary?.items?.elementary || [];
    var data_cat = Array(data_items.length).fill("École élémentaire");
    if (capacity?.context?.commodities?.education_primary?.items?.maternel) {
        data_items = data_items.concat(capacity?.context?.commodities?.education_primary?.items?.maternel);
        data_cat = data_cat.concat(Array(capacity?.context?.commodities?.education_primary?.items?.maternel.length).fill("École maternelle"));
    }
    if (capacity?.context?.commodities?.education_primary?.items?.primary) {
        data_items = data_items.concat(capacity?.context?.commodities?.education_primary?.items?.primary);
        data_cat = data_cat.concat(Array(capacity?.context?.commodities?.education_primary?.items?.primary.length).fill("École primaire"));
    }


    // Check needed data
    if (!data_items.length > 0) {
        return map_data
    }

    // Preset layer data
    var layer_data = {
        type: 'FeatureCollection',
        features: [],
    };

    // Add data
    for (var i = 0; i < data_items.length; i++) {
        var item = data_items[i];
        var item_marker_coords = null;
        // Generate polygon
        if (item?.type === "way" && item?.coords && item.coords.length > 3 && item.coords[0].length === 2 && item.coords[0][0] === item.coords[item.coords.length - 1][0] && item.coords[0][1] === item.coords[item.coords.length - 1][1]) {
            layer_data.features.push({
                type: "Feature",
                properties: item?.properties || null,
                geometry: {
                    type: "Polygon",
                    coordinates: [item.coords],
                }
            });
            try {
                var center = turf.centroid(turf.polygon([item.coords]));
                item_marker_coords = center?.geometry?.coordinates || null;
            } catch (error) {
                item_marker_coords = null;
            }
        }
        // Generate point
        else if (item?.type === "node" && item?.coords && item.coords.length === 2) {
            layer_data.features.push({
                type: "Feature",
                properties: item?.properties || null,
                geometry: {
                    type: "Point",
                    coordinates: item.coords,
                }
            });
            item_marker_coords = item.coords;
        }
        // Generate marker
        if (item_marker_coords !== null && item_marker_coords.length === 2) {
            var title = data_cat.length >= (i-1) ? data_cat[i] : "École";
            var title_txt = '<span style="color:#062134;font-size:15px;font-weight:700"><strong>' + title + '</strong></span>';
            var infos = [
                { label: "Nom", value: item?.properties?.name || "NC" },
                { label: "Public / Privé", value: item?.properties?.operator || "NC" },
                { label: "Distance", value: item?.properties?.distance ? item?.properties?.distance + " m" : "NC"},
            ]
            var infos_txt = infos.map(info => { return ('<br /><span style="font-size:12px;font-weight: 600;color: grey;">' + info.label + ' :</span><br /><span style="color:#062134;font-weight:500;">' + info.value + '</span>') }).join('');
            var marker_label = item?.properties?.name || "École";
            var el = document.createElement('div');
            el.className = 'marker';
            el.innerHTML = '<div style="cursor:pointer;padding:3px 8px 2px;background-color:#062134;border-radius:6px;display:flex;justify-content:center;align-items:center;gap:4px"><div style="display:flex;justify-content:center">' + marker_img + '</div><div style="font-family:Manrope;color:#FFF;">' + marker_label + '</div></div>';
            map_data.markers.push(new mapboxgl.Marker(el)
                .setLngLat(item_marker_coords)
                .setPopup(new mapboxgl.Popup({ offset: 15 })
                    .setHTML('<p style="font-family:Manrope">' + title_txt + infos_txt + '</p>'))
            )
        }
    }

    // Check data exists
    if (!layer_data.features.length > 0) {
        return map_data
    }

    // Create sources
    map_data.sources.push(
        {
            id: sources_ids[0],
            value: {
                type: 'geojson',
                data: layer_data
            }
        }
    )

    // Create layers
    map_data.layers.push(
        {
            id: layers_ids[0],
            type: 'fill',
            source: sources_ids[0],
            paint: {
                'fill-color': '#062134',
                'fill-opacity': 0.5,
            },
            filter: ['in', '$type', 'Polygon']
        }
    );

    // Set global markers
    if (map_data.markers.length > 0) {
        markers = map_data.markers;
    }
    else {
        markers = [];
    }

    // Get bbox
    var bbox_data = {
        type: 'FeatureCollection',
        features: layer_data.features.concat([capacity?.landBase?.union?.geometry]),
    };
    var bbox = turf.bbox(bbox_data);
    if (bbox && bbox.length === 4) {
        var bbox_poly = [
            [bbox[0], bbox[1]],
            [bbox[2], bbox[1]],
            [bbox[2], bbox[3]],
            [bbox[0], bbox[3]],
            [bbox[0], bbox[1]],
        ]
        map_data.bbox = [bbox_poly];
    }


    // Return
    // console.log("map_data", map_data);
    return map_data

}

